import baseRequest from './base-request';

import { FormPayload } from '../components/Forms/Contact';


const METHOD = 'POST';
const REQUIRED_PROPS = [
    'email',
    'first',
    'last',
    'message',
    'phone',
];
const URL = 'https://us-central1-enigma-advisory-beta.cloudfunctions.net/form-submission';

const clientRequest = async (payload: FormPayload) => {
  REQUIRED_PROPS.forEach((prop) => {
    if (!(prop in payload)) {
      const msg = `Missing ${prop}`;
      return {
        error: [msg],
      };
    }
  });

  const resp = fetch(
    URL,
    baseRequest({
        method: METHOD,
        body: JSON.stringify({
          ...payload,
        }),
        headers: {},
      }
    ),
  ).then(response => response.json()).then(resp => {
    if (resp.statusCode > 201) {
      console.error(`${METHOD} ${URL} failed: ${resp.message}`);
      return {
        error: resp.message,
        statusCode: resp.statusCode,
      };
    }
    return resp;
  }).catch((err) => {
    console.error(err);
    return {
      error: err,
      statusCode: 500,
    };
  });

  return resp;
};

export default clientRequest;
