import { PageProps } from '../../components/Templates/EmergencyManagement';

import CoopCog from '../../assets/images/preparedness-coop-cog.jpg';
import Eoc from '../../assets/images/preparedness-eoc.jpg';
import Preparedness from '../../assets/images/preparedness-in-disaster-management.jpg';
import Management from '../../assets/images/preparedness-mitigation-management.jpg';
import Mitigation from '../../assets/images/preparedness-mitigation.jpg';
import RoDlp from '../../assets/images/preparedness-ro-and-dlp.jpg';
import Rpa from '../../assets/images/preparedness-rpa.jpg';

import s from '../../i18n/strings.json';


/* Props for Emergency Preparedness Consulting page */
const props: PageProps = {
  seo: {
    description: s.services.preparedness.META_DESCRIPTION,
    title: s.services.preparedness.TITLE,
  },
  header: {
    backgroundImage: Preparedness,
    header: s.common.EMERGENCY_PREPAREDNESS_CONSULTING,
    subheader: s.common.ENIGMA_ADVISORY,
  },
  section1: {
    header: s.services.preparedness.EXPERT_AGENCY,
    text: s.services.preparedness.EXPERC_AGENCY_BLURB,
  },
  section2: {
    backgroundImage: Mitigation,
    header: s.services.preparedness.CEMP,
    text: s.services.preparedness.CEMP_BLURB,
  },
  imageBlurbs: [
    {
      blurb: s.services.preparedness.IMAGE_BLURB1,
      header: s.services.preparedness.IMAGE_BLURB1_HEADER,
      image: CoopCog,
      imageAlt: 'continuity of ops planning',
      color: '#3bafbf',
    },
    {
      blurb: s.services.preparedness.IMAGE_BLURB2,
      header: s.services.preparedness.IMAGE_BLURB2_HEADER,
      image: Eoc,
      imageAlt: 'emergency ops planning',
    },
    {
      blurb: s.services.preparedness.IMAGE_BLURB3,
      header: s.services.preparedness.IMAGE_BLURB3_HEADER,
      image: RoDlp,
      imageAlt: 'view of office meeting through glass',
    },
    {
      blurb: s.services.preparedness.IMAGE_BLURB4,
      header: s.services.preparedness.IMAGE_BLURB4_HEADER,
      image: Rpa,
      imageAlt: 'workers collaborating in room',
    },
  ],
  splitList: {
    actionText: s.services.preparedness.ACTION_PARAGRAPH_BEGIN,
    backgroundImage: Management,
    header: s.services.preparedness.ADDITIONAL_SERVICES,
    itemList: [
      'Debris Management Plans',
      'Emergency Communication Planning',
      'Evacuation Planning',
      'Logistical Planning',
      'Mass Casualty Incident Plans',
      'Disaster Housing Planning',
      'Emergency Consulting Plans',
      'Hazard Mitigation Plans',
      'Long-Term Recovery Plans',
      'and more…',
    ],
    text: s.services.preparedness.ADDITIONAL_SERVICES_INFO,
  },
};

export default props;
