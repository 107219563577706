import { useParams } from 'react-router-dom';

import Mitigation from './disaster-mitigation';
import Prep from './disaster-preparedness';
import Recovery from './disaster-recovery';
import Resp from './disaster-response';

import PageNotFound from '../../components/PageNotFound';
import PageTemplate, { PageProps } from '../../components/Templates/EmergencyManagement';

import css from '../FourOhFour/four-oh-four.module.scss';


type ProcessPages = {
  [service: string]: PageProps;
};

type ProcessProps = {
  openContact?: () => void;
};

const PROPS_MAP: ProcessPages = {
  'disaster-mitigation': Mitigation,
  'disaster-preparedness': Prep,
  'disaster-recovery': Recovery,
  'disaster-response': Resp,
};

const Services = ({ openContact = () => {} }: ProcessProps) => {
  const { id } = useParams();
  const props = PROPS_MAP[id as string];

  if (!props) {
    return (
      <div className={ css['four-oh-four'] }>
        <PageNotFound openContact={ openContact } />;
      </div>
    );
  }

  return (
    <PageTemplate openContact={ openContact } props={ props } />
  );
};

export default Services;
