import { FlexedRowProps } from '../components/BlogSections/FlexedRow';
import { TitledBodyProps } from '../components/BlogSections/TitledBody';
import { TitledDividedImageBodyProps } from '../components/BlogSections/TitledDividedImageBody';
import { TitledImageBodyProps } from '../components/BlogSections/TitledImageBody';
import { ImageBlurbProps } from '../components/ImageBlurb';

import Consultation from './images/consultation.jpg';

import BrandLogo from '../assets/images/brand-logo-emergency-white.svg';
import Mitigation from '../assets/images/preparedness-mitigation-management.jpg';
import RoDlp from '../assets/images/preparedness-ro-and-dlp.jpg';


const content = [
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: Mitigation,
          imageAlt: 'employees viewing data on screen',
        },
      ],
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'As a business owner, you know that disasters can happen at any time. For example, a fire in the office, a break-in, or an illness that keeps employees home from work can lead to a loss of income and productivity. That’s why it’s essential to have an office disaster plan in place. Here are a few things you can do to prepare your business for an emergency, including setting up an office disaster plan.',
            header: 'Stay Ready with an Office Disaster Plan',
            subheader: 'Be Prepared Whenever Disaster Strikes',
          } as TitledBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
  {
    component: 'titled-image-body',
    props: {
      body: 'A disaster plan prepares both your business and your employees for any potential situation that may arise, whether natural or manmade. First, spend some time brainstorming potential risks, from area-specific natural occurrences like earthquakes, tornadoes, and hurricanes, or potential risks like nearby factories or local crime numbers. From there, identify how you should respond in these situations. Next, formulate systems and protocols that meet the needs of your services and employees. Finally, brainstorm with your team, develop as many potential scenarios as possible, and identify solutions to them.\n\nAfter you have taken the time to identify potential risks, identify the point people you want to make decisions with during a disaster situation, and make sure they feel empowered to make decisions in the case of an emergency. They should be trained in the protocols and systems of your business and should be given the training and empowerment to make essential calls amid a developing situation.',
      header: 'Setting Up Your Office Disaster Plan',
      image: RoDlp,
      imageAlt: 'view of office meeting through glass',
      imageSide: 'left',
    } as TitledImageBodyProps,
  },
  {
    component: 'flexed-row',
    props: {
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'The health and safety of your people should always come first. Therefore, it’s crucial to establish communication channels and protocols beforehand so that no one is ever left in the dark and can conduct themselves calmly. An immediate emergency can save precious time from being lost due to miscommunication or an unclear chain of command.\n\nYou will also need to consider any disabilities that may require special accommodations during an emergency or evacuation scenario. For example, this could mean ensuring an exit strategy for physically disabled or wheelchair-bound individuals or specific alerting systems designed for those unable to detect auditory alarm systems.\n\nLook at your whole team, and decide on the systems and procedures that best fit your specific situation. The goal is not to create a perfect system but fully functional and accounts for your specific needs and potential scenarios.',
            header: 'Take Care of Employees First',
            endImage: {
              image: BrandLogo,
              imageAlt: 'enigma advisory disaster recovery and logo',
            },
          } as TitledBodyProps,
        },
        {
          component: 'titled-body',
          props: {
            body: 'Once you have established a solid foundation for your Emergency Management Plan, it’s time to get into the actual construction of your plan.\n\nYou can take a few logistical measures to prepare your office for a disaster situation. A great way to start preparing your office is by putting together an emergency kit. This kit should include emergency food and water provisions, first aid, and any specific items that might be useful within an emergency. In addition, this kit should be easy to access and openly available to any employees that need it.\n\nEnsuring that your alarms and systems work properly can also be a huge asset. Some systems will be critical – the fire alarm, the security system, and your main phone line are good examples. In addition, emergency services (police and fire department) will be able to use those systems, so make sure those aren’t out of order.\n\nTo mitigate potential data loss, store important documents in a safe, waterproof place. If your documentation is digitized, make sure that there are backups of all critical documents and that they are stored in a private and secure server system.\n\nTry to store valuable equipment and machinery at a higher elevation to reduce the chances of flood or water damage. It’s also recommended to store at least two fire extinguishers throughout your facility. This way, if there is a small fire, it can be dealt with quickly.\n\nAgain, the perfect plan is the one that works for your specific business operation. This will look different for every operation, so make decisions that best suit your particular needs and considerations.',
            header: 'Protect Your Enterprise',
          } as TitledBodyProps,
        },
      ],
    } as FlexedRowProps,
  },
  {
    component: 'flexed-row',
    props: {
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'If you find yourself in a situation where the power has gone out in your office, either due to power line disruption or blackouts, you first need to ensure that everyone in your office is safe and secure, both workers and customers. If someone is injured, make sure to see to their injuries immediately, either with the tools in your emergency kit or by calling 911 for medical assistance.\n\nOnce everyone is safe and secured, get in contact with your service provider. They are the ones who are responsible for managing power outages as they occur, so the sooner they know your power is down, the faster the situation can be resolved.\n\nThere is no guarantee that a power outage will be solved immediately or within a singular workday. If it’s available for your business, try to work from home. In the age of COVID, this idea has become a significant reality for many. Suppose your operations depend on a brick-and-mortar location. In that case, it may be worth investing in backup power systems, such as external generators, ahead of time to ensure that you can hold services during an outage.\n\nRemember that your health and the health of your employees and customers come first. There is no shame in evacuating or suspending operations during an emergency situation if it means that your personnel will be safe.',
            header: 'What to do if a power outage impacts your office',
          } as TitledBodyProps,
        },
        {
          component: 'titled-body',
          props: {
            body: 'What you are trying to avoid is allowing your office space to devolve into chaos when the regular workflow is unexpectedly disrupted. Here are some tips for dealing with disruption to daily operations.\n\nFirst, keep a running dialogue with your team. It isn’t uncommon to see a drastic dip in productivity when the normal workflow is disrupted. Let your employees know how long the disruption is expected to last, and keep them updated as the situation progresses.\n\nNow, this doesn’t mean bombarding your team with updates and emails. Instead, keep it streamlined so that people feel included but not to the point that it interferes with their other responsibilities. Allow for routine workflows to continue as close to normal as possible, but where this is not possible, use the dialogue to ask people what their priorities are so you can plan accordingly.\n\nTry to keep morale high by being positive in your updates, and don’t use the dialogue channel as a means for venting your frustration. Your employees will reflect your attitude, and if you come across as frustrated and overwhelmed, they will respond in kind. As a business leader, it is your job to keep the ship steady. Sometimes that means putting on a smile, even when you don’t fully believe in it yourself. Believe in yourself, and your team will follow suit!',
            header: 'Tips for Staying Productive After A Disruption',
          } as TitledBodyProps,
        },
      ],
    } as FlexedRowProps,
  },
  {
    component: 'image-blurb',
    props: {
      blurb: 'Disasters can happen any time, anywhere. That’s why it’s essential to have a plan in place, so you don’t have to scramble when something goes wrong. You can’t always predict the future, but you can be prepared for it.\nAs emergency management professionals, we want you to feel empowered to make the best decisions for your business in any situation. If you would like more information on emergency management and preparation, please visit our website or contact us for assistance.',
      header: 'Office Disaster Plan Conclusion',
      image: Consultation,
      imageAlt: 'consultation presentation around table',
      rightImage: true,
    } as ImageBlurbProps,
  },
];

export default content;
