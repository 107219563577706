import { blue } from '@carbon/colors';
import { Checkmark } from '@carbon/icons-react';
import classNames from 'classnames';
import React from 'react';
import { Helmet } from 'react-helmet';

import DiamondCenter from '../../Dividers/DiamondCenter';
import Spear from '../../Dividers/Spear';
import Footer from '../../Footer';
import ImageBlurb from '../../ImageBlurb';

import Header from '../../PageSections/Header';
import Testimonials from '../../PageSections/Testimonials';
import TextBackgroundImage from '../../PageSections/TextBackgroundImage';
import TextGradientHeader from '../../PageSections/TextGradientHeader';
import TextSplitList from '../../PageSections/TextSplitList';

import { SeoMetadata } from '../../../types';

import css from '../templates.module.scss';


type Testimonial = {
  author: string;
  text: string;
};

type ImgBlurb = {
  blurb: string;
  header: string;
  image: string;
  imageAlt: string;
  children?: Array<JSX.Element>;
  color?: string;
  subheader?: string;
  subheaderColor?: string;
};

export type PageProps = {
  header: {
    backgroundImage: string;
    header: string;
    subheader: string;
  };
  imageBlurbs?: Array<ImgBlurb>;
  section1?: {
    header: string;
    text: string;
    custom?: JSX.Element;
  };
  section2?: {
    backgroundImage: string;
    header: string;
    text: string;
    custom?: JSX.Element;
  };
  section3?: {
    backgroundImage: string;
    header: string;
    subheader: string;
    text: string;
  };
  section4?: {
    header: string;
    list: Array<string>;
    text: string;
  };
  section5?: {
    actionText: string,
    backgroundImage: string;
    header: string;
  };
  seo: SeoMetadata;
  splitList?: {
    actionText: string;
    backgroundImage: string;
    header: string;
    itemList: Array<string>;
    text: string;
  };
  testimonials?: {
    header: string;
    testimonials: Array<Testimonial>;
  };
};

type EmergencyManagementTemplateProps = {
  openContact: () => void;
  props: PageProps;
};

const EmergencyManagementTemplate = ({ openContact, props }: EmergencyManagementTemplateProps) => {
  return (
    <div className={ css.services }>
      <Helmet>
        <title>{ props.seo.title }</title>
        <meta name={ 'description' } content={ props.seo.description } />
      </Helmet>
      <Header
        backgroundImage={ props.header.backgroundImage }
        header={ props.header.header }
        subheader={ props.header.subheader }
      />
      { props.section1 && !props.section1.custom &&
        <TextGradientHeader
          header={ props.section1.header }
          text={ props.section1.text }
        />
      }
      { props.section1 && props.section1.custom }
      { props.testimonials &&
        <React.Fragment>
          { props.section2 && <DiamondCenter color={ blue[30] } paddingBottom={ 2 } /> }
          <Testimonials
            header={ props.testimonials.header }
            testimonials={ props.testimonials.testimonials }
          />
        </React.Fragment>
      }
      { props.section2 && !props.section2.custom &&
        <TextBackgroundImage
          backgroundImage={ props.section2.backgroundImage }
          header={ props.section2.header }
          text={ props.section2.text }
        />
      }
      { props.section2 && props.section2.custom }
      { props.section2
        ? <DiamondCenter color={ blue[30] } paddingBottom={ 4 } paddingTop={ 4 } />
        : <Spear size={ 'sm' } />
      }
      { props.imageBlurbs &&
        <div className={ classNames(css.section, css.flex) }>
          { props.imageBlurbs.map((b, idx) => {
            return (
              <ImageBlurb
                key={ `blurd-${idx}` }
                blurb={ b.blurb }
                header={ b.header }
                headerColor={ b.color || '' }
                image = { b.image }
                imageAlt={ b.imageAlt }
                subheader={ b.subheader || '' }
                subheaderColor={ b.subheaderColor || '' }
              >
                { b.children || [] }
              </ImageBlurb>
            );
          })}
        </div>
      }
      { props.splitList &&
          <div
            className={ classNames(css.break, css['bg-image']) /* TODO: Add parallax scroll */ }
            style={ { backgroundImage: `url(${props.splitList.backgroundImage})` } }
          />
      }
      { props.splitList &&
        <TextSplitList
          header={ props.splitList.header }
          list={ props.splitList.itemList }
          text={ props.splitList.text }
          actionProps={
            {
              action: openContact,
              text: props.splitList.actionText,
            }
          }
          dividerBottom={ true }
          dividerTop={ true }
          icon={ <Checkmark /> }
          iconSize={ 'md' }
        />
      }
      { props.section3 &&
        <React.Fragment>
          <div className={ css.wrapper }>
            <DiamondCenter color={ blue[30] } paddingBottom={ 2 } />
          </div>
          <TextBackgroundImage
            backgroundImage={ props.section3.backgroundImage }
            header={ props.section3.header }
            subheader={ props.section3.subheader }
            text={ props.section3.text }
          />
        </React.Fragment>
      }
      { props.section4 &&
        <TextSplitList
          header={ props.section4.header }
          list={ props.section4.list }
          text={ props.section4.text }
          dividerTop={ true }
          iconSize={ 'sm' }
        />
      }
      { props.section5 &&
        <React.Fragment>
          <div className={ css.wrapper }>
            { !props.section4 && !props.section2?.custom &&
              <DiamondCenter
                color={ blue[30] }
                paddingBottom={ 2 }
                paddingTop={ 2 }
              />
            }
          </div>
          <TextBackgroundImage
            backgroundImage={ props.section5.backgroundImage }
            header={ props.section5.header }
            actionProps={ { action: openContact, text: props.section5.actionText } }
          />
          <div className={ css['blue-gradient'] } />
        </React.Fragment>
      }
      { !props.section5 && !props.testimonials && <div className={ css['blue-gradient'] } /> }
      <Footer openContact={ openContact } />
    </div>
  );
};

export default EmergencyManagementTemplate;
