import { TitledBodyProps } from '../components/BlogSections/TitledBody';


const content = [
  {
    component: 'titled-body',
    props: {
      body: 'As emergency management professionals, we are constantly planning and preparing for all sorts of emergencies. However, the COVID-19 pandemic is presenting us with unique challenges that we have never faced before. In this blog post, we will explore the implications of COVID19 on emergency management and discuss how best to prepare for and respond to this outbreak as emergency management professionals.',
      header: 'Exploring The Consequences of COVID-19 on Emergency Management',
    } as TitledBodyProps,
  },
  {
    component: 'titled-body',
    props: {
      body: 'As of January 2022, we have seen a significant spike in COVID cases, primarily due to the recently discovered Omicron variation, and ICU units are still overwhelmed by the surge. Nevertheless, the medical community continues to do the work of keeping people alive. All the while developing new systems and protocols that integrate the threat of COVID-19.\n\nAs emergency management specialists, we must stay on top of local and state COVID numbers. This may mean doing additional research into how COVID affects the area you are looking to assist. Also, it may behoove you to look into the state of the medical community in that area.\n\nThe truth is that COVID has caused many medical professionals to leave the field entirely. So you may find yourself dealing with a medical community struggling to accommodate and care for a large number of patients. It is imperative here that we do everything to bolster their efforts. This may include sourcing more staff and equipment or finding adequate space for the extra patients. Of course, every situation will be unique, so our best course of action is to prepare ourselves beforehand. It is also essential that we communicate with the local medical communities to ensure that every need and situation is considered.',
      header: 'Medical Response Complications',
    } as TitledBodyProps,
  },
  {
    component: 'titled-body',
    props: {
      body: 'COVID-19 is an ongoing crisis itself. So, in a disaster situation, we must effectively manage two issues simultaneously. It makes the situation complicated. We must make plans that take into consideration the local COVID situation. We may even have to alter plans to meet local mask mandates and social distancing policies. While undeniably necessary, these additional requirements may put extra strain on what are already delicate operations. This will require more leg work for on-the-ground coordinators and managing parties.\n\nSo, how do we handle this? Like most things with COVID-19, this will most likely depend on community cooperation. Ensuring that public members can receive reliable, relevant information and access necessary services and resources will require the full cooperation of local officials, disaster management professionals, and the public. Each must be willing to do their part and make necessary accommodations to address and mediate a disaster situation. As professionals, we must keep an open dialogue with relevant officials and groups throughout the relief and recovery process and prepare ourselves ahead of time to understand the community’s needs.',
      header: 'Complex Relief Situations',
    } as TitledBodyProps,
  },
  {
    component: 'titled-body',
    props: {
      body: 'COVID has dramatically impacted the US’s supply chain. The exact reasoning for this is complicated. However, a significant factor is how the pandemic caused substantial issues for factories and international transportation companies. This has led to a shortage of essential or even vital goods. This situation has already affected the building industry and has resulted in a lack of building materials. While this is a developing situation, it is fair to assume that this will impact recovery efforts as materials become more difficult to source. Hopefully, the supply chain will recover with time, but for now, we should take this into account when assessing recovery operations.',
      header: 'Prolonged Recovery Efforts',
    } as TitledBodyProps,
  },
  {
    component: 'titled-body',
    props: {
      body: 'Emergency management professionals are tasked with mitigating, preparing for, and responding to all sorts of emergencies. However, the COVID-19 pandemic is presenting us with unique challenges that we have never faced before. We must be prepared for a variety of scenarios from limiting mobility in hospitals to long-term reconstruction efforts. As emergency managers, our focus should always be on safety first and foremost no matter what type of disaster or incident we’re dealing with.\n\nIf you would like to learn more about our services as emergency management specialists, you can connect with us here, and one of our specialists will contact you.',
      header: 'Conclusion',
    } as TitledBodyProps,
  },
];

export default content;
