import { useState } from 'react';
import { Helmet } from 'react-helmet';

import { ROUTES } from '../../Routes';

import SectorCard, { SectorCardProps } from '../../components/Cards/SectorCard';
import Footer from '../../components/Footer';
import Disaster from '../../components/SVG/Disaster';
import GrantsManagement from '../../components/SVG/GrantsMangement';
import Technology from '../../components/SVG/Technology';

import css from './home.module.scss';

import s from '../../i18n/strings.json';


type HomeProps = {
  openContact: () => void;
};

const Home = ({ openContact }: HomeProps) => {
  const [emergencyAnimated, setEmergencyAnimated] = useState(false);
  const [grantAnimated, setGrantAnimated] = useState(false);
  const [technologyAnimated, setTechnologyAnimated] = useState(false);

  const handleCardHover = (card: string, value: boolean) => {
    switch (card) {
      case 'emergency':
        setEmergencyAnimated(value);
        break;
      case 'grant':
        setGrantAnimated(value);
        break;
      case 'technology':
        setTechnologyAnimated(value);
        break;
    }
  };

  const BRANCH_CARDS: SectorCardProps[] = [
    {
      icon: getDisasterLogo(emergencyAnimated),
      key: 'emergency',
      label: s.common.EMERGENCY_MANAGEMENT,
      route: ROUTES.emergencyManagement,
    },
    {
      icon: getGrantLogo(grantAnimated),
      key: 'grant',
      label: s.common.GRANTS_MANAGEMENT,
      route: ROUTES.grantsManagement,
    },
    {
      icon: getTechnologyLogo(technologyAnimated),
      key: 'technology',
      label: s.common.TECHNOLOGY,
      route: ROUTES.technology,
    },
  ];

  return (
    <div className={ css.home }>
      <Helmet >
        <title>{ s.common.ENIGMA_ADVISORY }</title>
        <meta name={ s.common.DESCRIPTION } content={ s.common.META_DESCRIPTION } />
      </Helmet>
      <div className={ css['card-container'] }>
        { BRANCH_CARDS.map(card => {
          return (
            <a key={ `${card.label}-card` } href={ card.route } className={ css['centered-link'] }>
              <div
                className={ css.card }
                onMouseEnter={ () => handleCardHover(card.key || '', true) }
                onMouseLeave={ () => handleCardHover(card.key || '', false) }
              >
                <SectorCard
                  image={ card.image }
                  icon={ card.icon }
                  label={ card.label }
                />
              </div>
            </a>
          );
        })}
      </div>
      <div className={ css.statement }>
        <p className={ css.paragraph }>
          { s.common.STATEMENT }
        </p>
      </div>
      <Footer openContact={ () => openContact() } />
    </div>
  );
};

const getDisasterLogo = (animated: boolean) => {
  return (
    <Disaster animated={ animated } color={ 'white' } />
  );
};

const getGrantLogo = (animated: boolean) => {
  return (
    <GrantsManagement animated={ animated } color={ 'white' } />
  );
};

const getTechnologyLogo = (animated: boolean) => {
  return (
    <Technology animated={ animated } color={ 'white' } />
  );
};

export default Home;
