import { ArrowRight } from '@carbon/icons-react';
import { Button } from '@carbon/react';
import classNames from 'classnames';

import css from './image-blurb.module.scss';


export type ImageBlurbProps = {
  blurb: string;
  header: string;
  image: string;
  imageAlt: string;
  action?: boolean;
  actionLabel?: string;
  anchorLink?: string;
  children?: Array<JSX.Element>;
  headerColor?: string;
  onClick?: () => void;
  rightImage?: boolean;
  subheader?: string;
  subheaderColor?: string;
};

const ImageBlurb = ({
  blurb,
  header,
  headerColor,
  image,
  imageAlt,
  action = false,
  actionLabel = '',
  anchorLink,
  children,
  onClick = () => {},
  rightImage = false,
  subheader,
  subheaderColor,
}: ImageBlurbProps) => {
  return (
    <div className={ css['container'] }>
      { !rightImage && <div className={ css['image-container'] }>
          <img src={ image } alt={ imageAlt } />
        </div>
      }
      <div className={ classNames(css['blurb-container'], { [css.left]: rightImage }) }>
        { header &&
          <h1
            className={ css['blurb-header'] }
            style={ headerColor ? { color: headerColor } : {} }
          >
            { header }
          </h1>
        }
        { subheader &&
          <h2
            className={ css['blurb-subheader'] }
            style={ subheaderColor ? { color: subheaderColor } : {} }
          >
            { subheader }
          </h2>
        }
        { blurb && blurb.split('\n').map((b, idx) => {
            return (
              <div key={ `blurd-${idx}` } className={ css.blurb }>
                { b }
              </div>
            );
          })
        }
        { children && children?.map(child => child )}
        { action &&
          <div className={ css[rightImage ? 'left-align' : 'right-align'] }>
            { anchorLink && <a href={ anchorLink } /> }
            <Button renderIcon={ ArrowRight } onClick={ () => onClick() }>
              { actionLabel }
            </Button>
          </div>
        }
      </div>
      { rightImage && <div className={ css['image-container'] }>
          <img src={ image } alt={ imageAlt } />
        </div>
      }
    </div>
  );
};

export default ImageBlurb;
