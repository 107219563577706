import { TitledBodyProps } from '../components/BlogSections/TitledBody';

import Hurricane from '../assets/images/hurricane.jpg';


const content = [
  {
    component: 'titled-body',
    props: {
      body: 'Thank you for visiting Enigma Advisory! We are experts in the Disaster Planning, Response, Recovery, and Mitigation Industry. Our diverse and experienced team helps Federal, State, Local, and Tribal Governments and their communities whenever a catastrophe takes place. Our Disaster Experts are here to help prevent and assist in the recovery efforts prior to, during, and after an event.\n\nFor more information, please stay tuned. Enigma Advisory will be introducing new topics, insights, and expert opinions in Disaster Prevention, Management, and Mitigation. Thank you!',
      endImage: {
        image: Hurricane,
        imageAlt: 'hurricane over gulf coast',
      },
      externalLink: {
        body: 'To learn more about our services, click here.',
        href: '/disaster/services/emergency-management-consulting',
      },
    } as TitledBodyProps,
  },
];

export default content;
