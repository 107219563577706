import { gray } from '@carbon/colors';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../Routes';
import ActionParagraph from '../../components/ActionParagraph';
import Footer from '../../components/Footer';
import ImageBlurb from '../../components/ImageBlurb';
import WaveSection from '../../components/Sections/Wave';

import { ReactComponent as Logo } from '../../assets/images/brand-logo-emergency-white.svg';

import css from './emergency-management.module.scss';

import s from '../../i18n/strings.json';


type EmergencyManagementProps = {
  openContact: () => void;
};

const EmergencyManagement = ({ openContact }: EmergencyManagementProps) => {
  const navigate = useNavigate();

  return (
    <div className={ css['emergency-management'] }>
      <Helmet >
        <title>{ s.emergency_management.TITLE }</title>
        <meta name={ s.common.DESCRIPTION } content={ s.emergency_management.META_DESCRIPTION } />
      </Helmet>
      <div className={ css['logo-header'] }>
        <Logo />
      </div>
      <h1 className={ css.header }>
        { s.common.EMERGENCY_MANAGEMENT_CONSULTING }
      </h1>
      <div className={ classNames(css.section) } >
        <h2 className={ css['header-2'] }>
          { s.common.EXPERT }
          <span className={ css['text-bold-blue'] }>
            { s.common.EMERGENCY_MANAGEMENT_CONSULTING }
          </span>
          { s.common.FIRM }
        </h2>
        <h4 className={ css['header-4'] }>
          { s.emergency_management.COMPASSIONATE_PRECISIVE_REVIVIFICATION }
        </h4>
        <div className={ css['text-section'] }>
          <p className={ css.paragraph }>{ s.emergency_management.EXPERT_INFO }</p>
        </div>
      </div>
      <WaveSection
        backgroundColor={ '#327ec7' }
        bottom bottomColor={ '#327ec7' }
        bottomTransitionColor={ gray[100] }
        top topColor={ '#327ec7' }
        topTransitionColor={ gray[100] }
      >
        <div className={ classNames(css.section, css['no-background']) }>
          <h2 className={ css['header-2_5'] }>
            { s.common.EMERGENCY_MANAGEMENT_CONSULTING }
            <span className={ css['text-gray'] }>
              { s.emergency_management.SERVICES }
              </span>
          </h2>
          <h4 className={ classNames(css['header-4'], css['text-gray']) }>
            { s.emergency_management.FOR_SERVICES }
          </h4>
          <div className={ css['text-section'] }>
            <p className={ css['paragraph-2'] }>
              { s.emergency_management.FOR_SERVICES_INFO }
            </p>
          </div>
        </div>
      </WaveSection>
      <div className={ classNames(css.section, css.flex) }>
        <div className={ css['split-header'] }>
          <h2 className={ classNames(css['header-2'], css['text-bold-blue-2']) }>
            { s.common.EMERGENCY_MANAGEMENT_CONSULTING }
          </h2>
          <h2 className={ css['header-2'] }>
            { s.emergency_management.SERVICES_INCLUDE }
          </h2>
        </div>
        <ImageBlurb
          blurb={ s.emergency_management.EMERGENCY_PREPAREDNESS_CONSULTING_BLURB }
          header={ s.common.EMERGENCY_PREPAREDNESS_CONSULTING }
          headerColor={ '#3bafbf' }
          image = { '/images/emergency-preparedness-consultation.jpg' }
          imageAlt={ 'preparedness consultation' }
          action={ true }
          actionLabel={ s.common.LEARN_MORE }
          anchorLink={ ROUTES.services.paths.preparedness }
          onClick={ () => navigate(ROUTES.services.paths.preparedness) }
        />
        <ImageBlurb
          blurb={ s.emergency_management.EMERGENCY_RESPONSE_MANAGEMENT_BLURB }
          header={ s.emergency_management.EMERGENCY_RESPONSE_MANAGEMENT }
          image = { '/images/flood-rescue-team.jpg' }
          imageAlt={ 'response management' }
          action={ true }
          actionLabel={ s.common.LEARN_MORE }
          anchorLink={ ROUTES.services.paths.response }
          onClick={ () => navigate(ROUTES.services.paths.response) }
        />
        <ImageBlurb
          blurb={ s.emergency_management.DISASTER_RECOVERY_CONSULTING_BLURB }
          header={ s.emergency_management.DISASTER_RECOVERY_CONSULTING }
          image = { '/images/emergency-recovery-rebuilding.jpg' }
          imageAlt={ 'recovery consulting' }
          action={ true }
          actionLabel={ s.common.LEARN_MORE }
          anchorLink={ ROUTES.services.paths.recovery }
          onClick={ () => navigate(ROUTES.process.paths.recovery) }
        />
        <ImageBlurb
          blurb={ s.emergency_management.MITIGATION_EMERGENCY_PLANNING_BLURB }
          header={ s.emergency_management.MITIGATION_EMERGENCY_PLANNING }
          image = { '/images/emergency-mitigation-planning.jpg' }
          imageAlt={ 'mitigation planning' }
          action={ true }
          actionLabel={ s.common.LEARN_MORE }
          anchorLink={ ROUTES.services.paths.mitigation }
          onClick={ () => navigate(ROUTES.services.paths.mitigation) }
        />
      </div>
      <div className={ classNames(css.section, css.flood) }>
        <h2 className={ css['header-2'] }>
          { s.emergency_management.BENEFITS }
        </h2>
        <div className={ css.divider } />
        <h4 className={ css['header-4'] }>
          { s.emergency_management.OPTIMIZING }
        </h4>
      </div>
      <div className={ css.blocks }>
        <div className={ classNames(css.block, css['blue-1']) }>
          <div className={ css['block-fill'] }>
            { s.emergency_management.DISASTER_PLANNING }
          </div>
          <a href={ ROUTES.process.paths.preparedness }>
            <div className={ css['btn'] }>
              { s.emergency_management.PREPARE }
            </div>
          </a>
        </div>
        <div className={ classNames(css.block, css['blue-2']) }>
          <div className={ css['block-fill'] }>
            { s.emergency_management.DEEP_ROOTS }
          </div>
          <a href={ ROUTES.process.paths.response }>
            <div className={ css['btn'] }>
              { s.emergency_management.RESPOND }
            </div>
          </a>
        </div>
        <div className={ classNames(css.block, css['blue-3']) }>
          <div className={ css['block-fill'] }>
            { s.emergency_management.READY_TO_ACT }
          </div>
          <a href={ ROUTES.process.paths.recovery }>
            <div className={ css['btn'] }>
              { s.emergency_management.RECOVER }
            </div>
          </a>
        </div>
      </div>
      <div className={ classNames(css.section, css.consult) }>
        <div className={ css['image-overlay'] }>
          <h2 className={ css['header-2_5'] }>
              { s.common.EMERGENCY_MANAGEMENT_CONSULTING }
            </h2>
            <h3 className={ css['header-3'] }>
              { s.emergency_management.HIRING }
            </h3>
            <div className={ css['text-section'] }>
              <ActionParagraph
                action={ openContact }
                actionText={ s.common.SUBMIT_CONTACT }
                beginningText={ s.emergency_management.HIRING_INFO }
                endingText={ s.emergency_management.EMERGENCY_MANAGEMENT_CONSULTING_END }
              />
            </div>
          </div>
        </div>
      <div className={ css['footer-container'] }>
        <Footer openContact={ () => openContact() } />
      </div>
    </div>
  );
};

export default EmergencyManagement;
