import css from './wave.module.scss';


const WAVES = [
  {
    wave: 'M0 0h1024v77.3c-49-20.1-120.5-42.4-193.2-42.1-128.9.5-159.8 72.3-255.8 72.3-89.1 0-134.7-80.2-245-82-110.4-1.8-160.9 57.2-280 59.2-17.2.3-33.9-1.8-50-5.7V0z',
    opacity: '1',
  },
  {
    wave: 'M0 0h1024v140.5C995.9 162 963.9 178 926.9 178c-112.8 0-198.1-93.8-289.8-93.8s-199.5 91-285.3 91-142.2-82.1-238.5-82.1c-63.1 0-75.7 31.6-113.3 53.6V0z',
    opacity: '0.2',
  },
  {
    wave: 'M1024 0v75.9C984.3 60 942.2 48.6 905.3 50.1c-110.4 4.5-191.8 109.8-325.5 116.2C470.6 171.5 235.6 46.1 150.3 0H1024z',
    opacity: '0.2',
  },
  {
    wave: 'M0 0h1024v92c-57.5 30.9-116.9 73.7-199.9 75-133.8 2-158.9-87.9-342-87.9S202.1 183.7 74.2 182c-27.8-.3-52.4-4.2-74.2-9.7V0z',
    opacity: '0.2',
  },
  {
    wave: 'M0 0h1024v101.6C986.8 82 943.3 66.3 884.4 65.4 733.3 63 742.8 94 585.9 94.6S447 47.6 280.4 50.9C132.8 53.6 91.2 137.5 0 154V0z',
    opacity: '0.2',
  },
];

type WaveProps = {
  number: number;
  backgroundColor?: string;
  color?: string;
  invert?: boolean;
}

const Waves = ({
  number,
  backgroundColor = 'white',
  color = 'white',
  invert = false,
}: WaveProps) => {
  let waves;
  if (number <= WAVES.length) {
    waves = WAVES.slice(0, number);
  } else {
    waves = WAVES;
  }

  return (
    <div
      className={ css['wave-container'] }
      style={ { backgroundColor: invert ? color : backgroundColor } }
    >
      <div className={ css.waves } >
        {
          waves.map((wave, idx) => {
            return (
              <div
                key={ `wave-${idx}` }
                className={ css.wave }
                style={ { opacity: wave.opacity } }
              >
                <svg
                  viewBox={ '0 0 1024 182' }
                  preserveAspectRatio={ 'none' }
                  style={ {
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'transparent',
                    color: invert ? backgroundColor : color,
                  } }
                  fill={ 'currentColor' }
                >
                  <path className={ css.path } d={ wave.wave } />
                </svg>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default Waves;
