import { ReactElement, useEffect, useRef, useState } from 'react';

import css from './horizontal-scroll.module.scss';


type HorizontalScrollProps = {
  backgroundImage: string;
  children: ReactElement;
  scrollDirection?: 'left' | 'right';
  scrollRate?: number;
};

const HorizontalScroll = ({
  backgroundImage,
  children,
  scrollDirection = 'right',
  scrollRate = 1,
}: HorizontalScrollProps) => {
  const [position, setPosition] = useState<number>(0);
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      setPosition(window.scrollY + window.innerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const calculatePosition = () => {
    const h = ref.current?.clientHeight || 0;
    const w = ref.current?.clientWidth || 0;
    const scrollable = .2 * w / 2;
    const windowH = document.documentElement.offsetHeight;
    const rate = scrollRate ? (scrollable / (windowH + h)) / scrollRate : scrollable / (windowH + h);
    const offset = ( ref.current?.offsetTop || position) < position
      ? (position - (ref.current?.offsetTop || position)) * rate
      : 0;
    return `calc(50% ${scrollDirection === 'left' ? '-' : '+'} ${offset}px) 50%`;
  };

  return (
    <div
      className={ css.container }
      ref={ ref }
      style={
        {
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: calculatePosition(),
        }
      }
    >
      { children }
    </div>
  );
};

export default HorizontalScroll;
