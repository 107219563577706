import { ReactNode } from 'react';

import Waves from './waves';


type WaveProps = {
  backgroundColor: string;
  bottom: boolean;
  bottomColor: string;
  bottomTransitionColor: string;
  children: ReactNode;
  top: boolean;
  topColor: string;
  topTransitionColor: string;
};

const Wave = ({
  backgroundColor,
  bottom,
  bottomColor,
  bottomTransitionColor,
  children,
  top,
  topColor,
  topTransitionColor,
}: WaveProps) => {
  return (
    <div style={ { backgroundColor } }>
      { top &&
        <Waves
          number={ 5 }
          color={ topColor }
          invert={ true }
          backgroundColor={ topTransitionColor }
        />
      }
      { children }
      { bottom &&
        <Waves
          number={ 5 }
          color={ bottomColor }
          backgroundColor={ bottomTransitionColor }
        />
      }
    </div>
  );
};

export default Wave;
