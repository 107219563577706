import { FlexedRowProps } from '../components/BlogSections/FlexedRow';
import { TitledBodyProps } from '../components/BlogSections/TitledBody';
import { TitledDividedImageBodyProps } from '../components/BlogSections/TitledDividedImageBody';
import { TitledListProps } from '../components/BlogSections/TitledList';

import BrandLogo from '../assets/images/brand-logo-emergency-white.svg';
import Assessment from '../assets/images/operations-vulnerability-assessment.jpg';
import DisasterManagement from '../assets/images/preparedness-in-disaster-management.jpg';
import Preparedness from '../assets/images/preparedness-rpa.jpg';

import UrbanFlood from '../pages/Insight/images/urban-flooding.jpg';


const content = [
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: Preparedness,
          imageAlt: 'workers collaborating in room',
        },
      ],
      imageSide: 'right',
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'Why is Hazard Mitigation Planning important? How many times have we seen a community decimated by a disaster, then struggle to reassert itself over the following weeks and months? It’s an unfortunate story, but not an inevitable one. In an era of increasingly severe weather patterns and intense natural disasters, it’s important that communities arm themselves as much as possible against potential threats.\n\nA community hazard mitigation plan can help reduce the risk of losses from disaster situations. While it is impossible to eliminate all hazards, we can avoid or reduce the severity of disasters by working together to identify potential hazards and taking steps to mitigate them.',
            header: 'Hazard Mitigation Planning',
            subheader: 'Be Prepared Whenever Disaster Strikes',
          } as TitledBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: UrbanFlood,
          imageAlt: 'city street flooded',
        },
      ],
      imageSide: 'left',
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'A community hazard mitigation plan (CHMP) is a comprehensive, long-term strategy for reducing the risks of natural disasters. Community leaders and local government officials can use a hazard mitigation plan to prioritize initiatives and allocate resources to reduce the potential impact of a disaster on their community. Residents can also benefit from a CHMP, as it can help them understand how they can participate in hazard mitigation efforts and make informed decisions about their safety.',
            header: 'What is a Community Hazard Mitigation Plan (CHMP)?',
          } as TitledBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
  {
    component: 'flexed-row',
    props: {
      sections: [
        {
          component: 'titled-list',
          props: {
            body: 'Hazard mitigation planning is a proactive approach to reducing or eliminating the long-term risk to people and property. CHMPs identify vulnerabilities and consequences of specific threats and recommend actions to reduce or eliminate the risk. The goals of hazard mitigation planning are to:',
            body2: 'A well-designed hazard mitigation plan considers all phases of emergency management: prevention, protection, response, and recovery. A properly made mitigation plan should be a living document that is updated regularly to reflect new information about hazards, vulnerabilities, and consequences.',
            header: 'Why is it important to have a CHMP and how it Works?',
            items: [
              {
                value: 'prevent loss of life',
              },
              {
                value: 'protect property',
              },
              {
                value: 'minimize social and economic disruption',
              },
              {
                value: 'enable recovery from disasters',
              },
            ],
            ordered: true,
          } as TitledListProps,
        },
        {
          component: 'titled-list',
          props: {
            body: 'There are many benefits to having a CHMP in place, both for the community and individual residents. A CHMP can help to:',
            body2: 'A CHMP is an important tool for any community at risk of being impacted by a natural or man-made disaster. By having a plan, communities can be better prepared to respond to and recover from disasters.',
            header: 'How CHMP Helps Communities prepare and respond to disasters?',
            items: [
              {
                value: 'Save lives and protect property',
              },
              {
                value: 'Reduce the impact of disasters on the local economy',
              },
              {
                value: 'Help people recover more quickly after a disaster has struck',
              },
              {
                value: 'Allows local community members to be actively involved in hazard mitigation',
              },
              {
                value: 'Helps ensure that everyone in the community is aware of the dangers posed by hazards and knows what to do to stay safe',
              },
            ],
            isList: true,
          } as TitledListProps,
        },
      ],
    } as FlexedRowProps,
  },
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: Assessment,
          imageAlt: 'woman analyzing vulnerability report',
        },
      ],
      imageSide: 'right',
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'Hazard mitigation planning is often a continuous process, where systems and processes are consistently being adapted to evolving needs of the community. Some unique steps occur when setting up a hazard mitigation plan, which often involves community leaders, local authorities, and interested parties working together to identify and develop strategies to reduce or eliminate those risks. The goal here isn’t to create a perfect system but rather one adapted to the needs and unique characteristics of the community it serves.\n\nAt its most basic, there are three steps to this process: identifying potential risks, coming up with solutions, and finding ways to implement those solutions using current resources and systems.',
            header: 'What Are The Steps Involved In Creating a CHMP?',
          } as TitledBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
  {
    component: 'flexed-row',
    props: {
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'The first step in hazard mitigation planning is to conduct a risk and vulnerability assessment. The purpose of the assessment is to identify potential hazards and assess the risks they pose to a community. This step is important because it allows community leaders to identify what disaster events are most likely to occur and which pose the greatest risk to the community. By identifying these events, community leaders can then take steps to mitigate the risks they pose.\n\nThis step also helps quantify the most pressing risks and those with the greatest potential for destruction. Such information is critical in developing an effective hazard mitigation plan. This step is also a great place to get local leaders and community stakeholders involved in the process. They will be able to help identify risks and ways that the community can respond to them with clarity that only comes from being centered in a community.',
            subheader: 'Identifying Potential Risks',
          } as TitledBodyProps,
        },
        {
          component: 'titled-body',
          props: {
            body: 'The second step of the hazard mitigation planning process is centered around developing potential solutions and responses to the identified threats. This often involves community leaders, local authorities, and interested parties coming together to build up currently existing systems and create new processes to reduce or eliminate the dangers associated with the identified risk factors.',
            subheader: 'Strategizing Solutions',
            endImage: {
              image: BrandLogo,
              imageAlt: 'enigma advisory disaster recovery and logo',
            },
          } as TitledBodyProps,
        },
        {
          component: 'titled-body',
          props: {
            body: 'After solutions have been identified and vetted, it is time to start drafting the hazard mitigation plan. This document will serve as a living guide that can be used by emergency responders, government officials, and community members in the event of a disaster. The goal here is not to create a ‘perfect’ system but to create processes that are practical and beneficial to the community.\n\nThe hazard mitigation plan should outline ways the community can prepare itself before a disaster occurs and how those plans can be brought to fruition. The idea is to have actionable, measurable steps identified and prepared to minimize the effects of a disaster should one occur. The goal, as always, is to ensure the community’s safety and minimize the loss of life and property.',
            subheader: 'Creating and Maintaining the Plan',
          } as TitledBodyProps,
        },
      ],
    } as FlexedRowProps,
  },
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: DisasterManagement,
          imageAlt: 'meeting with people around a table',
        },
      ],
      imageSide: 'left',
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'A hazard mitigation plan is an incredibly useful tool for reducing the risks and effects of natural disasters. By prioritizing the most relevant and beneficial initiatives and allocating resources to where they are most needed, leaders and officials can better prepare their community for a disaster. Residents can also benefit from having a CHMP, as it can help them understand how they can participate in hazard mitigation.\n\nIf you want to learn more about Enigma Advisory and how we can help local areas prepare for and mitigate disaster situations, connect with us here.',
            header: 'Hazard Mitigation Planning Conclusion',
          } as TitledBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
];

export default content;
