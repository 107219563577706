import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import Footer from '../../../components/Footer';
import PageNotFound from '../../../components/PageNotFound';
import BlogPostTemplate from '../../../components/Templates/BlogPost';

import { BlogPost, getBlog } from '../blogs';

import css from './post.module.scss';


type PostProps = {
  openContact: () => void;
};

const Post = ({ openContact }: PostProps) => {
  const { id } = useParams();
  const post: BlogPost | undefined = getBlog(id || '');

  return (
    <>
      { post
        ? <div className={ css.post }>
            <Helmet>
              <title>{ post.seo.title }</title>
              <meta name={ 'description' } content={ post.seo.description } />
            </Helmet>
            <div className={ css['post-container'] }>
              <BlogPostTemplate content={ post } />
              <Footer openContact={ openContact } />
            </div>
          </div>
        : <div className={ css['four-oh-four'] }>
            <PageNotFound openContact={ openContact } />
          </div>
      }
    </>
  );
};

export default Post;
