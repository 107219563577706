import classNames from 'classnames';

import { ActionProps } from '../../../types';
import ActionParagraph from '../../ActionParagraph';

import css from './text-background-image.module.scss';

import s from '../../../i18n/strings.json';
import HorizontalScroll from '../../Parallax/HorizontalScroll';


type SectionProps = {
  backgroundImage: string;
  header: string;
  actionProps?: ActionProps;
  alt?: boolean;
  subheader?: string;
  text?: string;
};

const TextBackgroundImage = ({
  backgroundImage,
  header,
  actionProps,
  alt = false,
  subheader,
  text,
}: SectionProps) => {
  return (
    <HorizontalScroll
      backgroundImage={ backgroundImage }
      scrollDirection={ actionProps ? 'left' : 'right' }
    >
      <div
        className={ classNames( css['padding-10p']) }
      >
        <div className={ css['image-overlay'] }>
          <h2 className={ css['header-2'] }>
            { header }
          </h2>
          { subheader &&
            <h4 className={ css['header-4'] }>
              { subheader }
            </h4>
          }
          <div className={ css['text-section'] }>
            { text &&
              <p className={ css.paragraph }>
                { text }
              </p>
            }
            { actionProps && !alt &&
              <ActionParagraph
                action={ actionProps.action ? actionProps.action : () => {} }
                actionText={ s.common.SUBMIT_CONTACT }
                beginningText={ actionProps.text }
                endingText={ s.common.TODAY }
              />
            }
            { actionProps && alt &&
              <ActionParagraph
                action={ actionProps.action ? actionProps.action : () => {} }
                actionText={ s.common.CONTACT_US }
                beginningText={ actionProps.text }
                endingText={ actionProps.altText || '' }
              />
            }
          </div>
        </div>
      </div>
    </HorizontalScroll>
  );
};

export default TextBackgroundImage;
