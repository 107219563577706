import { TitledSubsectionBodyProps } from '../components/BlogSections/TitledSubsectionBody';

import UrbanFlood from '../pages/Insight/images/urban-flooding.jpg';


const content = [
  {
    component: 'titled-subsection-body',
    props: {
      header: 'Emergency Management Professionals',
      endImage: {
        image: UrbanFlood,
        imageAlt: 'city street flooded',
      },
      subsections: [
        {
          body: 'When disaster strikes, you need the support of emergency management professionals and you need that support right away. Acts of God rarely provide a pre-event warning.\n\nRecovering from a natural disaster can be a long and tedious process. Establishing an emergency response chain of command and action plan become critical assets when a community experiences the destructive aftermath of a disaster event. Accomplishing this requires the experienced and steady hand of emergency management professionals who can provide support in the immediate aftermath.',
          header: 'We’re Here When You Need Us',
        },
        {
          body: 'Immediately following a natural disaster, the priority should include mitigation planning to abate the effects to health and safety of the public. This is where knowledge and experience of response and recovery operations can be invaluable.\n\nEmergency management professionals train to move quickly and act decisively so they may provide needed and valued support. This can take the form of emergency response and recovery operations or the implementation of forward-thinking mitigation systems. A team of experienced and compassionate emergency response professionals can address the unique needs of your community and the various factors affecting your specific situation.',
          header: 'Acting with Urgency with the Help of Emergency Management Experts',
        },
        {
          body: 'Once initial relief has concluded and those who are most affected are safe, the long march to recovery can begin. This is where emergency management teams can be especially helpful.\n\nDisaster Recovery efforts go deeper than rebuilding homes and removing debris. It’s also about reclaiming the sense of peace and security that natural disasters can rip away from communities.\n\nFull recovery requires a cohesive plan acted out by capable and meticulous disaster recovery professionals and can take months or even years to complete. Creating a detailed, organized plan can dramatically speed up the process. The truth is that things will never be the same as before. There are immense opportunities, however, to rebuild the places affected by fire, flooding, and storms into strong, resilient facilities that can hold up against mother nature and man-made disasters.\n\nEven the most organized systems of leadership can be overwhelmed by the demand and the stress of recovery efforts. Avoid exhausting your team by forming a network of emergency recovery experts. Our team can break down the roadmap into manageable, actionable steps. As a community leader, you should feel confident in addressing the challenges of disaster recovery. Because it’s about more than just addressing the immediate situation. It is about identifying ways to build up systems and communities that are better prepared for any future events.\n\nIf disaster has struck your community, or you want to improve your organization’s preparedness/mitigation efforts, connect with us today.',
          header: 'Short-Term and Long-Term Support',
        },
      ],
    } as TitledSubsectionBodyProps,
  },
];

export default content;
