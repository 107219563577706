import { Menu } from '@carbon/icons-react';
import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { BASE_NAMESPACE } from '../../App';
import { ROUTES } from '../../Routes';
import { ReactComponent as Brand } from '../../assets/images/brand-enigma-advisory.svg';

import DropdownMenu from '../DropdownMenu';

import css from './navigation.module.scss';


type DropdownStates = {
  [state: string]: boolean;
};

export type NavigationItem = {
  label: string;
  icon?: ReactElement;
  mobileOnly?: boolean;
  onClick?: () => void;
  route?: string;
  tooltip?: string;
};

export type ActionDropdown = {
  icon: ReactElement;
  tooltip: string;
  menuItems: Array<NavigationItem>;
};

type LinkDropdown = {
  icon: ReactElement,
  menuItems: Array<NavigationItem>;
  menuLabel: string;
};

export type Namespace = {
  label: string;
  linkDropdowns: Array<LinkDropdown>;
  links: Array<NavigationItem>;
  href?: string,
};

export type Namespaces = {
  [namespace: string]: Namespace;
};

type NavigationProps = {
  namespace: Namespace;
  actionDropdowns: Array<ActionDropdown>;
  actionItems?: Array<NavigationItem>;
  selected?: string;
  subdirectory?: string;
};

const Navigation = ({
  namespace = BASE_NAMESPACE,
  actionDropdowns,
  actionItems,
  selected,
  subdirectory,
}: NavigationProps) => {
  const handleCustomRoutes = (route: string) => {
    if (`${namespace.href}/${route.toLowerCase()}` === ROUTES.aboutEM) {
      return 'About Us';
    }
    return route;
  };

  const [active, setActive] = useState(handleCustomRoutes(selected || ''));
  const [dropdowns, setDropdowns] = useState(createDropdownStates(
    actionDropdowns, namespace.linkDropdowns));
  const navigate = useNavigate();

  const onSelected = (val: string) => {
    setActive(handleCustomRoutes(val));
  };

  const handleMenuDropdown = (label: string, value: boolean) => {
    const newDrops: DropdownStates = {};
    Object.keys(dropdowns).forEach(d => {
      newDrops[d] = false;
    });
    newDrops[label] = value;
    setDropdowns(newDrops);
  };

  const handleLinkClick =(item: NavigationItem) => {
    item.onClick && item.onClick();
    item.route && navigate(item.route);
  };

  return (
    <React.Fragment>
      <div className={ css['header-container'] }>
        <div className={ css['menu-container'] }>
          { namespace.links.length > 0 &&
            <div className={ classNames(css['actions-container'], css.menu) }>
              <div
                className={ css['clickable-action'] }
                onMouseEnter={ () => handleMenuDropdown('menu', true) }
                onMouseLeave={ () => handleMenuDropdown('menu', false) }
              >
                <Menu />
                { dropdowns.menu &&
                  <div className={ css['action-dropdown-left'] }>
                    <div className={ css['link-menu'] }>
                      { namespace.links.map(item => {
                          return (
                            <React.Fragment key={ `${item.label}-menu-item` }>
                              <a href={ item.route }
                                className={ classNames(
                                    css['menu-item'],
                                    {
                                      [css.selected]: item.label === active,
                                    },
                                  )
                                }
                              >
                                { item.label }
                              </a>
                              <div className={ css['menu-divider'] } />
                            </React.Fragment>
                          );
                        })
                      }
                      { namespace.linkDropdowns.map(dropdown =>
                          <div key={ `${dropdown.menuLabel}-menu-item` } className={ css['pad-1'] }>
                            <DropdownMenu
                              label={ dropdown.menuLabel }
                              menuItems={ dropdown.menuItems }
                              selected={ handleCustomSubdirectories(subdirectory || '') }
                            />
                          </div>
                        )
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          }
          <div className={ css['title-container'] }>
            <a href='/'>
              <Brand />
            </a>
            { namespace?.label &&
              <div className={ css.title }>
                <a href={ namespace.href || '/' }>
                { namespace.label }
              </a>
              </div>
            }
            <div className={ css.divider } />
          </div>
        </div>
        <div className={ css['links-container'] }>
          { namespace.links.map(item => {
            return (
              <a
                key={ `${item.label}-nav-item` }
                className={ classNames(
                    css.clickable,
                    { [css.selected]: item.label === active }
                  )
                }
                onClick={ () => onSelected(item.label) }
                href={ item.route }
              >
                { item.label }
              </a>
            );
          })}
          { namespace.linkDropdowns.map(dropdown => {
              return (
                <div
                  key={ `${dropdown.menuLabel}-nav-item` }
                  className={ classNames(
                      css.clickable,
                      { [css.selected]: dropdown.menuLabel === active }
                    )
                  }
                  onMouseEnter={ () => handleMenuDropdown(dropdown.menuLabel, true) }
                  onMouseLeave={ () => handleMenuDropdown(dropdown.menuLabel, false) }
                >
                  { dropdown.menuLabel }
                  { dropdown.icon }
                  { dropdowns[dropdown.menuLabel] &&
                    <div className={ css['link-dropdown'] }>
                      <div className={ css['link-menu'] }>
                        { dropdown.menuItems.map(item => {
                            return (
                              <React.Fragment key={ `${item.label}-menu-item` }>
                                <a href={ item.route }
                                  className={ classNames(
                                      css['menu-item'],
                                      {
                                        [css.selected]: item.label.toLocaleLowerCase() === handleCustomSubdirectories(subdirectory || ''),
                                      },
                                    )
                                  }
                                >
                                  { item.label }
                                </a>
                                <div className={ css['menu-divider'] } />
                              </React.Fragment>
                            );
                          })
                        }
                      </div>
                    </div>
                  }
                </div>
              );
            })
          }
        </div>
        <div className={ css['actions-container'] }>
          { actionItems?.map(item => {
              return (
                <React.Fragment key={ `${item.label}-action` }>
                  <div
                    id={ `${item.label}-tooltip` }
                    className={ classNames(
                        css['clickable-action'],
                        { [css['mobile-only']]: item.mobileOnly }
                      )
                    }
                    onClick={ () => handleLinkClick(item) }
                  >
                    { item.icon }
                  </div>
                  <Tooltip
                    anchorSelect={ `#${item.label}-tooltip` }
                    place={ 'bottom' }
                    content={ item.tooltip || '' }
                  />
                </React.Fragment>
              );
            })
          }
          { actionDropdowns?.map(dropdown => {
              return (
                <React.Fragment key={ `${dropdown.tooltip}-action` }>
                  <div
                    id={ `${dropdown.tooltip}-tooltip` }
                    className={ css['clickable-action'] }
                    onMouseEnter={ () => handleMenuDropdown(dropdown.tooltip, true) }
                    onMouseLeave={ () => handleMenuDropdown(dropdown.tooltip, false) }
                  >
                    { dropdown.icon }
                    { dropdowns[dropdown.tooltip] &&
                      <div className={ css['action-dropdown'] }>
                        <div className={ css['link-menu'] }>
                          { dropdown.menuItems.map(item => {
                              return (
                                <React.Fragment key={ `${item.label}-menu-item` }>
                                  <a className={ css['menu-item'] } href={ item.route }>
                                    { item.icon }
                                  </a>
                                  <div className={ css['menu-divider'] } />
                                </React.Fragment>
                              );
                            })
                          }
                        </div>
                      </div>
                    }
                  </div>
                  <Tooltip
                    anchorSelect={ `#${dropdown.tooltip}-tooltip` }
                    place={ 'bottom' }
                    content={ dropdown.tooltip || '' }
                  />
                </React.Fragment>
              );
            })
          }
        </div>
      </div>
      <div className={ css.floating }>
        { actionItems?.map(item => {
            if (item.mobileOnly) {
              return (
                <React.Fragment key={ `floating-action-${item.label}` }>
                  <div
                    id={ `floating-action-${item.label}` }
                    className={ css['action-container'] }
                    onClick={ () => handleLinkClick(item) }
                  >
                    <div className={ css['floating-action'] }>
                      { item.icon }
                    </div>
                  </div>
                  <Tooltip
                    anchorSelect={ `#floating-action-${item.label}` }
                    place={ 'top' }
                    content={ item.tooltip }
                  />
                </React.Fragment>
              );
            }
          })
        }
      </div>
    </React.Fragment>
  );
};

const createDropdownStates = (actions: Array<ActionDropdown>, links: Array<LinkDropdown>): DropdownStates => {
  const states: DropdownStates = {
    menu: false,
  };
  actions.forEach(action => {
    states[action.tooltip] = false;
  });
  links.forEach(dropdown => {
    states[dropdown.menuLabel] = false;
  });
  return states;
};

const handleCustomSubdirectories = (subdir: string) => {
  if (subdir.toLowerCase() === 'emergency management consulting') {
    return 'emergency preparedness consulting';
  }
  if (subdir.toLowerCase() === 'emergency response center') {
    return 'emergency operations center';
  }
  if (subdir.toLowerCase() === 'disaster preparedness') {
    return 'preparedness';
  }
  if (subdir.toLowerCase() === 'disaster response') {
    return 'response';
  }
  if (subdir.toLowerCase() === 'disaster recovery') {
    return 'recovery';
  }
  if (subdir.toLowerCase() === 'disaster mitigation') {
    return 'ongoing support';
  }
  return subdir;
};

export default Navigation;
