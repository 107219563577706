import { EnigmaDocumentProps } from '../components/BlogSections/EnigmaDocument';
import { FlexedRowProps } from '../components/BlogSections/FlexedRow';
import { TitledBodyProps } from '../components/BlogSections/TitledBody';
import { ImageBlurbProps } from '../components/ImageBlurb';

import Family from './images/family-evacuating.jpg';
import Facetime from './images/family-facetime.jpg';
import Tornado from './images/tornado-aftermath.jpg';

import FamilyPlan from '../pages/Insight/images/family-planning.jpg';


const content = [
  {
    component: 'image-blurb',
    props: {
      blurb: 'Navigating a disaster situation can be difficult. But it doesn’t have to be an arduous task as long as you prepare yourself accordingly. You’ll want to do everything you can to ensure your family is ready for an emergency, including an evacuation order. We have outlined some things you can do when an evacuation notice gets sent out to your area.',
      header: 'Navigating Disaster The Right Way',
      image: Tornado,
      imageAlt: 'aftermath of home hit my tornado',
    } as ImageBlurbProps,
  },
  {
    component: 'image-blurb',
    props: {
      blurb: 'It may be an emergency, but taking a moment to pause and collect yourself can make an incredible difference to your decision-making capabilities. When we get stressed out, parts of our brain go into fight or flight mode, and every decision you make in this state will be based on immediate survival rather than sound logic or reasoning. This can lead to poor judgment calls, impulsiveness, and even accidents. By simply taking a breath and reminding yourself that you will get through this, you can help ease some of that tension and allow your brain to function more clearly.',
      header: 'Stay Calm and Think Clearly',
      image: Family,
      imageAlt: 'woman evacuating children',
      rightImage: true,
    } as ImageBlurbProps,
  },
  {
    component: 'flexed-row',
    props: {
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'If you already have an emergency plan set out, this is the time to break it out. Ensure that all foods are still edible, clothes are wearable and size-appropriate, that any copies of essential documents are valid, and that battery-powered equipment is functional. This will make you as prepared as you can be going into this situation and should give all of you a confidence boost, knowing that all the equipment your family could need is right at your fingertips.\n\nIf you don’t have a kit already prepared, do what you can to collect dry or canned goods, water, and necessary medications. However, please be considerate of others and only take the amount you and your family need. Panic buying can become a severe problem during emergencies. It can lead to many people being unable to source life-saving medications or resources like water and edible foods. That is why we recommend buying provisions ahead of time and storing them in a cool, dry place. Once you have all of your necessary materials, collect any important documents that may be difficult to replace (birth certificates, IDs, etc.). If you have devices such as phones and laptops, make efforts to charge them before leaving and pack extra charge chords and plug-ins.\n\nShut off gas or electricity if your area’s officials instruct you instructed to do so. Ensure your car is gassed up and locate the best exit thoroughfare for your current location. There are often public safety zones, but if you have a safe place to go outside the danger zone, find the best path out of the area in the direction you need to go. Stick to main roads, as side streets may be closed-off.',
            header: 'Refer to Your Evacuation Plan',
          } as TitledBodyProps,
        },
        {
          component: 'enigma-document',
          props: {
            body: 'It is critical that you follow the guidance of local officials during an emergency evacuation situation. These officials are often guided by emergency management specialists, who are looking at the situation from a 30,000 feet lens and are making decisions based on how to service the entire affected area, including you and your family. Part of their job is to ensure the safety of those living in the area any affected area, and that includes getting you and your family to safety. Often they will be able to provide you with evacuation options and resources and direct you towards public shelters and supply centers should you need them. Remember – they are there to help you. Don’t be afraid to ask for assistance when and if you need it.',
            header: 'Follow Evacuation Instructions From Authorities',
          } as EnigmaDocumentProps,
        },
      ],
    } as FlexedRowProps,
  },
  {
    component: 'image-blurb',
    props: {
      blurb: 'After the danger has passed, it is imperative that you take the time to assess any damages that may have occurred on the property. Keep track of all damages and take pictures to show insurance officials and other interested parties. This will make things easier for you as you work towards recovery and rebuilding your community.\n\nAnother, often forgotten element of recovery is mental or emotional recovery. Assess your mental state and work to discern whether you need to seek professional assistance to fully process the events. There is no shame in seeking out the help that you need, including psychological assistance. If you can, try to get back to everyday life as quickly as possible. Maintaining a routine allows you to re-establish some sense of normalcy and stability in your life. However, recovery following a disaster will look different for each family and situation, and you should always do what is best for you and your family.',
      header: 'Check-In After the Danger Has Passed',
      image: Facetime,
      imageAlt: 'elderly couple using video call',
      rightImage: true,
    } as ImageBlurbProps,
  },
  {
    component: 'image-blurb',
    props: {
      blurb: 'Disasters can happen at any time, so it is important to be prepared. First, make sure you know what the evacuation plan is for your area and have a family emergency plan in place. Have a list of items you will need to take with you if you are forced to leave in a hurry. And lastly, make sure you are familiar with the warning signs of an impending disaster. By being prepared, you can ensure that you and your loved ones will be safe during times of crisis. For more information on emergency management and preparation, please visit our website or contact us today.',
      header: 'Conclusion',
      image: FamilyPlan,
      imageAlt: 'family emergency planning on couch',
    } as ImageBlurbProps,
  },
];

export default content;
