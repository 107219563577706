import {
  Route,
  BrowserRouter as Router,
  Routes as Switch,
} from 'react-router-dom';

import About from './pages/About';
import EmergencyManagement from './pages/EmergencyManagement';
import FourOhFour from './pages/FourOhFour';
import GrantsManagement from './pages/GrantsMangement';
import Home from './pages/Home';
import Insight from './pages/Insight';
import Post from './pages/Insight/Post';
import Process from './pages/Process';
import Redirect, { BlogRedirect } from './pages/Redirect';
import Services from './pages/Services';
import Technology from './pages/Technology';
import Testimonials from './pages/Testimonials';
import { BLOG_ROUTES } from './util/constants';


type DynamicRoute = {
  path: string;
  paths: {
    [path: string]: string;
  };
};

type RouteMap = {
  aboutEM: string;
  insight: string;
  blogPost: string;
  emergencyManagement: string;
  fourOhFour: string;
  grantsManagement: string;
  home: string;
  process: DynamicRoute;
  services: DynamicRoute;
  solutions: DynamicRoute;
  technology: string;
  testimonials: string;
};

export const ROUTES: RouteMap = {
  home: '/',

  /* EmergencyManagement Recovery Routes */
  aboutEM: '/emergency-management/about-enigma-advisory',
  insight: '/emergency-management/insight',
  blogPost: '/emergency-management/insight/:id',
  emergencyManagement: '/emergency-management',
  fourOhFour: '/404',
  process: {
    path: '/emergency-management/process/:id',
    paths: {
      mitigation: '/emergency-management/process/disaster-mitigation',
      preparedness: '/emergency-management/process/disaster-preparedness',
      recovery: '/emergency-management/process/disaster-recovery',
      response: '/emergency-management/process/disaster-response',
    },
  },
  testimonials: '/emergency-management/testimonials',
  services: {
    path: '/emergency-management/services/:id',
    paths: {
      mitigation: '/emergency-management/services/mitigation-planning',
      preparedness: '/emergency-management/services/emergency-management-consulting',
      recovery: '/emergency-management/services/emergency-response-center',
      response: '/emergency-management/services/emergency-response-consulting',
    },
  },

  /* Grants Management Routes */
  grantsManagement: '/grants-management',

  /* Technology Routes */
  technology: '/technology',
  solutions: {
    path: '/technology/solutions/:id',
    paths: {
      advisory: '/technology/solutions/advisory',
      cloud: '/technology/solutions/cloud',
      devops: '/technology/solutions/devops',
      software: '/technology/solutions/software',
      web: '/technology/solutions/web-development',
    },
  },
};

type RouteProps = {
  contactOpen: () => void;
  windowWidth: number;
};

const Routes = ({ contactOpen, windowWidth }: RouteProps) => {
  return (
    <Router>
      <Switch>
        <Route
          path={ '/about-enigma-advisory' }
          element={ <Redirect /> }
        />
        <Route
          path={ '/enigma-advisory-blog' }
          element={ <Redirect /> }
        />
        <Route
          path={ '/testimonials' }
          element={ <Redirect /> }
        />
        <Route
          path={ '/process/:id' }
          element={ <Redirect /> }
        />
        <Route
          path={ '/services/:id' }
          element={ <Redirect /> }
        />
        { /* Redirects for blogs */
          BLOG_ROUTES.map(route =>
            <Route
              key={ `${route}-redirect` }
              path={ `/${route}` }
              element={ <BlogRedirect /> }
            />
          )
        }

        <Route path={ ROUTES.home } element={ <Home openContact={ contactOpen } /> } />
        <Route path={ ROUTES.aboutEM } element={ <About openContact={ contactOpen } /> } />
        <Route path={ ROUTES.blogPost } element={ <Post openContact= { contactOpen } /> } />
        <Route path={ ROUTES.emergencyManagement } element={ <EmergencyManagement openContact={ contactOpen } /> } />
        <Route path={ ROUTES.grantsManagement } element={ <GrantsManagement openContact={ contactOpen } /> } />
        <Route path={ ROUTES.insight } element={ <Insight openContact= { contactOpen } windowWidth={ windowWidth } /> } />
        <Route path={ ROUTES.process.path } element={ <Process openContact={ contactOpen } /> } />
        <Route path={ ROUTES.services.path } element={ <Services openContact={ contactOpen } /> } />
        { /* Disabling until individual pages have content <Route path={ ROUTES.solutions.path } element={ <Solutions openContact={ contactOpen } /> } /> */ }
        <Route path={ ROUTES.technology } element={ <Technology openContact={ contactOpen } /> } />
        <Route path={ ROUTES.testimonials } element={ <Testimonials openContact={ contactOpen } /> } />

        <Route path={ '*' } element={ <FourOhFour openContact={ contactOpen } /> } />
      </Switch>
    </Router>
  );
};

export default Routes;
