import moment from 'moment';

import { BlogPost } from '../../../pages/Insight/blogs';
import Header from '../../PageSections/Header';

import { COMPONENT_MAP } from '../../../util/constants';

import css2 from '../../BlogSections/blog-sections.module.scss';
import css from '../templates.module.scss';


type BlogPostTemplateProps = {
  content: BlogPost;
};

const BlogPostTemplate = ({ content }: BlogPostTemplateProps) => {
  return (
    <div className={ css.services }>
      <div className={ css['header-wrapper'] }>
        <Header backgroundImage={ content.image } header={ content.header } />
        { content.date &&
          <div className={ css.date }>
            { `${moment(content.date).format('MMMM D, YYYY')}` }
          </div>
        }
      </div>
      { content.content?.map((c, idx) => {
          const El = COMPONENT_MAP[c.component];
          return (
            <div key={ `component-${idx}` } className={ css2['section-wrapper'] }>
              <El { ...c.props } />
            </div>
          );
        })
      }
    </div>
  );
};

export default BlogPostTemplate;
