import { Helmet } from 'react-helmet';

import Flood from '../../assets/images/flood-aerial.jpg';
import Joshua from '../../assets/images/Joshua-Rosales.jpg';

import PageTemplate, { PageProps } from '../../components/Templates/EmergencyManagement';

import s from '../../i18n/strings.json';


type AboutProps = {
  openContact?: () => void;
};

const PROPS: PageProps = {
  seo: {
    description: s.common.META_DESCRIPTION2,
    title: s.about.TITLE,
  },
  header: {
    backgroundImage: Flood,
    header: s.common.ABOUT_US,
    subheader: s.common.ENIGMA_ADVISORY,
  },
  section1: {
    header: s.about.ABOUT,
    text: s.about.ABOUT_INFO,
  },
  imageBlurbs: [
    {
      blurb: s.about.IMAGE_BLURB1,
      header: s.about.IMAGE_BLURB1_HEADER,
      image: Joshua,
      imageAlt: 'Joshua Rosales',
    },
  ],
};

const About = ({ openContact = () => {} }: AboutProps) => {
  return (
    <>
      <Helmet >
        <title>{ PROPS.seo.title }</title>
        <meta name={ s.common.DESCRIPTION } content={ PROPS.seo.description } />
      </Helmet>
      <PageTemplate openContact={ openContact } props={ PROPS } />
    </>
  );
};

export default About;
