import { TitledDividedImageBodyProps } from '../components/BlogSections/TitledDividedImageBody';
import { TitledImageBodyProps } from '../components/BlogSections/TitledImageBody';
import { TitledSubsectionBodyProps } from '../components/BlogSections/TitledSubsectionBody';

import CommunityMeeting from './images/community-meeting.jpg';
import Donations from './images/donation-center.jpg';
import Hands from './images/group-hands.jpg';

import MitigationManagement from '../assets/images/preparedness-mitigation-management.jpg';


const content = [
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: CommunityMeeting,
          imageAlt: 'woman listening during community group session',
        },
      ],
      imageSide: 'right',
      sections: [
        {
          component: 'titled-subsection-body',
          props: {
            header: 'How a Disaster Mitigation Plan Helps Protect Communities',
            subsections: [
              {
                body: 'In the aftermath of a natural disaster, it’s not just the physical damage that can take a toll on a community. The financial cost of rebuilding can be astronomical, and the psychological effects of living through a traumatic event can linger for years. That’s why it’s so important to have a disaster mitigation plan for the community in place. So communities can be prepared to mitigate the effects of a disaster before it happens. In this article, we have taken a look at how disaster mitigation plans help protect communities, how community members can engage with a mitigation strategy, and how working with disaster mitigation specialists can make the process easier.\n\nDisaster mitigation plans are designed to reduce the physical, social, economic, and environmental impacts of disasters. By taking steps to lessen the impact of a disaster before it happens, communities can save lives, reduce damage to infrastructure, and speed up the recovery process. Some of the ways by which a disaster mitigation plan for the community can help protect communities include:',
                closing: 'Each of these elements, by themselves, can be enough to make the endeavor worthwhile. Together, it turns the development of a plan from an important task to an imperative, even life-saving effort.',
                header: 'Plans Built for Successful Response',
                listItems: [
                  'Lessening loss of life by identifying potential hazards and taking steps to improve safety',
                  'Curtailed damage to buildings and other infrastructure by identifying vulnerabilities and implementing strengthening measures',
                  'Diminished economic losses by identifying ways to keep businesses operating during and after a disaster',
                  'Minimizing disruptions to essential services by developing continuity plans',
                  'Reducing psychological trauma by providing support and assistance to those affected by a disaster',
                ],
              },
            ],
          } as TitledSubsectionBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: Donations,
          imageAlt: 'volunteers working at donation center',
        },
      ],
      imageSide: 'left',
      sections: [
        {
          component: 'titled-subsection-body',
          props: {
            header: 'How Community Members Can Engage with a Mitigation Strategy',
            subsections: [
              {
                body: 'Community members play an essential role in mitigating the effects of disasters. Some of the ways a community member can get involved in assisting with disaster mitigation plans for the community include:',
                closing: 'Looking for ways to get your community started on the path to developing a mitigation strategy? Our team here at Enigma Advisory can help. Connect here with our team, and see how we can work together to create a plan that takes your community’s specific needs into account. We can also help you with things like identifying high-risk areas, creating evacuation plans, and much more. For more information about our services, visit our website or give us a call today.',
                header: 'Building Disaster Mitigation Plan For The Community',
                listItems: [
                  'Learning about hazards that affect their community and what actions they can take to stay safe',
                  'Heeding warnings from authorities and evacuating when instructed to do so',
                  'Participating in community outreach programs that raise awareness about disaster preparedness and response',
                  'Volunteering for or donating to organizations that provide relief and assistance during times of crisis',
                ],
              },
            ],
          } as TitledSubsectionBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
  {
    component: 'titled-image-body',
    props: {
      body: 'Looking for help developing your plan? Disaster mitigation specialists can help you quickly develop comprehensive plans tailored specifically to your community’s needs while ensuring compliance with all local, state, and federal regulations. A professional disaster management firm will also help establish relationships with key stakeholders in your community, which can help ensure buy-in for your mitigation plan from government officials, business leaders, first responders, and others. Finally, working with an experienced CM firm can save your community time and money by avoiding costly mistakes often made during the planning stage, as well as help review any existing plans and policies to identify gaps and opportunities for improvement\n\nUltimately, having a well-thought-out disaster mitigation plan for the community is one of the best things a community can do to protect itself from the devastating effects of natural disasters. Community members play an important role in making sure that these plans are successful—by learning about hazards, heeding warnings from authorities, participating in outreach programs, and volunteering or donating to relief efforts—but working with experienced professionals is often essential to developing an effective strategy. If your community doesn’t have a disaster mitigation plan in place yet, now is the time to start putting one together.\n\nLooking for help developing a plan? Connect with us and see how we can help!',
      header: 'Working with Disaster Mitigation Specialists',
      image: Hands,
      imageAlt: 'group meeting hand gestures',
      imageSide: 'right',
      subheader: 'Developing an Effective Strategy',
    } as TitledImageBodyProps,
  },
  {
    component: 'titled-image-body',
    props: {
      body: 'The disaster management and recovery industry is changing, and it’s important to stay up-to-date on the latest news and updates. But, as always, we must learn from past events to be better prepared for future ones. If you are interested in learning more about disaster management or recovery, please don’t hesitate to connect with our experts here at Enigma Advisory. We can help you navigate these changes and prepare your business for potential disasters.',
      header: 'Conclusion',
      image: MitigationManagement,
      imageAlt: 'people planning with design documents',
      imageSide: 'left',
    } as TitledImageBodyProps,
  },
];

export default content;
