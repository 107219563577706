import css from './page-not-found.module.scss';

import Flood from '../SVG/Flood';
import Storm from '../SVG/Storm';

import { Helmet } from 'react-helmet';
import s from '../../i18n/strings.json';
import Footer from '../Footer';


type PageNotFoundProps = {
  openContact: () => void;
};

const PageNotFound = ({ openContact }: PageNotFoundProps) => {
  return (
    <>
    <Helmet>
      <title>{ s['404'].TITLE }</title>
      <meta name={ s.common.DESCRIPTION } content={ s['404'].TITLE } />
    </Helmet>
      <div className={ css['page-not-found'] }>
        <div className={ css.header }>
          { s['404'].DISASTER }
        </div>
        <div className={ css['image-container'] }>
          <div className={ css.storm }>
            <Storm />
          </div>
          <div className={ css.flood }>
            <Flood />
          </div>
        </div>
        <div className={ css.subheader }>
          { s['404'].UNPREPARED  }
        </div>
      </div>
      <Footer openContact={ () => openContact() } />
    </>
  );
};

export default PageNotFound;
