import {
  Close,
  Email,
  Enterprise,
  Phone,
  Time,
} from '@carbon/icons-react';
import {
  Button,
  Loading,
  TextArea,
  TextInput,
  ToastNotification,
} from '@carbon/react';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as Facebook } from '../../../assets/images/icon-facebook.svg';
import { ReactComponent as LinkedIn } from '../../../assets/images/icon-linkedIn.svg';
import { ReactComponent as X } from '../../../assets/images/icon-x.svg';

import { EMAIL_REGEX, FACEBOOK, LINKED_IN, PHONE_NUMBER_REGEX, X as X_ } from '../../../util/constants';

import css from './contact.module.scss';

import s from '../../../i18n/strings.json';


export type FormPayload = {
  email: string;
  first: string;
  last: string;
  message: string;
  phone: string;
};

type ContactFormProps = {
  isLoading: boolean;
  onCancel: () => void;
  onNotificationClose: () => void;
  onSubmit: (payload: FormPayload) => void;
  notification?: string;
};

const Contact = ({
  isLoading,
  onCancel,
  onNotificationClose = () => true,
  onSubmit,
  notification,
}: ContactFormProps) => {
  const [email, setEmail] = useState<string>('');
  const [first, setFirst] = useState<string>('');
  const [last, setLast] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  /**
   * Validates the contact form is complete with valid information
   * @returns - Whether all fields have valid input
   */
  const validInput = (): boolean => {
    const validEmail = email?.length > 0 && EMAIL_REGEX.test(email);
    const validPhone = phone?.length > 0 && PHONE_NUMBER_REGEX.test(phone);
    return validEmail
      && validPhone
      && first.length > 0 && first.length <= 32
      && last.length> 0 && last.length <= 32
      && message.length > 0;
  };

  const handleSubmit = () => {
    onSubmit({
      email,
      first,
      last,
      message,
      phone,
    });
  };

  return (
    <div className={ css['form-container'] }>
      <div className={ css.close } onClick={ () => onCancel() }>
        <Close id={ 'close-icon' } size={ 'md' } />
        <Tooltip
          anchorSelect={ '#close-icon' }
          place={ 'bottom' }
          content={ s.common.CLOSE }
        />
      </div>
      <div className={ css.form }>
        { notification &&
          <ToastNotification
            title={ 'Error' }
            subtitle={ notification }
            kind={ 'error' }
            onClose={ () => onNotificationClose() }
          />
        }
        <div className={ css.header }>
          { s.common.CONTACT_ENIGMA }
        </div>
        <div className={ css['input-row'] }>
          <div className={ css['input-label'] }>
            { s.contact.NAME }
          </div>
          <div className={ css['input-item'] }>
            <TextInput
              id={ 'first-name-input' }
              labelText={ '' }
              warn={ first.length <= 0 || first.length > 32 }
              warnText={ first.length <= 0 ? s.contact.FIRST_REQUIRED : s.contact.LONGER_THAN_32_CHARS }
              helperText={ s.contact.FIRST }
              onChange={ (event) => setFirst(event.target.value) }
            />
            <TextInput
              id={ 'last-name-input' }
              labelText={ '' }
              warn={ last.length <= 0 || last.length > 32 }
              warnText={ last.length <= 0 ? s.contact.LAST_REQUIRED : s.contact.LONGER_THAN_32_CHARS }
              helperText={ s.contact.LAST }
              onChange={ (event) => setLast(event.target.value) }
            />
          </div>
        </div>
        <div className={ css['input-row'] }>
          <div className={ css['input-label'] }>
            { s.contact.PHONE }
          </div>
          <div className={ css['input-item'] }>
            <TextInput
              id={ 'phone-input' }
              labelText={ '' }
              warn={ !PHONE_NUMBER_REGEX.test(phone) }
              warnText={ s.contact.VALID_PHONE }
              onChange={ (event) => setPhone(event.target.value) }
            />
          </div>
        </div>
        <div className={ css['input-row'] }>
          <div className={ css['input-label'] }>
            { s.contact.EMAIL }
          </div>
          <div className={ css['input-item'] }>
            <TextInput
              id={ 'email-input' }
              labelText={ '' }
              warn={ !EMAIL_REGEX.test(email) }
              warnText={ s.contact.VALID_EMAIL }
              onChange={ (event) => setEmail(event.target.value) }
            />
          </div>
        </div>
        <div className={ css['input-row'] }>
          <div className={ css['input-label'] }>
            { s.contact.MESSAGE }
          </div>
          <div className={ css['input-item'] }>
            <TextArea
              id={ 'message-input' }
              labelText={ '' }
              warn={ message.length <= 0 }
              warnText={ s.contact.MESSAGE_REQUIRED }
              onChange={ (event) => setMessage(event.target.value) }
            />
          </div>
        </div>
        <div className={ css.info }>
          <div className={ css['label-container'] }>
            <div className={ css['icon-label'] }>
              <Enterprise />
              { s.footer.AUSTIN_TX }
            </div>
            <div className={ css['icon-label'] }>
              <Email />
              { s.footer.ENIGMA_EMAIL }
            </div>
            <div className={ css['icon-label'] }>
              <Phone />
              { s.footer.ENIGMA_PHONE }
            </div>
            <div className={ css['icon-label'] }>
              <Time />
              { s.contact.OPERATING_HOURS }
            </div>
          </div>
          <div className={ css.social }>
            <a href={ LINKED_IN } target={ '_blank' } rel={ 'noreferrer' }>
              <LinkedIn />
            </a>
            <a href={ FACEBOOK } target={ '_blank' } rel={ 'noreferrer' }>
              <Facebook />
            </a>
            <a href={ X_ } target={ '_blank' } rel={ 'noreferrer' }>
              <X />
            </a>
          </div>
        </div>
        <div className={ css['form-controls'] }>
          <div className={ css['form-buttons'] }>
            <Button onClick={ () => onCancel() }>
              { s.common.CANCEL }
            </Button>
            <Button
              disabled={ !validInput() }
              onClick={ () => handleSubmit() }
            >
              { s.common.SUBMIT }
            </Button>
          </div>
        </div>
        <div className={ css['close-mobile'] } onClick={ () => onCancel() }>
          <Close id={ 'close-icon' } size={ 'md' } />
          <Tooltip
            anchorSelect={ '#close-icon' }
            place={ 'bottom' }
            content={ s.common.CLOSE }
          />
        </div>
      </div>
      { isLoading && <Loading withOverlay={ true } /> }
    </div>
  );
};

export default Contact;
