import { PageProps } from '../../components/Templates/EmergencyManagement';

import Assessment from '../../assets/images/operations-center-assessment.jpg';
import Center from '../../assets/images/operations-center.jpg';
import { default as Design, default as Training } from '../../assets/images/operations-design.jpg';
import Vulnerability from '../../assets/images/operations-vulnerability-assessment.jpg';
import Mitigation from '../../assets/images/preparedness-mitigation.jpg';
import RPM from '../../assets/images/response-planning-mitigation.jpg';

import s from '../../i18n/strings.json';


/* Props for Emergency Recovery Center Support page */
const props: PageProps = {
  seo: {
    description: s.services.operations.META_DESCRIPTION,
    title: s.services.operations.TITLE,
  },
  header: {
    backgroundImage: Center,
    header: s.common.EMERGENCY_OPERATIONS_CENTER,
    subheader: s.common.ENIGMA_ADVISORY,
  },
  section1: {
    header: s.common.EMERGENCY_OPERATIONS_CENTER,
    text: s.services.operations.EMERGENCY_OPERATIONS_CENTER_INFO,
  },
  section2: {
    backgroundImage: Mitigation,
    header: s.services.operations.EOC_TRAINING,
    text: s.services.operations.EOC_TRAINING_INFO,
  },
  imageBlurbs: [
    {
      blurb: s.services.operations.IMAGE_BLURB1,
      header: s.services.operations.IMAGE_BLURB1_HEADER,
      image: Assessment,
      imageAlt: 'operations center assessment',
      color: '#3bafbf',
    },
    {
      blurb: s.services.operations.IMAGE_BLURB2,
      header: s.services.operations.IMAGE_BLURB2_HEADER,
      image: Vulnerability,
      imageAlt: 'vulnerability and capability assessment',
    },
    {
      blurb: s.services.operations.IMAGE_BLURB3,
      header: s.services.operations.IMAGE_BLURB3_HEADER,
      image: Design,
      imageAlt: 'design and development',
    },
    {
      blurb: s.services.operations.IMAGE_BLURB4,
      header: s.services.operations.IMAGE_BLURB4_HEADER,
      image: Training,
      imageAlt: 'assets and manuals',
    },
  ],
  section5: {
    backgroundImage: RPM,
    header: s.services.operations.HIRE_EXPERTS,
    actionText: s.services.operations.HIRE_EXPERTS_INFO,
  },
};

export default props;
