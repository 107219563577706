import { Section, SeoMetadata } from '../../types';

import Molecules from './images/cellular-molecules.jpg';
import Gifts from './images/christmas-gifts.jpg';
import Clearboarding from './images/clearboarding.jpg';
import Protest from './images/climate-protest.jpg';
import Community from './images/community-volunteers.jpg';
import Plans from './images/construction-plans.jpg';
import CrisisPlan from './images/crisis-management-plan.jpg';
import Documents from './images/document-stack.jpg';
import Eggs from './images/eggs.jpg';
import EOC from './images/emergency-operations-center.jpg';
import EmergencyPlan from './images/emergency-plan.jpg';
import Plan from './images/emergency-response-plan.jpg';
import FireTeam from './images/emergency-response-team.jpg';
import Family from './images/family-planning.jpg';
import Fire from './images/fire-disaster.jpg';
import FirstAid from './images/first-aid.jpg';
import FloodTeam from './images/flood-rescue-team.jpg';
import NaturalDisaster from './images/natural-disaster.jpg';
import Meeting from './images/office-meeting.jpg';
import Risk from './images/risk-management.jpg';
import ShippingYard from './images/shipping-yard.jpg';
import Storm from './images/storm-umbrella.png';
import UrbanFlood from './images/urban-flooding.jpg';

import Hurricane from '../../assets/images/hurricane.jpg';
import Preparedness from '../../assets/images/preparedness-in-disaster-management.jpg';

import AlphaToOmicron from '../../blog-posts/alpha-to-omicron';
import BuildBusinessResilience from '../../blog-posts/build-business-resilience';
import CBRN from '../../blog-posts/cbrn-emergency-management';
import CommunityMitigationApplications from '../../blog-posts/community-mitigation-applications';
import ContingencyManagement from '../../blog-posts/contingency-management';
import CrisisManagement from '../../blog-posts/crisis-management-preparedness';
import DisasterExperts from '../../blog-posts/disaster-experts-blog';
import DisasterManagementActivities from '../../blog-posts/disaster-management-activities';
import EmergencyManagementFoundations from '../../blog-posts/emergency-management-foundations';
import EmergencyManagementProfessionals from '../../blog-posts/emergency-management-professionals';
import EnvironmentalRiskManagement from '../../blog-posts/environmental-risk-management-guide';
import ExaminingHospitalPreparedness from '../../blog-posts/examining-hospital-preparedness';
import Fema22_26 from '../../blog-posts/fema-2022-2026-emergency-management-plan';
import HandlingEvacuationNotices from '../../blog-posts/handling-evacuation-notices';
import HazardPlanningBenefits from '../../blog-posts/hazard-mitigation-planning-benefits';
import HolidayDisasterPlanning from '../../blog-posts/holiday-disaster-planning';
import LeanrResponsePlan from '../../blog-posts/learn-response-plan';
import ManagingBusiness from '../../blog-posts/managing-business-after-disaster';
import NaturalDisasterRisk from '../../blog-posts/natural-disaster-risk';
import OfficeDisasterPlan from '../../blog-posts/office-disaster-plan-setup';
import OperationManagerResponsibilities from '../../blog-posts/operation-managers-responsibilities';
import PreparednessPsychology from '../../blog-posts/psychology-of-preparedness';
import RiskReactionVsProaction from '../../blog-posts/risk-reaction-vs-proaction';
import SupplyChainDevelopments from '../../blog-posts/supply-chain-developments';
import TechAndManagement from '../../blog-posts/technology-and-emergency-management';
import ThingsToKnow from '../../blog-posts/things-to-know';
import UnderstandingResponseTeams from '../../blog-posts/understanding-state-response-teams';
import WorkplacePreparednessResponse from '../../blog-posts/workplace-preparedness-response';


export const getBlog = (blog: string) => {
  return blogs.get(blog);
};

export type BlogPost = {
  header: string;
  image: string;
  imageAlt: string;
  seo: SeoMetadata;
  snippet: string;
  tags: Array<string>;
  content?: Array<Section>;
  date?: string;
  route?: string;
};

/* The first item in this list is the featured article.
 * Articles 2-5 are also featured.
 * The remaining articles are in the Carousel.
 *
 * Previous articles don't have dates. Include a date in all future additions.
*/
const blogs: Map<string, BlogPost> = new Map([
    ['the-psychology-of-emergency-preparedness', {
        content: PreparednessPsychology,
        date: '03/18/2024',
        header: 'The Psychology of Emergency Preparedness',
        image: Eggs,
        imageAlt: 'three eggs with facial expressions drawn with black marker',
        snippet: 'Understanding Human Behavior in Crisis Situations The Psychology of Emergency Preparedness Understanding human behavior in crisis situations can be complex',
        tags: ['disaster', 'preparedness', 'psychology'],
        seo: {
          description: 'Unlock the nuances of human behavior in crisis situations with Enigma Advisory. Craft robust emergency plans based on psychology-driven preparedness.',
          title: 'The Psychology of Emergency Preparedness | Enigma Advisory',
        },
    }],
    ['contingency-management-distinctions-in-crisis', {
        content: ContingencyManagement,
        date: '02/20/2024',
        header: 'Contingency Management & Distinctions in Crisis',
        image: Clearboarding,
        imageAlt: 'person writing on a clear board',
        snippet: 'Unveiling the Distinctions Between Emerging Risks, Crisis, and Contingency Management',
        tags: ['disaster', 'plan', 'manage'],
        seo: {
          description: 'Understanding crisis, risk, & contingency management makes a difference when tragedy strikes. Learn to navigate uncertainty with Enigma Advisory!',
          title: 'Contingency Management & Distinctions in Crisis | Enigma Advisory',
        },
    }],
    ['business-resilience-framework', {
        content: BuildBusinessResilience,
        date: '01/22/2024',
        header: 'Building a Business Resilience Framework In The New Year',
        image: Storm,
        imageAlt: 'man blocking wind and rain with umbrella',
        snippet: 'Building Business Resilience Framework In The New Year Business Resilience Framework Key Strategies And How Your Business Can Stay',
        tags: ['disaster', 'business'],
        seo: {
          description: 'Good Business Resilience Framework Is Key To The Continued Success Of Your Business. Partner With Crisis Management Experts Enigma Advisory!',
          title: 'New Year Business Resilience Framework | Enigma Advisory',
        },
      },
    ],
    ['disaster-prevention-mitigation-planning-during-the-holidays', {
        content: HolidayDisasterPlanning,
        date: '12/20/2023',
        header: 'Disaster Prevention & Mitigation Planning During the Holidays',
        image: Gifts,
        imageAlt: 'christmas gifts',
        snippet: 'Disaster Prevention & Mitigation Planning During the Holidays Effective Disaster Prevention And Mitigation Planning Tips For Staying Safe This',
        tags: ['disaster', 'plan', 'mitigate'],
        seo: {
          description: 'Mitigation Planning Is Imperative This Holiday Season. At Enigma Advisory, We Want to Show You How It\'s Done! Learn More Now!',
          title: 'Mitigation Planning During the Holiday Season | Enigma Advisory',
        },
      },
    ],
    ['the-foundations-of-emergency-management', {
        content: EmergencyManagementFoundations,
        date: '11/14/2023',
        header: 'The Foundations of Emergency Management',
        image: CrisisPlan,
        imageAlt: 'crisis management plan displayed on box',
        snippet: 'What Are The Foundations Of Emergency Management',
        tags: ['disaster', 'manage'],
        seo: {
          description: 'Foundations of Emergency Management Protect People & Rebuild Communities. Learn How Protecting Your Business Could Save Lives.',
          title: 'The Foundations of Emergency Management | Enigma-Advisory',
        },
      },
    ],
    ['disaster-risk-management-reaction-vs-proaction', {
        content: RiskReactionVsProaction,
        date: '10/23/2023',
        header: 'Disaster Risk Management: Reaction VS Proaction',
        image: Risk,
        imageAlt: 'first person view of man navigating a tablet',
        snippet: 'Disaster Risk Management: Reaction VS Proaction',
        tags: ['disaster', 'manage'],
        seo: {
          description: 'Proactive and Reactive Disaster Risk Management are two approaches to handling threats to effectively maneuver both.',
          title: 'Disaster Risk Management: Reaction VS Proaction',
        },
      },
    ],
    ['a-complete-guide-to-environmental-risk-management', {
        content: EnvironmentalRiskManagement,
        date: '09/30/2023',
        header: 'A Complete Guide to Environmental Risk Management',
        image: Protest,
        imageAlt: 'climate justice protest march',
        snippet: 'A Complete Guide to Environmental Risk Management',
        tags: ['disaster', 'environmental', 'manage'],
        seo: {
          description: 'Environmental Risks Are Becoming Increasingly Urgent. Don’t Get Caught Off Guard. Learn About Environmental Risk Management From The Experts.',
          title: 'A Complete Guide to Environmental Risk Management',
        },
      },
    ],
    ['cbrn-emergency-management-preparedness-and-response-planning', {
        content: CBRN,
        date: '08/22/2023',
        header: 'CBRN Emergency Management: Preparedness & Response Planning',
        image: Molecules,
        imageAlt: 'cellular molecules',
        snippet: 'CBRN Emergency Management: Preparedness & Response Planning',
        tags: ['disaster', 'plan', 'preparedness', 'response'],
        seo: {
          description: 'CBRN emergency management is never easy but always necessary. Learn the Importance of preparedness in this inclusive guide to CBRN disasters.',
          title: 'CBRN Emergency Management: Preparedness & Response',
        },
      },
    ],
    ['examining-emergency-preparedness-in-hospitals', {
        content: ExaminingHospitalPreparedness,
        date: '08/02/2023',
        header: 'Examining Emergency Preparedness In Hospitals',
        image: EmergencyPlan,
        imageAlt: 'emergency plan chart overlaying person',
        snippet: 'Examining Emergency Preparedness In Hospitals',
        tags: ['disaster', 'preparedness'],
        seo: {
          description: 'Hospital preparedness is vital to disaster resilience. Learn about the benefits & how hospital contingency plans save lives in a crisis!',
          title: 'Examining Emergency Preparedness In Hospitals',
        },
      },
    ],
    ['how-technology-in-emergency-management-is-changing-industry', {
        content: TechAndManagement,
        date: '07/04/2023',
        header: 'How Technology In Emergency Management Is Changing Industry',
        image: EmergencyPlan,
        imageAlt: 'emergency plan chart overlaying person',
        snippet: 'How Technology In Emergency Management Is Changing Industry',
        tags: ['disaster', 'technology'],
        seo: {
          description: 'Risk is everywhere, Technology in emergency management systems from Enigma revolutionizes the mitigation process and saves lives everywhere.',
          title: 'How Technology In Emergency Management Is Changing Industry',
        },
      },
    ],
    ['crisis-management-preparedness', {
        content: CrisisManagement,
        date: '05/31/2023',
        header: 'Crisis Management Preparedness',
        image: CrisisPlan,
        imageAlt: 'crisis management plan displayed on box',
        snippet: 'Crisis Management Preparedness Why It Matters',
        tags: ['disaster', 'preparedness'],
        seo: {
          description: 'About crisis management preparedness plan, what constitutes a crisis, and what steps should be in it that can help you create a lasting plan.',
          title: 'Crisis Management Preparedness',
        },
      },
    ],
    ['learn-more-about-an-emergency-response-plan', {
        content: LeanrResponsePlan,
        date: '04/11/2023',
        header: 'Learn More About An Emergency Response Plan',
        image: Plan,
        imageAlt: 'notebook titled emergency response plan',
        snippet: 'Learn More About An Emergency Response Plan Most',
        tags: ['disaster', 'response'],
        seo: {
          description: 'Are you prepared for the unknown? Learn how Businesses have an emergency response plan in place to protect people, property, and reputation.',
          title: 'Learn More About An Emergency Response Plan',
        },
        subheader: 'Most businesses don’t have a plan. Are you prepared?',
      },
    ],
    ['understanding-natural-disasters-risk-reduction-and-management', {
        content: NaturalDisasterRisk,
        date: '03/01/2023',
        header: 'Understanding Natural Disasters Risk Reduction And Management',
        image: NaturalDisaster,
        imageAlt: 'natural disaster types and images',
        snippet: 'Understanding Natural Disasters Risk Reduction And Management What',
        tags: ['disaster', 'manage'],
        seo: {
          description: 'Natural Disaster Risk Reduction and Management are essential steps we must take for the continued success of human civilization.',
          title: 'Understanding Natural Disasters Risk Reduction And Management',
        },
      },
    ],
    ['responsibilities-of-emergency-operation-centers-managers', {
        content: OperationManagerResponsibilities,
        date: '11/17/2022',
        header: 'Responsibilities Of Emergency Operation Centers Managers',
        image: EOC,
        imageAlt: 'emergency operations center sign',
        snippet: 'Responsibilities Of  Emergency Operation Centers Managers The Beating',
        tags: ['disaster'],
        seo: {
          description: 'Learn how the relief and recovery efforts of the Emergency Operations Center and its EOC Manager\'s responsibilities help keep you safe during disasters.',
          title: 'Responsibilities Of Emergency Operation Centers Managers',
        },
      },
    ],
    ['basic-emergency-preparedness-and-response-in-the-workplace', {
        content: WorkplacePreparednessResponse,
        date: '10/07/2022',
        header: 'Basic Emergency Preparedness and Response in the Workplace',
        image: Plans,
        imageAlt: 'construction worker drawing on blueprints',
        snippet: 'Basic Emergency Preparedness and Response in the Workplace What',
        tags: ['disaster', 'preparedness', 'response'],
        seo: {
          description: 'Learn about the basics and importance of having a plan for emergency preparedness and response in the workplace and more in our latest blog post.',
          title: 'Basic Emergency Preparedness and Response in the Workplace',
        },
      },
    ],
    ['the-applications-of-a-disaster-mitigation-plan-for-the-community', {
        content: CommunityMitigationApplications,
        date: '09/09/2022',
        header: 'The Applications of a Disaster Mitigation Plan For The Community',
        image: Community,
        imageAlt: 'community volunteers side hugging',
        snippet: 'The Applications of a Disaster Mitigation Plan For The',
        tags: ['disaster', 'plan'],
        seo: {
          description: 'Learn how to Plan, Prepare, and Execute in the event of a Catastrophe with a Disaster Mitigation Plan for your Community with Enigma Advisory.',
          title: 'The Applications of a Disaster Mitigation Plan For The Community',
        },
      },
    ],
    ['understanding-state-emergency-response-teams', {
        content: UnderstandingResponseTeams,
        date: '08/11/2022',
        header: 'Understanding State Emergency Response Teams',
        image: FireTeam,
        imageAlt: 'fire team fire retardant in suits',
        snippet: 'Understanding State Emergency Response Teams',
        tags: ['disaster', 'response'],
        seo: {
          description: 'Learn About How The State Emergency Response Team Collaborates During A Disaster Situation. More On This In Enigma Advisory’s Latest Blog!',
          title: 'Understanding State Emergency Response Teams',
        },
      },
    ],
    ['activities-of-disaster-management-procedure-and-purpose', {
        content: DisasterManagementActivities,
        date: '07/21/2022',
        header: 'Activities of Disaster Management Procedure and Purpose',
        image: FirstAid,
        imageAlt: 'unpacked first aid kit',
        snippet: 'Activities of Disaster Management How to',
        tags: ['disaster'],
        seo: {
          description: 'Do you know about the activities of Disaster Management? Learn more about the relief and recovery process step by step.',
          title: 'Activities of Disaster Management Procedure and Purpose',
        },
      },
    ],
    ['benefits-of-hazard-mitigation-planning', {
        content: HazardPlanningBenefits,
        date: '06/16/2022',
        header: 'Benefits of Hazard Mitigation Planning',
        image: Preparedness,
        imageAlt: 'meeting with people around a table',
        snippet: 'Benefits of Having a Community Hazard Mitigation Plan',
        tags: ['disaster', 'mitigate'],
        seo: {
          description: 'Learn how Community Hazard Mitigation Planning is important in protecting your area and communities from threats and natural disasters.',
          title: 'Benefits of Having a Community Hazard Mitigation Plan',
        },
      },
    ],
    ['continued-developments-in-the-supply-chain', {
        content: SupplyChainDevelopments,
        date: '05/17/2022',
        header: 'Continued Developments in the Supply Chain',
        image: ShippingYard,
        imageAlt: 'airport shipping yard with plane flyover',
        snippet: 'Continued Developments in the Supply Chain',
        tags: ['disaster', 'operations'],
        seo: {
          description: 'Learn how supply chain disruptions are happening more frequently and why they impact the Disaster Management and Disaster Recovery Industry.',
          title: 'Supply Chain Developments in Disaster Recovery Management',
        },
      },
    ],
    ['managing-your-business-after-a-disaster', {
        content: ManagingBusiness,
        date: '04/25/2022',
        header: 'Managing Your Business After a Disaster',
        image: Documents,
        imageAlt: 'scattered documents',
        snippet: 'Managing Your Business After a Disaster',
        tags: ['disaster', 'manage'],
        seo: {
          description: 'Have a Business Disaster Management Plan in place so that you are always prepared. Learn what to do and how to do it when disaster strikes.',
          title: 'Managing Business after a Disaster | Business Disaster Management Plan',
        },
      },
    ],
    ['how-to-handle-an-evacuation-notice', {
        content: HandlingEvacuationNotices,
        date: '03/31/2022',
        header: 'How to Handle an Evacuation Notice',
        image: Family,
        imageAlt: 'family emergency planning on couch',
        snippet: 'How To Handle An Evacuation Notice',
        tags: ['disaster', 'plan'],
        seo: {
          description: 'How to Handle an Evacuation Notice and the Best Ways to Stay Prepared, Keep Safe, and Mitigate Your Family\'s Exposure to Danger.',
          title: 'How To Handle An Evacuation Notice | Enigma Advisory and Consulting',
        },
      },
    ],
    ['setup-office-disaster-plan', {
        content: OfficeDisasterPlan,
        date: '02/24/2022',
        header: 'How to Setup an Office Disaster Plan',
        image: Meeting,
        imageAlt: 'man presenting in office meeting',
        snippet: 'How to Setup an Office Disaster Plan',
        tags: ['disaster', 'plan'],
        seo: {
          description: 'Learn How to Setup Your Office Disaster Plan with one of Enigma Advisory\'s Expert Office Disaster Consultants Today.',
          title: 'Office Disaster Plan | Office Disaster Consultant | Business Disaster Plan',
        },
      },
    ],
    ['emergency-management-alpha-to-omicron', {
        content: AlphaToOmicron,
        date: '01/25/2022',
        header: 'From Alpha to Omicron',
        image: Molecules,
        imageAlt: 'cellular molecules',
        snippet: 'Emergency Management From',
        tags: ['disaster', 'health'],
        seo: {
          description: 'Improve your Emergency Management efforts and deepen your understanding with our blog insights From Alpha to Omicron.',
          title: 'Emergency Management - From Alpha to Omicron',
        },
      },
    ],
    ['fema-2022-2026-new-emergency-management-plan', {
        content: Fema22_26,
        date: '01/03/2022',
        header: 'FEMA: A Comprehensive Analysis of the 2022-2026 Strategy',
        image: FloodTeam,
        imageAlt: 'flood barrier with rescue team in boat',
        snippet: 'FEMA\'s 2022 - 2026 Plan Is Out (One Year',
        tags: ['disaster'],
        seo: {
          description: 'Reviewing FEMA\'s New 2022-2026 Emergency Management Plan and what this means for the Disaster Management Industry.',
          title: 'FEMA\'s New 2022-2026 Emergency Management Plan Review',
        },
      },
    ],
    ['5-things-your-emergency-management-specialist-wants-you-to-know', {
        content: ThingsToKnow,
        date: '12/01/2021',
        header: '5 Things Your Emergency Management Specialist Wants You To Know',
        image: Fire,
        imageAlt: 'woman looking at burned down residence',
        snippet: '5 Things Your Emergency Management Specialist Wants You To',
        tags: ['disaster'],
        seo: {
          description: 'Learn from our Emergency Management Specialist what to do in the event of a disaster to help keep you and your family safe and ready to act.',
          title: '5 Things Your Emergency Management Specialist Wants You To Know',
        },
      },
    ],
    ['emergency-management-professionals', {
        content: EmergencyManagementProfessionals,
        date: '11/11/2021',
        header: 'Emergency Management Professionals',
        image: UrbanFlood,
        imageAlt: 'city street flooded',
        snippet: 'Emergency Management Professionals We’re Here When You Need Us',
        tags: ['disaster'],
        seo: {
          description: 'Emergency Management Professionals here to help the communities, families, and businesses impacted by Disaster.',
          title: 'Emergency Management Professionals | Enigma Advisory',
        },
      },
    ],
    ['disaster-experts-blog', {
        content: DisasterExperts,
        date: '07/16/2021',
        header: 'Enigma Advisory – Disaster Experts',
        image: Hurricane,
        imageAlt: 'hurricane over gulf coast',
        snippet: 'Thank you for visiting Enigma Advisory! We are experts',
        tags: ['disaster'],
        seo: {
          description: 'Disaster Experts providing Real Time Industry News, Insights, and Expert Opinion in Disaster Planning, Response, Recovery, and Mitigation.',
          title: 'Disaster Experts | Disaster Planning | Disaster Mitigation',
        },
      },
    ],
  ],
);

export default blogs;
