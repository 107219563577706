import css from './four-oh-four.module.scss';

import PageNotFound from '../../components/PageNotFound';


type FourOhFourProps = {
  openContact: () => void;
};

const FourOhFour = ({ openContact = () => {} }: FourOhFourProps) => {
  return (
    <div className={ css['four-oh-four'] }>
      <PageNotFound openContact={ openContact } />
    </div>
  );
};

export default FourOhFour;
