import { useParams } from 'react-router-dom';

import EMC from './emergency-management-consulting';
import EOC from './emergency-operations-center';
import ERC from './emergency-response-consulting';
import MP from './mitigation-planning';

import PageNotFound from '../../components/PageNotFound';
import PageTemplate, { PageProps } from '../../components/Templates/EmergencyManagement';

import css from '../FourOhFour/four-oh-four.module.scss';


type ServicePages = {
  [service: string]: PageProps;
};

type ServicesProps = {
  openContact?: () => void;
};

const PROPS_MAP: ServicePages = {
  'emergency-management-consulting': EMC,
  'emergency-response-center': EOC,
  'emergency-response-consulting': ERC,
  'mitigation-planning': MP,
};

const Services = ({ openContact = () => {} }: ServicesProps) => {
  const { id } = useParams();
  const props = PROPS_MAP[id as string];

  if (!props) {
    return (
      <div className={ css['four-oh-four'] }>
        <PageNotFound openContact={ openContact } />;
      </div>
    );
  }

  return (
    <PageTemplate openContact={ openContact } props={ props } />
  );
};

export default Services;
