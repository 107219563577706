import { ImageProps, Section } from '../../../types';
import { COMPONENT_MAP } from '../../../util/constants';

import css from '../blog-sections.module.scss';


export type TitledDividedImageBodyProps = {
  images: Array<ImageProps>;
  sections: Array<Section>;
  imageSide?: 'left' | 'right';
};

const TitledDividedImageBody = ({
  images,
  imageSide = 'right',
  sections,
}: TitledDividedImageBodyProps) => {
  return (
    <div className={ css['titled-divided-image-body'] }>
      { imageSide == 'left' &&
        <div className={ css['image-container'] }>
          { images.map((image, idx) =>
              <img
                key={ `img-${idx}` }
                src={ image.image }
                alt={ image.imageAlt }
              />
            )
          }
        </div>
      }
      <div className={ css.sections }>
        { sections.map((sect, idx) => {
            const El = COMPONENT_MAP[sect.component];
            return (
              <div key={ `section-${idx}` } className={ css.section }>
                <El { ...sect.props } />
              </div>
            );
          })
        }
      </div>
      { imageSide == 'right' &&
        <div className={ css['image-container'] }>
          { images.map((image, idx) =>
              <img
                key={ `img-${idx}` }
                src={ image.image }
                alt={ image.imageAlt }
              />
            )
          }
        </div>
      }
    </div>
  );
};

export default TitledDividedImageBody;
