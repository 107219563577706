/* eslint-disable react/no-unknown-property */
type StormProps = {
  boltColor?: string;
  cloudColor?: string;
  rainColor?: string;
};

const Storm = ({ boltColor = '#5699D2', cloudColor = '#1D3F72', rainColor = '#D8EBF9' }: StormProps) => {
  return (
    <svg viewBox={ '0 0 80 95' } width={ '80px' } height={ '100px' } xmlns={ 'http://www.w3.org/2000/svg' }>
      <g transform={ 'matrix(1, 0, 0, 1, -10.049999, -7)' }>
        <g transform-origin={ '40 33.3' }>
          <path style={ { fill: cloudColor } } x={ '0' } clipRule={ 'evenodd' } fillRule={ 'evenodd' } d={ 'M73.6 32.8c.5-1.7.8-3.4.8-5.3 0-9.6-7.8-17.5-17.5-17.5-6.7 0-12.5 3.8-15.4 9.3-1.1-.3-2.3-.5-3.5-.5-6.4 0-11.6 5.2-11.6 11.6 0 .8.1 1.6.2 2.4-5.9.9-10.4 6-10.4 12.2 0 6.8 5.5 12.4 12.4 12.4h42.9c6.8 0 12.4-5.5 12.4-12.4-.1-6.2-4.5-11.3-10.3-12.2z' }></path>
          <path d={ 'M28.3 66.8l1.5-3.8c.4-1-.1-2.1-1.1-2.5-1-.4-2.1.1-2.5 1.1l-1.5 3.8c-.4 1 .1 2.1 1.1 2.5.2.1.5.1.7.1.8 0 1.5-.5 1.8-1.2z' } style={ { fill: rainColor } }></path>
          <path d={ 'M35.7 72.9l3.8-9.9c.4-1-.1-2.1-1.1-2.5-1-.4-2.1.1-2.5 1.1l-3.8 9.9c-.4 1 .1 2.1 1.1 2.5.2.1.5.1.7.1.8 0 1.6-.5 1.8-1.2z' } style={ { fill: rainColor } }></path>
          <path d={ 'M57 67.9l1.9-4.9c.4-1-.1-2.1-1.1-2.5-1-.4-2.1.1-2.5 1.1l-1.9 4.9c-.4 1 .1 2.1 1.1 2.5.2.1.5.1.7.1.8.1 1.6-.4 1.8-1.2z' } style={ { fill: rainColor } }></path>
          <path d={ 'M71.9 75.3l3.8-11.7c.3-1-.2-2.1-1.2-2.4-1-.3-2.1.2-2.4 1.2l-3.8 11.7c-.3 1 .2 2.1 1.2 2.4.2.1.4.1.6.1.8.1 1.5-.5 1.8-1.3z' } style={ { fill: rainColor } }></path>
          <animateTransform attributeName={ 'transform' } type={ 'scale' } from={ '1' } to={ '1.1' } dur={ '.75s' } fill={ 'freeze' } repeatCount={ 'indefinite' }></animateTransform>
        </g>
      </g>
      <g transform={ 'matrix(1, 0, 0, 1, -10.049999, -9)' }>
        <path d={ 'M43.2 79l6.1-16c.4-1-.1-2.1-1.1-2.5-1-.4-2.1.1-2.5 1.1l-6.1 16c-.4 1 .1 2.1 1.1 2.5.2.1.5.1.7.1.7 0 1.5-.5 1.8-1.2z' } style={ { fill: rainColor } }></path>
        <path d={ 'M61.8 81l6.5-18.1c.4-1-.2-2.1-1.2-2.5-1-.4-2.1.2-2.5 1.2l-6.5 18.1c-.4 1 .2 2.1 1.2 2.5.2.1.4.1.7.1.8 0 1.5-.5 1.8-1.3z' } style={ { fill: rainColor } }></path>
        <path d={ 'M29.7 88.8L33 80c.4-1-.1-2.1-1.1-2.5-1-.4-2.1.1-2.5 1.1L26 87.4c-.4 1 .1 2.1 1.1 2.5.2.1.5.1.7.1.9 0 1.6-.5 1.9-1.2z' } style={ { fill: rainColor } }></path>
        <animateMotion path={ 'M 0 0 L -3.147 7.083' } calcMode={ 'linear' } begin={ '0.08s' } dur={ '0.8s' } fill={ 'freeze' } repeatCount={ 'indefinite' }></animateMotion>
      </g>
      <g transform={ 'matrix(1, 0, 0, 1, -10.049999, -7)' }>
        <path d={ 'M20.1 88.8l5.7-15.4c.4-1-.1-2.1-1.1-2.5-1-.4-2.1.1-2.5 1.1l-5.7 15.4c-.4 1 .1 2.1 1.1 2.5.2.1.4.1.7.1.8 0 1.5-.5 1.8-1.2z' } style={ { fill: rainColor } }></path>
        <path d={ 'M39.4 88.8l.9-2.4c.4-1-.1-2.1-1.1-2.5-1-.4-2.1.1-2.5 1.1l-.9 2.4c-.4 1 .1 2.1 1.1 2.5.2.1.5.1.7.1.8 0 1.6-.5 1.8-1.2z' } style={ { fill: rainColor } }></path>
        <animateMotion path={ 'M 0 0 L -3.147 7.083' } calcMode={ 'linear' } begin={ '0.08s' } dur={ '1.1s' } fill={ 'freeze' } repeatCount={ 'indefinite' }></animateMotion>
      </g>
      <g transform={ 'matrix(1, 0, 0, 1, -10.049999, -7)' }>
        <path d={ 'M49 88.8l5.3-13.5c.4-1-.1-2.1-1.1-2.5-1-.4-2.1.1-2.5 1.1l-5.3 13.5c-.4 1 .1 2.1 1.1 2.5.3.1.5.1.7.1.8 0 1.5-.5 1.8-1.2z' } style={ { fill: rainColor } }></path>
        <path d={ 'M68.6 88.8l2.6-6.8c.4-1-.1-2.1-1.1-2.5-1-.4-2.1.1-2.5 1.1L65 87.4c-.4 1 .1 2.1 1.1 2.5.2.1.5.1.7.1.8 0 1.5-.5 1.8-1.2z' } style={ { fill: rainColor } }></path>
        <animateMotion path={ 'M 0 0 L -3 12' } calcMode={ 'linear' } begin={ '0s' } dur={ '0.8s' } fill={ 'freeze' } repeatCount={ 'indefinite' }></animateMotion>
      </g>
      <g style={ { animation: '1.11111s linear -1.11111s infinite normal forwards running breath-c749314a-0ffc-4dcc-b472-811a9b67ed9e', transformOrigin: '50px 50px' } } transform={ 'matrix(0.91, 0, 0, 0.91, -6.307002, -23.362972)' }>
        <path d={ 'M50.4 66.2l9.4-13.1c.5-.7.1-1.8-.5-1.8H47.7c-.8 0-1.5.6-1.8 1.6l-8.1 20c-.1.4.1.8.3.8l12.9.1-3 7.3c-.2.5-.1 1.2.2 1.7.3.4.9.4 1.2 0l12.9-15.7c.3-.3.1-1-.3-1l-11.6.1z' } style={ { fill: boltColor } }></path>
        <animateMotion path={ 'M 0 0 L -4 12' } calcMode={ 'linear' } begin={ '0s;l1mv3.end' } dur={ '0.25s' } fill={ 'freeze' } id={ 'l1mv1' }></animateMotion>
        <animateMotion path={ 'M -4 12 L -2 24' } calcMode={ 'linear' } begin={ 'l1mv1.end' } dur={ '0.25s' } fill={ 'freeze' } id={ 'l1mv2' }></animateMotion>
        <animateMotion path={ 'M -2 24 L -24 36' } calcMode={ 'linear' } begin={ 'l1mv2.end' } dur={ '0.35s' } fill={ 'freeze' } id={ 'l1mv3' }></animateMotion>
      </g>
      <g style={ { animation: '1.11111s linear -1.11111s infinite normal forwards running breath-c749314a-0ffc-4dcc-b472-811a9b67ed9e', transformOrigin: '50px 50px' } } transform={ 'matrix(0.2432, 0, 0, 0.2432, -36.354675, -15.61665)' }>
        <path d={ 'M50.4 66.2l9.4-13.1c.5-.7.1-1.8-.5-1.8H47.7c-.8 0-1.5.6-1.8 1.6l-8.1 20c-.1.4.1.8.3.8l12.9.1-3 7.3c-.2.5-.1 1.2.2 1.7.3.4.9.4 1.2 0l12.9-15.7c.3-.3.1-1-.3-1l-11.6.1z' } style={ { fill: boltColor } }></path>
        <animateMotion path={ 'M 0 0 L -4 4' } calcMode={ 'linear' } begin={ '0s;l2mv5.end' } dur={ '0.15s' } fill={ 'freeze' } id={ 'l2mv1' }></animateMotion>
        <animateMotion path={ 'M -4 4 L -2 8' } calcMode={ 'linear' } begin={ 'l2mv1.end' } dur={ '0.15s' } fill={ 'freeze' } id={ 'l2mv2' }></animateMotion>
        <animateMotion path={ 'M -2 8 L -6 12' } calcMode={ 'linear' } begin={ 'l2mv2.end' } dur={ '0.15s' } fill={ 'freeze' } id={ 'l2mv3' }></animateMotion>
        <animateMotion path={ 'M -6 12 L -4 16' } calcMode={ 'linear' } begin={ 'l2mv3.end' } dur={ '0.15s' } fill={ 'freeze' } id={ 'l2mv4' }></animateMotion>
        <animateMotion path={ 'M -4 16 L -8 20' } calcMode={ 'linear' } begin={ 'l2mv4.end' } dur={ '0.15s' } fill={ 'freeze' } id={ 'l2mv5' }></animateMotion>
      </g>
      <g style={ { animation: '1.11111s linear -1.11111s infinite normal forwards running breath-c749314a-0ffc-4dcc-b472-811a9b67ed9e', transformOrigin: '50px 50px' } } transform={ 'matrix(0.2432, 0, 0, 0.2432, 7.85498, -5.11348)' }>
        <path d={ 'M50.4 66.2l9.4-13.1c.5-.7.1-1.8-.5-1.8H47.7c-.8 0-1.5.6-1.8 1.6l-8.1 20c-.1.4.1.8.3.8l12.9.1-3 7.3c-.2.5-.1 1.2.2 1.7.3.4.9.4 1.2 0l12.9-15.7c.3-.3.1-1-.3-1l-11.6.1z' } style={ { fill: boltColor } }></path>
        <animateMotion path={ 'M 0 0 L 4 4' } calcMode={ 'linear' } begin={ '0s;l3mv5.end' } dur={ '0.15s' } fill={ 'freeze' } id={ 'l3mv1' }></animateMotion>
        <animateMotion path={ 'M 4 4 L 2 8' } calcMode={ 'linear' } begin={ 'l3mv1.end' } dur={ '0.15s' } fill={ 'freeze' } id={ 'l3mv2' }></animateMotion>
        <animateMotion path={ 'M 2 8 L 6 12' } calcMode={ 'linear' } begin={ 'l3mv2.end' } dur={ '0.15s' } fill={ 'freeze' } id={ 'l3mv3' }></animateMotion>
        <animateMotion path={ 'M 6 12 L 4 16' } calcMode={ 'linear' } begin={ 'l3mv3.end' } dur={ '0.15s' } fill={ 'freeze' } id={ 'l3mv4' }></animateMotion>
        <animateMotion path={ 'M 4 16 L 8 20' } calcMode={ 'linear' } begin={ 'l3mv4.end' } dur={ '0.15s' } fill={ 'freeze' } id={ 'l3mv5' }></animateMotion>
      </g>
    </svg>
  );
};

export default Storm;
