import { TitledDividedImageBodyProps } from '../components/BlogSections/TitledDividedImageBody';
import { TitledImageBodyProps } from '../components/BlogSections/TitledImageBody';
import { TitledSubsectionBodyProps } from '../components/BlogSections/TitledSubsectionBody';

import TeamMeeting from './images/team-meeting-analytics.jpg';

import Preparedness from '../assets/images/preparedness-rpa.jpg';
import Recovery from '../assets/images/recovery-phase-two.jpg';
import Filesystem from '../assets/images/response-filesystem.jpg';
import Mitigation from '../assets/images/response-planning-mitigation.jpg';
import Responders from '../assets/images/response-responders.jpg';

import ConstructionPlan from '../pages/Insight/images/construction-plans.jpg';
import OfficeMeeting from '../pages/Insight/images/office-meeting.jpg';


const content = [
  {
    component: 'titled-image-body',
    props: {
      body: 'During relief and recovery efforts, an EOC Manager’s responsibilities can range widely and change often. The center itself serves as the central location for coordination during a disaster relief effort, and often a manager is the one who manages the goings on within the center itself. It is the place where key personnel from responding agencies coordinate their activities and share information so that an effective and efficient response can be mounted. It brings together representatives from multiple agencies to communicate and coordinate their activities. The EOC is responsible for collecting, analyzing, and implementing changes to operations based on information around the incident so that informed decisions can be made about the response effort. EOCs are also responsible for collecting and analyzing information about the incident. This information can come from a variety of sources, including media reports, social media, eyewitness accounts, or first responders on scene. This information is then used to make decisions about the response effort.\n\nThe EOC is usually activated when an incident or event occurs that exceeds the capabilities of the initial responders on scene. It may also be activated in advance of an impending incident, such as a hurricane, to provide a central location for the coordination of pre-positioned resources. Either way, the EOC is designed to deal with the aftermath of a disaster situation, provide on the ground relief, and ensure that efforts across the disaster zone are coordinated and operational.',
      body2: 'The function of the EOC is to provide a coordinated response to an incident by integrating the activities of multiple agencies. This integration is accomplished through continuous communication among all agencies represented in the EOC, including state and local governments, relevant nongovernmental organizations, and even. Depending on the size and scope of the incident, representatives from local, state, and federal agencies may be present, as well as private sector organizations.\n\nCommunication is essential in the EOC to ensure that all agencies are working together towards a common goal. The Incident Command System (ICS) is used to facilitate this communication. ICS is a standardized system that provides a common language and organizational structure for responding to incidents. It helps to ensure that all agencies are working together efficiently and effectively.',
      header: 'Role of an Emergency Operation Center',
      image: Preparedness,
      imageAlt: 'workers collaborating in room',
      image2: Recovery,
      imageAlt2: 'employees having a meeting',
      imageSide: 'right',
      subheader: 'On-the-Ground Response Services',
      subheader2: 'EOC Functions',
    } as TitledImageBodyProps,
  },
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: Mitigation,
          imageAlt: 'employees viewing data on screen',
        },
        {
          image: TeamMeeting,
          imageAlt: 'employee explaining data on screen',
        },
      ],
      imageSide: 'left',
      sections: [
        {
          component: 'titled-subsection-body',
          props: {
            header: 'What are EOC Manager Responsibilities?',
            subsections: [
              {
                body: 'The role of an EOC manager is critical during a disaster relief and recovery effort. An EOC manager is responsible for coordinating the activities of all personnel assigned to the emergency operations center (EOC) and ensuring that the EOC is fully operational during a crisis. Think of them like a stage manager, working diligently to ensure that every department has what they need and performs adequately so that the final product is executed successfully. To be certain, the EOC manager has a large number of responsibilities that they must shoulder. Thankfully, they often have a team working with them, and with the assistance of both government and nongovernmental organizations, they can create meaningful changes in the lives of people affected by disasters.',
                header: 'How The Machine Runs',
              },
              {
                body: 'During disaster relief and recovery efforts, the EOC manager is responsible for:',
                header: 'EOC Manager Responsibilities During Disaster Relief and Recovery',
                listItems: [
                  'Coordinating the activities of all personnel assigned to the EOC',
                  'Ensuring that the EOC is fully operational during an emergency',
                  'Disseminating information to the public through the media and other channels',
                  'Working closely with other agencies and organizations to ensure a coordinated response to the disaster',
                  'Developing and updating incident action plans',
                  'Tracking resource requests and allocations',
                  'Monitoring weather conditions and forecasts',
                  'Provide situational awareness reports to decision-makers.',
                ],
              },
              {
                body: 'As you can see, this is quite a long and varied list of responsibilities. EOC managers perform a long list of roles and wear many different hats. They play a vital role in disasters by coordinating relief efforts, disseminating information, tracking resources, and providing situation awareness reports to decision-makers. An EOC manager is responsible for coordinating the activities of all personnel assigned to the emergency operations center (EOC) and ensuring that the EOC is fully operational during an emergency.',
              },
            ],
          } as TitledSubsectionBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: ConstructionPlan,
          imageAlt: 'construction worker drawing on blueprints',
        },
        {
          image: Responders,
          imageAlt: 'emergency responders in the field',
        },
      ],
      imageSide: 'right',
      sections: [
        {
          component: 'titled-subsection-body',
          props: {
            header: 'Who Works with Emergency Operations',
            subsections: [
              {
                body: 'Emergency operations are the procedures and protocols that are put into place to help protect citizens and property during times of crisis. These operations are carried out by a variety of organizations, both on the state and local government levels as well as in the private sector. Let’s take a closer look at some of the key players in emergency operations.',
                header: 'The Coordination Between Different Levels',
              },
              {
                body: 'State and local government agencies are responsible for coordinating emergency response efforts within their jurisdictions. This includes mobilizing resources, such as law enforcement, firefighting, and medical personnel, as well as managing evacuation efforts. State and local governments also provide funding for emergency operations centers (EOCs), which are used to coordinate response efforts.\n\nSome of the key players in state and local emergency operations include:',
                header: 'State and Local Government Agencies',
                listItems: [
                  'Police departments',
                  'Fire departments',
                  'Emergency medical services (EMS)',
                  'Public works departments',
                  'Hazardous materials teams',
                ],
              },
              {
                body: 'The private sector plays a critical role in emergency operations by providing essential goods and services, such as food, water, fuel, and shelter. For example, during Hurricane Katrina in 2005, UPS donated the use of truck trailers and supplies like water. The private sector also provides financial assistance to government agencies and NGOs during emergency situations. While the private sector is not always the first element thought of in a disaster situation, they can play a major part in forming solutions.\n\nOne of the easiest ways for businesses to help during a disaster is by donating supplies that are needed by relief organizations. This could include food, water, clothes, medicine, or other essential items. For example, during Hurricane Harvey in 2017, Walmart donated over $20 million in supplies, including food, water, generators, and baby formula.\n\nCompanies can also donate supplies that are specific to their industry. Volunteer opportunities like this allow businesses to use their unique skills and resources to help out in a meaningful way. For instance, construction companies can donate lumber or building materials, while furniture companies can donate beds or couches. Even something as simple as a box of diapers can be a welcome donation for families who have been displaced by a natural disaster.\n\nAnother way businesses can help is by making financial contributions to relief efforts. This could be done through corporate donations or employee giving programs. Some companies also choose to match employee donations dollar-for-dollar. Financial contributions can be used to fund supplies, personnel, transportation, or any other expenses related to relief efforts.',
                header: 'Private Sector',
              },
              {
                body: 'NGOs provide support to emergency operations in a variety of ways, including responding to calls for help from those affected by an emergency situation, providing financial assistance, and delivering supplies. NGOs also often have expertise in specific areas that can be utilized during an emergency situation, such as search and rescue or medical care.\n\nEmergency operations are complex undertakings that require the coordination of many different organizations. State and local governments play a vital role in providing the initial response to an incident, while private sector organizations often contribute essential supplies or services. By understanding the roles and responsibilities of each organization involved in emergency operations, we can be better prepared for when disaster strikes.',
                header: 'Nongovernmental Organizations',
              },
            ],
          } as TitledSubsectionBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: Filesystem,
          imageAlt: 'filesystem overlaying typing hands',
        },
      ],
      imageSide: 'left',
      sections: [
        {
          component: 'titled-subsection-body',
          props: {
            header: 'When Does an Emergency Operations Center Become Operable',
            subsections: [
              {
                body: 'An emergency operations center (EOC) serves predominantly as a designated location where personnel can coordinate and direct emergency responses during a crisis situation. An EOC may be activated in response to natural disasters, man-made disasters, or other emergencies. These are often near the disaster zones themselves, meaning that they can also provide on-the-ground support for larger organizations and entities to get a clear understanding of the situation, damages, and recovery efforts.\n\nThe decision to activate an EOC is typically made by the incident commander—the main person in charge of responding to the crisis. Once the decision has been made, the EOC team swings into action, setting up the necessary equipment and communications systems and developing response plans.  The incident commander will be the one to ensure that the needs of logistics, command, operations, and so on are met, and the EOC will go on to communicate with local and state-level organizations to acquire goods and services for those most affected.\n\nThe actual amount of time it takes to get an EOC up and running will vary depending on the specific situation. For example, if the incident commander has been monitoring a developing situation and decides to activate the EOC well in advance of the crisis, it may only take a few hours to get everything operational. However, if the incident commander is taken by surprise and must set up the EOC in the midst of a fast-moving crisis, it could take days or even weeks.',
                header: 'The General Timeline',
              },
            ],
          } as TitledSubsectionBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
  {
    component: 'titled-image-body',
    props: {
      body: 'No one wishes for disaster to strike, but many are worried about making sure they are ready for when it does. If your organization or agency is looking for ways to prepare yourselves for a disaster, Enigma Advisory can help. Our team of specialists can work with you to create plans and take necessary actions in the event of a disaster. Connect here to learn more!',
      header: 'We Hope You Never Need Us',
      image: OfficeMeeting,
      imageAlt: 'man presenting in office meeting',
      imageSide: 'right',
      subheader: 'But Just In Case You Do',
    } as TitledImageBodyProps,
  },
];

export default content;
