import classNames from 'classnames';

import css from './spear.module.scss';


type SpearProps = {
  reversed?: boolean;
  size?: 'sm' | 'md' | 'lg';
};

const Spear = ({ reversed = false, size = 'md' }: SpearProps) => {
  return (
    <div className={ classNames(
        css.divider,
        css[`${size}`],
        { [css.reversed]: reversed }
      ) }
    />
  );
};

export default Spear;
