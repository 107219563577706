import { CircleSolid } from '@carbon/icons-react';
import classNames from 'classnames';
import { ReactElement } from 'react';

import { ActionProps } from '../../../types';
import ActionParagraph from '../../ActionParagraph';
import Spear from '../../Dividers/Spear';

import css from './text-split-lists.module.scss';

import s from '../../../i18n/strings.json';


type SectionProps = {
  header: string;
  list: Array<string>;
  text: string;
  actionProps?: ActionProps;
  dividerBottom?: boolean;
  dividerTop?: boolean;
  icon?: ReactElement;
  iconSize?: 'sm' | 'md';
};

const TextSplitLists = ({
  actionProps,
  header,
  list,
  text,
  dividerBottom = false,
  dividerTop = false,
  icon = <CircleSolid />,
  iconSize = 'md',
}: SectionProps) => {
  return (
    <div className={ css.section }>
      <div className={ css['text-section'] }>
        <h2 className={ classNames(css['header-2'], css['text-bold-blue-2']) }>
          { header }
        </h2>
        { dividerTop && <Spear size={ 'sm' } /> }
        { text &&
          <p className={ css['paragraph'] }>
            { text }
          </p>
        }
        <div className={ css['split-list'] }>
          <div className={ classNames(css['list-column'], css['blue-1']) }>
            <div className={ css['list-container'] }>
              { list.slice(0, Math.ceil(list.length / 2))
                .map((itm, idx) => {
                  return (
                  <div key={ `col1-item${idx}` } className={ classNames(css['icon-label'], css[`${iconSize}`]) }>
                    { icon }
                    { itm }
                  </div>
                  );
                })
              }
            </div>
          </div>
          <div className={ classNames(css['list-column'], css['blue-3']) }>
            <div className={ css['list-container'] }>
              { list.slice((Math.ceil(list.length / 2)))
                .map((itm, idx) => {
                  return (
                  <div key={ `col2-item${idx}` } className={ classNames(css['icon-label'], css[`${iconSize}`]) }>
                    { icon }
                    { itm }
                  </div>
                  );
                })
              }
            </div>
          </div>
        </div>
        { actionProps &&
          <div className={ css['text-section'] }>
            <ActionParagraph
              action={ actionProps.action ? actionProps.action : () => {} }
              actionText={ s.common.SUBMIT_CONTACT }
              beginningText={ actionProps.text }
              endingText={ s.common.TODAY }
            />
          </div>
        }
        { dividerBottom && <Spear size={ 'sm' } /> }
      </div>
    </div>
  );
};

export default TextSplitLists;
