import classNames from 'classnames';
import React, { useState } from 'react';

import css from './action-paragraph.module.scss';


type ActionParagraphProps = {
  action: () => void;
  actionText: string;
  beginningText: string;
  endingText: string;
  color?: 'blue' | 'gray';
};

const ActionParagraph = ({
  action,
  actionText,
  beginningText,
  endingText,
  color= 'blue',
}: ActionParagraphProps) => {
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <React.Fragment>
      <p className={ css.paragraph }>
        { beginningText }
        <span
          className={ classNames(
            css.clickable,
            {
              [css[`text-bold-${color}`]]: !hovered,
              [css[`text-bold-${color}-2`]]: hovered,
            })
          }
          onClick={ () => action() }
          onMouseEnter={ () => setHovered(true) }
          onMouseLeave={ () => setHovered(false) }
        >
          { actionText }
        </span>
        { endingText }
      </p>
    </React.Fragment>
  );
};

export default ActionParagraph;
