import classNames from 'classnames';
import React from 'react';

import Spear from '../../Dividers/Spear';

import css from './testimonials.module.scss';


type Testimonial = {
  author: string;
  text: string;
};

type SectionProps = {
  header: string;
  testimonials: Array<Testimonial>;
};

const Testimonials = ({ header, testimonials }: SectionProps) => {
  return (
    <React.Fragment>
      <div className={ css.section }>
        <h2 className={ css['header-2'] }>
          { header }
        </h2>
        <div className={ css['text-section'] }>
          <Spear size={ 'sm' } />
          { testimonials.map((testimonial, idx) => {
              return (
                <React.Fragment key={ `testimonial-${idx}` }>
                  <p className={ classNames(css.paragraph, css.italicized) }>
                    { testimonial.text }
                  </p>
                  <p className={ classNames(css.paragraph, css.bold) }>
                    { testimonial.author }
                  </p>
                </React.Fragment>
              );
            })
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default Testimonials;
