import {
  Checkmark,
  Email,
  Enterprise,
  Phone,
} from '@carbon/icons-react';
import { Button } from '@carbon/react';
import classNames from 'classnames';

import { ROUTES } from '../../Routes';
import { ReactComponent as Brand } from '../../assets/images/brand-enigma-advisory.svg';
import { ReactComponent as Facebook } from '../../assets/images/icon-facebook.svg';
import { ReactComponent as LinkedIn } from '../../assets/images/icon-linkedIn.svg';
import { ReactComponent as X } from '../../assets/images/icon-x.svg';
import { ReactComponent as EmergencyLogo } from '../../assets/images/logo-emergency.svg';
import { ReactComponent as GrantsLogo } from '../../assets/images/logo-grants.svg';
import { ReactComponent as TechnologyLogo } from '../../assets/images/logo-technology.svg';
import { FACEBOOK, LINKED_IN, X as Xx } from '../../util/constants';

import css from './footer.module.scss';

import s from '../../i18n/strings.json';


type FooterProps = {
  openContact: () => void;
};

const Footer = ({ openContact }: FooterProps) => {
  return (
    <div className={ css.footer }>
      <div className={ css.brand }>
        <Brand />
      </div>
      <div className={ css.info }>
        <div className={ css.section }>
          <div className={ css['label-container'] }>
            <div className={ css['icon-label'] }>
              <Enterprise />
              { s.footer.AUSTIN_TX }
            </div>
            <div className={ css['icon-label'] }>
              <Email />
              { s.footer.ENIGMA_EMAIL }
            </div>
            <div className={ css['icon-label'] }>
              <Phone />
              { s.footer.ENIGMA_PHONE }
            </div>
          </div>
          <div className={ css['image-container'] }>
            <div className={ css.image }>
              <img src={ '/images/united-states.png' } alt={ 'united states' } />
            </div>
            <div className={ css.image }>
              <img src={ '/images/NMSDC-certified-MBE-2022.png' } alt={ 'NMSDC certified' } />
            </div>
          </div>
        </div>
        <div className={ css.section }>
          <div className={ css.text }>
            { s.footer.BLURB }
          </div>
        </div>
        <div className={ css.section }>
          <div className={ css['label-container'] }>
            <a href={ ROUTES.emergencyManagement }>
              <div className={ classNames(css['icon-label'], css.clickable) }>
                <Checkmark />
                { s.common.EMERGENCY_MANAGEMENT }
              </div>
            </a>
            <a href={ ROUTES.grantsManagement }>
              <div className={ classNames(css['icon-label'], css.clickable) }>
                <Checkmark />
                { s.common.GRANTS_MANAGEMENT }
              </div>
            </a>
            <a href={ ROUTES.technology }>
              <div className={ classNames(css['icon-label'], css.clickable) }>
                <Checkmark />
                { s.common.TECHNOLOGY_SOLUTIONS }
              </div>
            </a>
          </div>
          <div className={ css['btn-container'] }>
            <Button renderIcon={ Email } onClick={ () => openContact() }>
              { s.footer.CONTACT_US }
            </Button>
          </div>
        </div>
      </div>
      <div className={ css.divider } />
      <div className={ css.fineprint }>
        <div className={ css.social }>
          <a href={ LINKED_IN } target={ '_blank' } rel={ 'noreferrer' }>
            <LinkedIn />
          </a>
          <a href={ FACEBOOK } target={ '_blank' } rel={ 'noreferrer' }>
            <Facebook />
          </a>
          <a href={ Xx } target={ '_blank' } rel={ 'noreferrer' }>
            <X />
          </a>
        </div>
        <div className={ css.brand }>
          <EmergencyLogo />
          <GrantsLogo />
          <TechnologyLogo />
        </div>
        <div className={ css.copy }>
          &copy;
          { s.footer.COPYRIGHT }
        </div>
      </div>
    </div>
  );
};

export default Footer;
