import { ImageProps } from '../../../types';
import css from '../blog-sections.module.scss';


export type TitledBodyProps = {
  body: string;
  endImage?: ImageProps;
  externalLink: {
    body: string;
    href: string;
  };
  header?: string;
  subheader?: string;
};

const TitledBody = ({
  body,
  endImage,
  externalLink,
  header,
  subheader,
}: TitledBodyProps) => {
  return (
    <div className={ css['titled-body'] }>
      { header &&
        <h1 className={ css.header }>
          { header }
        </h1>
      }
      { subheader &&
        <h3 className={ css['header-3'] }>
          { subheader }
        </h3>
      }
      <p className={ css.paragraph }>
        { body }
      </p>
      { externalLink &&
        <a className={ css['link'] } href={ externalLink.href } target={ '_blank' } rel={ 'noreferrer' }>
          { externalLink.body }
        </a>
      }
      { endImage &&
        <div className={ css['image-wrapper'] }>
          <img src={ endImage.image } alt={ endImage.imageAlt } />
        </div>
      }
    </div>
  );
};

export default TitledBody;
