import classNames from 'classnames';
import { ReactNode } from 'react';

import css from './sector-card.module.scss';


export type SectorCardProps = {
  label: string;
  color?: string;
  icon?: ReactNode;
  image?: string;
  key?: string;
  route?: string;
};

const SectorCard = ({
  label,
  icon,
  image,
  color = 'blue',
}: SectorCardProps) => {
  return (
    <div className={ classNames(css.card, css[`${color}`]) }>
      <div className={ css['image-container'] }>
          { image && <img src={ image } alt={ `${label}` } /> }
          { !image && icon }
      </div>
      <div className={ css.divider } />
      <div className={ css.label }>
        { label }
      </div>
    </div>
  );
};

export default SectorCard;
