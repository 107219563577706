import {
  ChevronDown,
  Email,
  Phone,
  Switcher,
} from '@carbon/icons-react';
import { Theme } from '@carbon/react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import SlidingPanel from 'react-sliding-side-panel';

import SubmitForm from './api/submit-form';

import { ReactComponent as Emergency } from './assets/images/logo-emergency.svg';
import { ReactComponent as Grants } from './assets/images/logo-grants.svg';
import { ReactComponent as Technology } from './assets/images/logo-technology.svg';

import Contact, { FormPayload } from './components/Forms/Contact';
import Navigation, { ActionDropdown, Namespace, Namespaces, NavigationItem } from './components/Navigation';
import Routes, { ROUTES } from './Routes';

import 'react-sliding-side-panel/lib/index.css';
import './global.scss';

import s from './i18n/strings.json';


interface GlobalThemeProps {
  theme: 'white' | 'g10' | 'g90' | 'g100';
}

function App() {
  const [theme] = useState<GlobalThemeProps['theme']>('g100');
  const [namespace] = useState<Namespace>(NAMESPACES[getSubdirectory()]);
  const [sideOpen, setSideOpen] = useState<boolean>(false);
  const [windowSize, setWindowWidth] = useState<number>(getWindowWidth());
  const [isLoading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<string>('');

  const actionDropdowns: Array<ActionDropdown> = [
    {
      icon: <Switcher />,
      menuItems: [
        {
          label: s.common.EMERGENCY_MANAGEMENT,
          icon: <Emergency />,
          route: ROUTES.emergencyManagement,
          tooltip: s.common.EMERGENCY_MANAGEMENT,
        },
        {
          label: s.common.GRANTS_MANAGEMENT,
          icon: <Grants />,
          route: ROUTES.grantsManagement,
          tooltip: s.common.GRANTS_MANAGEMENT,
        },
        {
          label: s.common.TECHNOLOGY,
          icon: <Technology />,
          route: ROUTES.technology,
          tooltip: s.common.TECHNOLOGY,
        },
      ],
      tooltip: s.common.BRANCHES,
    },
  ];

  const actionItems: Array<NavigationItem> = [
    {
      label: s.common.CONTACT,
      onClick: () => setSideOpen(true),
      icon: <Email />,
      mobileOnly: true,
      tooltip: s.common.CONTACT_US,
    },
    {
      label: s.common.CALL,
      onClick: () => window.open(`tel:8889482566`),
      icon: <Phone />,
      tooltip: s.common.CALL_US,
    },
  ];

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(getWindowWidth()));
    return () => window.removeEventListener('resize', () => setWindowWidth(getWindowWidth()));
  }, []);

  const onFormSubmit = (payload: FormPayload) => {
    setLoading(true);
    SubmitForm(payload).then(resp => {
      if (resp.statusCode != 200) {
        setNotification(resp.error);
      } else {
        setSideOpen(false);
      }
    }).finally(() => setLoading(false));
  };

  return (
    <div className="App">
      <Helmet >
        <title>{ s.common.ENIGMA_ADVISORY }</title>
        <meta name={ s.common.DESCRIPTION } content={ s.common.LEARN_ABOUT_ENIGRMA } />
      </Helmet>
      <Theme theme={ theme }>
        <div className={ 'page' }>
          <div className={ 'navigation' }>
            <BrowserRouter>
              <Navigation
                actionDropdowns={ actionDropdowns }
                actionItems={ actionItems }
                namespace={ namespace }
                selected={ getNavigationDirectory() }
                subdirectory={ getNavigationSubdirectory() }
              />
            </BrowserRouter>
          </div>
          <div className={ 'content' }>
            <Routes contactOpen={ () => setSideOpen(true) } windowWidth={ windowSize } />
            <SlidingPanel
              type={ 'right' }
              isOpen={ sideOpen }
              size={ windowSize > 800 ? 85 : 100 }
            >
              <Contact
                isLoading={ isLoading }
                onCancel={ () => setSideOpen(false) }
                onSubmit={ (payload) => onFormSubmit(payload) }
                notification={ notification }
                onNotificationClose={ () => setNotification('') }
              />
            </SlidingPanel>
          </div>
        </div>
      </Theme>
    </div>
  );
}

export const BASE_NAMESPACE = {
  label: '',
  linkDropdowns: [],
  links: [],
};

const EMERGENCY_MANAGEMENT_NAMESPACE = {
  href: '/emergency-management',
  label: s.common.EMERGENCY_MANAGEMENT,
  linkDropdowns: [
    {
      icon: <ChevronDown />,
      menuItems: [
        {
          label: s.common.EMERGENCY_PREPAREDNESS_CONSULTING,
          route: ROUTES.services.paths.preparedness,
        },
        {
          label: s.common.EMERGENCY_RESPONSE_CONSULTING,
          route: ROUTES.services.paths.response,
        },
        {
          label: s.common.EMERGENCY_OPERATIONS_CENTER,
          route: ROUTES.services.paths.recovery,
        },
        {
          label: s.common.MITIGATION_PLANNING,
          route: ROUTES.services.paths.mitigation,
        },
      ],
      menuLabel: s.common.SERVICES,
    },
    {
      icon: <ChevronDown />,
      menuItems: [
        {
          label: s.common.PREPAREDNESS,
          route: ROUTES.process.paths.preparedness,
        },
        {
          label: s.common.RESPONSE,
          route: ROUTES.process.paths.response,
        },
        {
          label: s.common.RECOVERY,
          route: ROUTES.process.paths.recovery,
        },
        {
          label: s.common.ONGOING_SUPPORT,
          route: ROUTES.process.paths.mitigation,
        },
      ],
      menuLabel: s.common.PROCESS,
    },
  ],
  links: [
    {
      label: s.common.TESTIMONIALS,
      route: ROUTES.testimonials,
    },
    {
      label: s.common.INSIGHTS,
      route: ROUTES.insight,
    },
    {
      label: s.common.ABOUT_US,
      route: ROUTES.aboutEM,
    },
  ],
};

const NAMESPACES: Namespaces = {
  '/': BASE_NAMESPACE,
  '/emergency-management': EMERGENCY_MANAGEMENT_NAMESPACE,
  '/grants-management': {
    href: '/grants-management',
    label: 'Grants Management',
    links: [],
    linkDropdowns: [],
  },
  '/technology': {
    href: '/technology',
    label: s.common.TECHNOLOGY,
    links: [],
    linkDropdowns: [],
      /* Disabling until we have content for individual pages
      {
        icon: <ChevronDown />,
        menuItems: [
          {
            label: s.common.ADVISORY,
            route: ROUTES.solutions.paths.advisory,
          },
          {
            label: s.technology.CLOUD,
            route: ROUTES.solutions.paths.cloud,
          },
          {
            label: s.technology.DEVOPS,
            route: ROUTES.solutions.paths.devops,
          },
          {
            label: s.common.SOFTWARE,
            route: ROUTES.solutions.paths.software,
          },
          {
            label: s.technology.WEB_DEVELOPMENT,
            route: ROUTES.solutions.paths.web,
          },
        ],
        menuLabel: s.common.TECHNOLOGY_SOLUTIONS,
      },
    ], */
  },
  '/404': BASE_NAMESPACE,

  /* These are used for redirects */
  '/about-enigma-advisory': EMERGENCY_MANAGEMENT_NAMESPACE,
  '/enigma-advisory-blog': EMERGENCY_MANAGEMENT_NAMESPACE,
  '/testimonials': EMERGENCY_MANAGEMENT_NAMESPACE,
};

const getSubdirectory = () => {
  const tokens: Array<string> = window.location.pathname.split('/').filter(t => t);
  if (tokens.length > 0) {
    return `/${tokens[0]}`;
  }
  return '/';
};

const getNavigationDirectory = () => {
  const tokens: Array<string> = window.location.pathname.split('/').filter(t => t);
  if (tokens.length > 1) {
    return `${tokens[1].charAt(0).toUpperCase()}${tokens[1].slice(1)}`;
  }
  if (tokens.length > 0) {
    if (['about-enigma-advisory', 'testimonials'].includes(tokens[0])) {
      return `${tokens[0].charAt(0).toUpperCase()}${tokens[0].slice(1)}`;
    }
    if (tokens[0] === 'enigma-advisory-blog') {
      return 'Insight';
    }
    return getSubdirectory();
  }
  return '';
};

const getNavigationSubdirectory = () => {
  const tokens: Array<string> = window.location.pathname.split('/').filter(t => t);
  if (tokens.length > 2) {
    return tokens[2].toLowerCase().replaceAll('-', ' ');
  }
  return '';
};

const getWindowWidth = () => {
  const { innerWidth: width } = window;
  return width;
};

export default App;
