import React from 'react';

import css from './text-gradient-header.module.scss';


type SectionProps = {
  header: string;
  text: string;
};

const TextGradientHeader = ({ header, text }: SectionProps) => {
  return (
    <React.Fragment>
      <div className={ css['blue-gradient'] }>
        <h2 className={ css['header-2'] }>
          { header }
        </h2>
      </div>
      <div className={ css.section }>
        <div className={ css['text-section'] }>
          <p className={ css.paragraph }>
            { text }
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TextGradientHeader;
