import { TitledBodyProps } from '../components/BlogSections/TitledBody';
import { TitledImageBodyProps } from '../components/BlogSections/TitledImageBody';
import { TitledListProps } from '../components/BlogSections/TitledList';
import { TitledSubsectionBodyProps } from '../components/BlogSections/TitledSubsectionBody';

import ResponseTeam from './images/emergency-response-team.jpg';
import PreparednessChecklist from './images/preparedness-checklist.jpg';


const content = [
  {
    component: 'titled-image-body',
    props: {
      body: 'Having processes in place for CBRN emergency management can often get overlooked because they happen less frequently. However, these emergencies pose some of the gravest and most formidable disasters. CBRN disasters involve hazardous materials with the potential to inflict widespread destruction and severe harm to both humans and the environment. It is, therefore, imperative to comprehend the nature of CBRN emergencies, their significance in terms of management and mitigation, and the necessary preparations.\n\nThis comprehensive guide explores the essence of CBRN emergencies, the importance of their proper management, the key players involved, and the most destructive CBRN agents.',
      header: 'Get Ahead of the Curve & Protect What You Love',
      image: PreparednessChecklist,
      imageAlt: 'disaster preparedness checklist',
      imageSide: 'right',
      subheader: 'How It Affects Disaster Recovery',
    } as TitledImageBodyProps,
  },
  {
    component: 'titled-body',
    props: {
      body: 'CBRN emergencies refer to an emergency situation that involves the release of hazardous materials into the environment. CBRN is an acronym and stands for Chemical, Biological, Radiological, and Nuclear. These emergencies can be caused by human error, accidents, intentional acts, or a combination of the three. The key linking factor between all accidents in this category is their base nature: human intervention and technological breakdown. With the exception of certain bacteria, viruses, and other naturally developing biological pathogens, each of these segments is based on the developments that humans have made and what happens when disasters occur as a result of them. Think of incidents like Chernobyl, where the breakdown of a nuclear facility resulted in devastation to the surrounding areas and towns. Even today, we are still feeling the effects of these events. Because of the scale, basis, and similarity in terms of response, these types of disasters have been categorized together, as these types of emergencies pose a significant threat to public health and safety and must be managed promptly and effectively.',
      header: 'What is a CBRN Emergency?',
    } as TitledBodyProps,
  },
  {
    component: 'titled-subsection-body',
    props: {
      body: 'As we said before, there are four large subsections of these disasters. We think it is important that we identify each of them here so that you can get a basis for what we are discussing in the next sections:',
      header: 'Subtypes',
      subsections: [
        {
          body: 'These are disasters that involve the release of chemical agents into the environment. Effective and efficient chemical emergency management is vital because chemical agents can be extremely harmful and, when inhaled or ingested, can cause serious harm to people and animals in the area. Examples of these types of disasters include pesticide spills, industrial accidents involving hazardous materials, or intentional releases of poisonous gas.',
          header: 'Chemical',
        },
        {
          body: 'These are emergencies that involve either naturally occurring or intentionally released biological agents. Biological disaster management covers emergencies that involve bacteria, viruses, fungi, and other organisms that can cause severe harm to people and animals in the area. Examples of these types of disasters include natural disease outbreaks like pandemics or intentional releases of biological pathogens like anthrax.',
          header: 'Biological',
        },
        {
          body: 'Radiological emergency management covers disasters marked by the release of radioactive material into the environment. Radiological agents can be extremely harmful to people and animals in the area, so it is important to manage these disasters quickly and effectively. Examples of these types of disasters include accidental or intentional releases of radioactive material.',
          header: 'Radiological',
        },
        {
          body: 'Nuclear: These are emergencies that involve a nuclear device either exploding or being deliberately released in an area. Nuclear devices have the potential to cause mass destruction and severe harm to people and animals in the area. Examples of these types of disasters include accidental or intentional detonations of an atomic bomb.\n\nAs you can see, these four sections are intentionally broad enough to cover a wide variety of human-created disasters. That allows those responsible to best determine the appropriate response and ensure that the public is safe.',
          header: 'Nuclear',
        },
      ],
    } as TitledSubsectionBodyProps,
  },
  {
    component: 'titled-body',
    props: {
      body: 'Effective and efficient management of CBRN emergencies is of utmost importance due to their severe threat to public health and safety. These emergencies are characterized by the rapid spread and devastating impact of the agents involved, posing significant challenges to emergency responders and authorities.\n\nGiven the complex nature of CBRN emergencies, it is imperative to handle these situations efficiently and effectively. Timely response, coordination among various agencies, and proper utilization of resources are crucial in minimizing the impact on human health, preventing further dispersion of hazardous agents, and facilitating a swift recovery. The agents involved in CBRN emergencies can cause extensive harm to individuals, resulting in numerous deaths, injuries, and long-term health consequences. Chemical agents can lead to respiratory distress, burns, and organ damage. Biological agents can cause infectious diseases with severe symptoms and the potential for widespread transmission. Radiological agents can result in radiation sickness and long-term effects such as cancer. Nuclear events can cause catastrophic destruction and long-lasting environmental contamination.\n\nRecognizing the severity and multifaceted challenges posed by CBRN emergencies, it is essential to prioritize their effective management. By doing so, we can safeguard public health, protect the environment, and mitigate the long-lasting impacts on society as a whole. By implementing comprehensive emergency preparedness plans, training personnel, and having robust communication systems in place, the ability to effectively manage CBRN emergencies can be enhanced. This includes early detection, accurate risk assessment, appropriate protective measures, and the provision of necessary medical care and support to affected individuals.',
      header: 'Why CBRN Emergency Management is So Important',
    } as TitledBodyProps,
  },
  {
    component: 'titled-subsection-body',
    props: {
      body: 'In terms of CBRN emergency management, as in most emergency situations, the first responders will be the local emergency services such as police, fire department, and ambulance services. If there is a facility involved, such as a nuclear or chemical plant, typically, they will also employ protocols that involve connecting with local authorities and relevant national leadership groups. The EPA has an entire task force dedicated to the management and response efforts, dubbed The Consequence Management Advisory Division (CMAD).\n\nCMAD is responsible for advising, guiding, and supporting local emergency response organizations in managing a CBRN incident. Their primary goal is to minimize the risk of harm to people, property, and the environment from hazardous materials released into the atmosphere. This includes analyzing current conditions at the location of the incident as well as potential long-term impacts. They also provide technical assistance in the form of access to resources, expert personnel, and equipment.',
      header: 'Who Takes the Lead in CBRN Disaster Management?',
      subsections: [
        {
          body: 'On a national level, the Department of Homeland Security (DHS) provides leadership on CBRN response efforts. DHS works closely with other government agencies, such as the CDC, FEMA, and state/local governments, to formulate and implement plans of response. In addition, DHS also maintains the National Incident Management System (NIMS) that provides guidance on emergency response operations. NIMS is used to coordinate and manage all levels of government efforts in responding to CBRN incidents.\n\nUltimately, it is up to the individual states to develop their own system for managing CBRN emergencies as they see fit. Each state has law enforcement, health, and emergency response departments. They are responsible for developing strategies to respond to CBRN incidents in their region and providing local responders support, training, and resources.\n\nCBRN emergencies can be difficult to manage due to the agents’ complexity and the potential impacts on both human life and the environment. Maintaining effective emergency preparedness plans, training personnel, and having robust communication systems in place can enhance the ability to effectively manage CBRN emergencies. All levels of government and relevant agencies need to work together to minimize the risk posed by these disasters and ensure appropriate response measures are taken. With timely response and proper management of such incidents, even the most catastrophic events can be managed.',
          header: 'Taking Things One Step Further…',
        },
      ],
    } as TitledSubsectionBodyProps,
  },
  {
    component: 'titled-list',
    props: {
      body: 'In today’s world, being prepared for any kind of emergency is more crucial than ever. One specific scenario that requires readiness is a CBRN event. Without proper preparation, a CBRN situation can have devastating consequences. To ensure safety and security, being well informed and knowledgeable is important. As individuals, do what you can to understand the potential hazards and risks in your area and know the appropriate steps to take in case of an emergency. This includes:',
      body2: 'Taking these preparatory measures now can make a significant difference in safeguarding yourself and your loved ones during a CBRN event. Additionally, staying informed and remaining aware of your area’s latest news and developments is crucial. Local, state, and national authorities will provide timely updates as more information becomes available. Following their instructions can help protect you from potential hazards during an emergency.',
      header: 'How To Be Prepared For Any CBRN Disaster',
      items: [
        {
          value: 'having a communication plan with your family or coworkers',
        },
        {
          value: 'knowing how to effectively use personal protective equipment',
        },
        {
          value: 'keeping a supply of essential provisions like water and non-perishable food items',
        },
      ],
    } as TitledListProps,
  },
  {
    component: 'titled-image-body',
    props: {
      body: 'CBRN emergencies can be catastrophic, and their impact can extend beyond the affected area. Taking preventive measures and preparing for such disasters to minimize their impact is vital. We hope this guide has helped shed light on CBRN emergencies, how to manage them, and why they are important. Remember, being prepared can save lives.',
      header: 'How Enigma Advisory Can Help With CBRN Emergency Preparedness',
      image: ResponseTeam,
      imageAlt: 'emergency response team notebook',
      imageSide: 'right',
    } as TitledImageBodyProps,
  },
];

export default content;
