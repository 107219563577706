import classNames from 'classnames';

import css from '../blog-sections.module.scss';


type ListItem = {
  value: string;
  label?: string;
};

export type TitledListProps = {
  body: string;
  header: string;
  items: Array<ListItem>;
  body2?: string;
  isList?: boolean;
  ordered?: boolean;
  subheader?: string;
};

const TitledList = ({
  body,
  body2,
  header,
  items,
  isList = true,
  ordered = false,
  subheader,
}: TitledListProps) => {
  return (
    <div className={ css['titled-list'] }>
      <h1 className={ css.header }>
        { header }
      </h1>
      { subheader && <h4 className={ css['header-4'] }>{ subheader }</h4> }
      <p className={ css.paragraph }>
        { body }
      </p>
      { ordered
        ? <ol className={ classNames(
              css.list,
              css.paragraph,
              {
                [css['no-list']]: isList,
                [css.ordered]: isList,
              })
            }
          >
            { items.map((item, idx) => {
                return (
                  <div key={ `item-${idx}` } className={ css['list-item'] }>
                    <li>
                      { item.label && <span style={ { fontWeight: 700 } }>{ `${item.label} ` }</span> }
                      { item.value}
                    </li>
                  </div>
                );
              })
            }
          </ol>
        : <ul className={ classNames(
              css.list,
              css.paragraph,
              {
                [css['no-list']]: isList,
                [css.unordered]: isList,
              })
            }
          >
            { items.map((item, idx) => {
                return (
                  <div key={ `item-${idx}` } className={ css['list-item'] }>
                    <li>
                      { item.label && <span style={ { fontWeight: 700 } }>{ `${item.label} ` }</span> }
                      { item.value}
                    </li>
                  </div>
                );
              })
            }
          </ul>
      }
      { body2 &&
        <p className={ css.paragraph }>
          { body }
        </p>
      }
    </div>
  );
};

export default TitledList;
