import { CircleDash } from '@carbon/icons-react';
import { PageProps } from '../../components/Templates/EmergencyManagement';

import Imp from '../../assets/images/emergency-mitigation-implementation.jpg';
import DPS from '../../assets/images/emergency-preparedness-strategy.jpg';
import OVA from '../../assets/images/operations-vulnerability-assessment.jpg';
import PM from '../../assets/images/preparedness-mitigation.jpg';
import RPM from '../../assets/images/response-planning-mitigation.jpg';


import s from '../../i18n/strings.json';

import css from '../../components/ImageBlurb/image-blurb.module.scss';


const child = () => {
  return (
    <div className={ css['blurb-addon'] }>
      <div className={ css['blurb-bold'] }>
        { s.process.mitigation.INCLUDES }
      </div>
      <div className={ css['list-container'] }>
        <div className={ css['list-column'] }>
          { [s.process.mitigation.INCLUDES_1, s.process.mitigation.INCLUDES_2].map(label => {
              return (
                <div key={ `${label}-label` } className={ css['icon-label'] }>
                  <CircleDash />
                  { label }
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

const props: PageProps = {
  seo: {
    description: s.process.mitigation.META_DESCRIPTION,
    title: s.process.mitigation.TITLE,
  },
  header: {
    backgroundImage: PM,
    header: s.common.DISASTER_PREPAREDNESS,
    subheader: s.common.ENIGMA_ADVISORY,
  },
  section1: {
    header: s.process.mitigation.SUPPORT_AND_MITIGATION,
    text: s.process.mitigation.SUPPORT_AND_MITIGATION_INFO,
  },
  imageBlurbs: [
    {
      blurb: s.process.mitigation.IMAGE_BLURB1,
      header: s.process.mitigation.IMAGE_BLURB1_HEADER,
      image: OVA,
      imageAlt: 'woman analyzing vulnerability report',
      color: '#327ec7',
      subheader: s.process.mitigation.IMAGE_BLURB1_SUBHEADER,
      subheaderColor: '#3bafbf',
    },
    {
      blurb: s.process.mitigation.IMAGE_BLURB2,
      header: s.process.mitigation.IMAGE_BLURB2_HEADER,
      image: DPS,
      imageAlt: 'hazard mitigation strategy',
      color: '#327ec7',
      children: [child()],
      subheader: s.process.mitigation.IMAGE_BLURB2_SUBHEADER,
      subheaderColor: '#3bafbf',
    },
    {
      blurb: s.process.mitigation.IMAGE_BLURB3,
      header: s.process.mitigation.IMAGE_BLURB3_HEADER,
      image: Imp,
      imageAlt: 'implementation',
      color: '#327ec7',
      subheader: s.process.mitigation.IMAGE_BLURB3_SUBHEADER,
      subheaderColor: '#3bafbf',
    },
  ],
  section5: {
    backgroundImage: RPM,
    header: s.process.mitigation.ONGOING,
    actionText: s.process.mitigation.ONGOING_INFO,
  },
};

export default props;
