import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';

import { ChevronDown } from '@carbon/icons-react';

import css from './dropdown-menu.module.scss';


type MenuItem = {
  label: string;
  icon?: ReactElement;
  onClick?: () => void;
  route?: string;
  tooltip?: string;
};

type MenuItemProps = {
  label: string;
  menuItems: Array<MenuItem>;
  icon?: ReactElement;
  selected?: string;
};

const DropdownMenu = ({
  label,
  menuItems,
  icon,
  selected = '',
}: MenuItemProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div className={ css['menu'] } onClick={ () => setExpanded(!expanded) }>
      <div className={ classNames(
            css['menu-label'],
            { [css.selected]: menuItems.map(item => item.label.toLocaleLowerCase()).includes(selected) },
          )
        }
      >
        <div className={ css.label }>
          { label }
        </div>
        { icon || <ChevronDown /> }
      </div>
      <div className={ css.divider } />
      { expanded &&
        <div className={ css.dropdown }>
          { menuItems.map(item => {
              return (
                <React.Fragment key={ `${item.label}-menu-item` }>
                  <a href={ item.route }
                    className={ classNames(
                        css['menu-item'],
                        {
                          [css.selected]: item.label.toLocaleLowerCase() === selected,
                        }
                      )
                    }
                  >
                    { item.label }
                  </a>
                </React.Fragment>
              );
            })
          }
        </div>
      }
    </div>
  );
};

export default DropdownMenu;
