import { ArrowRight } from '@carbon/icons-react';
import { Button } from '@carbon/react';

import TagGroup from '../../TagGroup';

import css from './blog-card.module.scss';

import s from '../../../i18n/strings.json';

import classNames from 'classnames';

type BlogCardProps = {
  header: string;
  image: string;
  imageAlt: string;
  snippet: string;
  anchorLink?: string;
  backgroundColor?: string;
  minimized?: boolean;
  onClick?: () => void;
  tags?: Array<string>;
};

const BlogCard = ({
  anchorLink,
  backgroundColor = '#000',
  header,
  image,
  imageAlt,
  minimized = false,
  onClick = () => {},
  snippet,
  tags = [],
}: BlogCardProps) => {
  return (
    <div className={ css['blog-card'] } style={ { backgroundColor } }>
      { anchorLink && <a href={ anchorLink } /> }
      <div className={ classNames(css.image, { [css.minimized]: minimized }) }>
        <img src={ image } alt={ imageAlt } />
      </div>
      <div className={ classNames(css.info, { [css.minimized]: minimized }) }>
        <div className={ classNames(css.tags, { [css.minimized]: minimized }) }>
          <TagGroup tags={ tags } size={ minimized ? 'sm' : 'md' } color='cyan' />
        </div>
        <div className={ classNames(css.header, { [css.minimized]: minimized }) }>
          { header }
        </div>
        <div className={ classNames(css.snippet, { [css.minimized]: minimized }) }>
          { `${snippet} [...]` }
        </div>
        <div className={ classNames(css.action, { [css.minimized]: minimized }) }>
          <Button renderIcon={ ArrowRight } onClick={ () => onClick() }>
            { s.common.READ_MORE }
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
