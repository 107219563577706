import { TitledImageBodyProps } from '../components/BlogSections/TitledImageBody';

import Construction from './images/construction-site.jpg';
import Logistics from './images/warehouse-logistics.jpg';

import Mitigation from '../assets/images/preparedness-mitigation-management.jpg';
import Warehouse from '../assets/images/response-supply-warehouse.jpg';

import Molecules from '../pages/Insight/images/cellular-molecules.jpg';
import Meeting from '../pages/Insight/images/office-meeting.jpg';


const content = [
  {
    component: 'titled-image-body',
    props: {
      body: 'Covid has exacerbated and exposed major flaws within the supply chain systems. At first, we assumed that the troubles would last as long as the pandemic gripped the country. Yet, in the last year, it has become clear that we will not be returning to the world that ruled before the pandemic. The world has changed, and the supply chain is slowly moving to meet this new reality. In the meantime, however, we must find ways to meet the needs of those under our care.\n\nUnfortunately, as the greater US supply chain has faltered, emergency managers’ systems for obtaining and distributing essential supplies have too. We have all had the unfortunate experience of renegotiating time frames and money allocations and are looking for ways to avoid such conflict in the future. It’s our responsibility to set the expectations about time and budget for these our clients to set the tone and expectations for their constituents. In this article, we will discuss a few ways that can be achieved.',
      header: 'What It Means for Disaster Recovery Management',
      image: Molecules,
      imageAlt: 'cellular molecules',
      imageSide: 'right',
    } as TitledImageBodyProps,
  },
  {
    component: 'titled-image-body',
    props: {
      body: 'As you may have noticed, many of the products we use every day are becoming more expensive right now. It’s not just the consumer market, either. Construction and manufacturing industries also are being affected by disruptions at the distribution and shipment levels. Even construction equipment is rising in price and becoming more difficult to acquire. Combine that with a shortage of skilled labor in the construction workforce, and you may find yourself struggling to meet deadlines and budget constraints.\n\nDon’t let yourself be caught off guard by these changes. While you might not be handling the procurement of supplies, it is still important that you keep an eye on the process. Stay on top of any major shortages or price hikes that could affect the budget allocation requirements during the reconstruction phase. Stay on top of developing situations by keeping up to date with relevant news sources and industry-specific sites.',
      header: 'Set Proper Expectations',
      image: Warehouse,
      imageAlt: 'workers wheeling crates in warehouse',
    } as TitledImageBodyProps,
  },
  {
    component: 'titled-image-body',
    props: {
      body: 'Setting expectations early about timelines for disaster recovery and reconstruction is incredibly important. The last thing you want to have to do is come back to the client or agency that employed you and have to explain any necessary extensions or alterations to the original plan.\n\nTo avoid such conflict, it’s best to set the expectations upfront regarding degradations in relevant supply chains. Explain to your client what the current situation is and what that is likely to mean for recovery operations. While it is unlikely that they will not at least be familiar with the supply chain situation, they may not know or understand what that means for the emergency management industry and relevant recovery systems. As professionals, it’s our job to help them understand. That way, they can make informed decisions for relevant parties, such as displaced constituents or employees.',
      header: 'Educate Clients on Current Supply Chain Disruptions',
      image: Construction,
      imageAlt: 'Foreman overseeing construction site',
      imageSide: 'right',
    } as TitledImageBodyProps,
  },
  {
    component: 'titled-image-body',
    props: {
      body: 'Even at the local level, known disruptions can lead to improper distribution of goods. Even if goods can get to a disaster area, finding proper storage and transportation methods can prove a problem. Looking specifically at post-hurricane supply chain disruptions,  there seems to be a lack of necessary labor and utilities, causing a bottleneck effect. This makes it more difficult to get supplies where they need to go. Combine this with a situation where communication lines have decayed and infrastructure has been battered, and resolving issues on the ground can become quite difficult.\n\nIt’s important, then, that we as professionals stay on top of the distribution of goods and materials. This may need some creativity to find methods of keeping communication and goods running smoothly. The job is to protect and help people and communities get back on their feet. Ensuring that everyone can get what they need to eat and live is imperative to that end.',
      header: 'Keep An Eye on Distribution Systems',
      image: Logistics,
      imageAlt: 'Logistics tablet in warehouse setting',
      imageSide: 'left',
    } as TitledImageBodyProps,
  },
  {
    component: 'titled-image-body',
    props: {
      body: 'The most important part of this equation is confirming with suppliers and distributors about the timing and methods of distribution. By keeping in consistent contact with suppliers, you can become aware of situations as they develop. The world around us is changing right now, so the best thing to do is stay in the loop so that you won’t be caught off-guard when complications arise.',
      header: 'Communicate with Current and Potential Suppliers',
      image: Meeting,
      imageAlt: 'man presenting in office meeting',
      imageSide: 'right',
    } as TitledImageBodyProps,
  },
  {
    component: 'titled-image-body',
    props: {
      body: 'The disaster management and recovery industry is changing, and it’s important to stay up-to-date on the latest news and updates. But, as always, we must learn from past events to be better prepared for future ones. If you are interested in learning more about disaster management or recovery, please don’t hesitate to connect with our experts here at Enigma Advisory. We can help you navigate these changes and prepare your business for potential disasters.',
      header: 'Conclusion',
      image: Mitigation,
      imageAlt: 'employees viewing data on screen',
      imageSide: 'left',
    } as TitledImageBodyProps,
  },
];

export default content;
