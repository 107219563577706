import classNames from 'classnames';

import css from '../blog-sections.module.scss';


export type TitledImageBodyProps = {
  body: string;
  header: string;
  image: string;
  imageAlt: string;
  body2?: string,
  closing?: string,
  headerColor?: string;
  image2?: string;
  imageAlt2?: string;
  imageSide?: 'left' | 'right';
  listItems?: Array<string>;
  listItems2?: Array<string>;
  subheader?: string;
  subheaderColor?: string;
  subheader2?: string;
};

const TitledImageBody = ({
  body,
  body2,
  closing,
  header,
  image,
  imageAlt,
  headerColor,
  image2,
  imageAlt2,
  imageSide = 'left',
  listItems = [],
  listItems2 = [],
  subheader,
  subheaderColor,
  subheader2,
}: TitledImageBodyProps) => {
  return (
    <div className={ css['titled-image-body'] }>
      { header && <h1 className={ css.header } style={ headerColor ? { color: headerColor } : {} }>
          { header }
        </h1>
      }
      { subheader && !subheader2 &&
        <h3 className={ css['header-3'] } style={ subheaderColor ? { color: subheaderColor } : {} }>
          { subheader }
        </h3>
      }
      <div className={ classNames(
          css['wrapped-body'],
          {
            [css['left-image']]: imageSide == 'left',
            [css['right-image']]: imageSide == 'right',
          })
        }
      >
        { image2
          ? <>
              <img src={ image } alt={ imageAlt } style={ { clear: 'both' } } />
              <img src={ image2 } alt={ imageAlt2 } style={ { clear: 'both' } } />
            </>
          : <img src={ image } alt={ imageAlt } />
        }
        { subheader && subheader2 &&
          <h3 className={ css['header-3'] } style={ subheaderColor ? { color: subheaderColor } : {} }>
            { subheader }
          </h3>
        }
        <p className={ css.paragraph }>
          { body }
        </p>
        { listItems.length > 0 &&
          <div className={ css['titled-list'] }>
            <ul className={ classNames(css.list, css.unordered) }>
              { listItems.map((item, idx) => {
                  return (
                    <div key={ `item-${idx}` } className={ css['list-item'] }>
                      <li>{ item }</li>
                    </div>
                  );
                })
              }
            </ul>
          </div>
        }
        { subheader2 &&
          <h3
            className={ css['header-3'] }
            style={ subheaderColor
              ? { color: subheaderColor, paddingTop: '2rem' }
              : { paddingTop: '2rem' }
            }
          >
            { subheader2 }
          </h3>
        }
        { body2 &&
          <p className={ css.paragraph }>
            { body2 }
          </p>
        }
        { listItems2.length > 0 &&
          <div className={ css['titled-list'] }>
            <ul className={ classNames(css.list, css.unordered) }>
              { listItems2.map((item, idx) => {
                  return (
                    <div key={ `item-${idx}` } className={ css['list-item'] }>
                      <li>{ item }</li>
                    </div>
                  );
                })
              }
            </ul>
          </div>
        }
        { closing &&
          <p className={ css.paragraph }>
            { closing }
          </p>
        }
      </div>
    </div>
  );
};

export default TitledImageBody;
