/* eslint-disable react/no-unknown-property */
import classNames from 'classnames';

import css from '../svg.module.scss';


type TechnologyProps = {
  animated: boolean;
  color: string;
};

const Technology = ({ animated, color }: TechnologyProps) => {
  return (
    <svg viewBox={ '0 0 120 120' } xmlns={ 'http://www.w3.org/2000/svg' }>
      <g>
        { animated && <animateTransform attributeName={ 'transform' } attributeType={ 'XML' } type={ 'rotate' } from={ '-360 48.1 82.5' } to={ '0 48.1 82.5' } dur={ '6.67s' } begin={ '0s' } repeatCount={ 'indefinite' } /> }
        <ellipse className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '41.62px 117.724px' } cx={ '41.62' } cy={ '117.724' } rx={ '26' } ry={ '26' } transform={ 'matrix(0.9396930933, 0.342020004988, -0.342020004988, 0.9396930933, 6.543011340985, -35.135255556541)' } />
        <ellipse className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '1px' } transform-origin={ '41.62px 117.724px' } cx={ '41.62' } cy={ '117.724' } rx={ '22' } ry={ '22' } transform={ 'matrix(0.9396930933, 0.342020004988, -0.342020004988, 0.9396930933, 6.543011340985, -35.135255556541)' } />
        <ellipse className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '2px' } transform-origin={ '41.62px 117.724px' } cx={ '41.62' } cy={ '117.724' } rx={ '8' } ry={ '8' } transform={ 'matrix(0.9396930933, 0.342020004988, -0.342020004988, 0.9396930933, 6.543011340985, -35.135255556541)' } />
        <path d={ 'M 42.655 106.928 L 40.789 115.546 L 34.747 115.546 L 32.881 106.928 L 33.039 106.756 L 42.497 106.756 L 42.655 106.928 Z' } className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '37.768px 111.151px' } transform={ 'matrix(0.9396930933, 0.342020004988, -0.342020004988, 0.9396930933, -4.89432e-7, 0.000003745866)' } />
        <path d={ 'M 25.531 91.296 L 23.665 99.914 L 17.623 99.914 L 15.757 91.296 L 15.915 91.124 L 25.373 91.124 L 25.531 91.296 Z' } className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '20.644px 95.519px' } transform={ 'matrix(0.422618061304, 0.906307995319, -0.906307995319, 0.422618061304, 0.000006526578, -0.000001884025)' } />
        <path d={ 'M 65.938 106.003 L 64.072 114.621 L 58.03 114.621 L 56.164 106.003 L 56.322 105.831 L 65.78 105.831 L 65.938 106.003 Z' } className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '61.051px 110.226px' } transform={ 'matrix(0.906307995319, -0.422618061304, 0.422618061304, 0.906307995319, -0.000003647268, 0.00000260192)' } />
        <path d={ 'M 40.238 50.889 L 38.372 59.507 L 32.33 59.507 L 30.464 50.889 L 30.622 50.717 L 40.08 50.717 L 40.238 50.889 Z' } className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '35.351px 55.112px' } transform={ 'matrix(-0.906307995319, 0.422618061304, -0.422618061304, -0.906307995319, -0.0000028994, -0.000003646966)' } />
        <path d={ 'M 24.291 67.898 L 22.425 76.516 L 16.383 76.516 L 14.517 67.898 L 14.675 67.726 L 24.133 67.726 L 24.291 67.898 Z' } className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '19.404px 72.121px' } transform={ 'matrix(-0.342020004988, 0.9396930933, -0.9396930933, -0.342020004988, 0.000002782148, 0.000001336222)' } />
        <path d={ 'M 63.518 49.605 L 61.652 58.223 L 55.61 58.223 L 53.744 49.605 L 53.902 49.433 L 63.36 49.433 L 63.518 49.605 Z' } className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '58.631px 53.828px' } transform={ 'matrix(-0.9396930933, -0.342020004988, 0.342020004988, -0.9396930933, 0.000002267097, -0.000002790045)' } />
        <path d={ 'M 80.645 65.596 L 78.779 74.214 L 72.737 74.214 L 70.871 65.596 L 71.029 65.424 L 80.487 65.424 L 80.645 65.596 Z' } className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '75.758px 69.819px' } transform={ 'matrix(-0.422618061304, -0.906307995319, 0.906307995319, -0.422618061304, 0.000007502092, 0.000002795027)' } />
        <path d={ 'M 81.613 88.761 L 79.747 97.379 L 73.705 97.379 L 71.839 88.761 L 71.997 88.589 L 81.455 88.589 L 81.613 88.761 Z' } className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '76.726px 92.984px' } transform={ 'matrix(0.342020004988, -0.9396930933, 0.9396930933, 0.342020004988, -0.000009649507, 0.000006379619)' } />
      </g>
      <g>
        { animated && <animateTransform attributeName={ 'transform' } attributeType={ 'XML' } type={ 'rotate' } from={ '360 86.3 42.8' } to={ '0 86.3 42.8' } dur={ '5s' } begin={ '0s' } repeatCount={ 'indefinite' } /> }
        <ellipse className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '111.917px 81.778px' } cx={ '111.917' } cy={ '81.778' } rx={ '12.5' } ry={ '12.5' } transform={ 'matrix(0.974370002747, -0.22494995594, 0.22494995594, 0.974370002747, -25.612086027593, -38.979726681415)' } />
        <ellipse className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '111.917px 81.778px' } cx={ '111.917' } cy={ '81.778' } rx={ '9' } ry={ '9' } transform={ 'matrix(0.974370002747, -0.22494995594, 0.22494995594, 0.974370002747, -25.612086027593, -38.979726681415)' } />
        <path d={ 'M 86.593 23.36 L 85.066 30.222 L 80.12 30.222 L 78.593 23.36 L 78.722 23.222 L 86.463 23.222 L 86.593 23.36 Z' } className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '82.593px 26.722px' } transform={ 'matrix(-0.974370002747, 0.224950954318, -0.224950954318, -0.974370002747, -0.00000686959, 0.000002417526)' } />
        <path d={ 'M 94.017 55.514 L 92.49 62.376 L 87.544 62.376 L 86.017 55.514 L 86.146 55.376 L 93.887 55.376 L 94.017 55.514 Z' } className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '90.017px 58.876px' } transform={ 'matrix(0.974370002747, -0.224950954318, 0.224950954318, 0.974370002747, -0.000003897917, 0.000001011439)' } />
        <path d={ 'M 78.542 50.583 L 77.015 57.445 L 72.069 57.445 L 70.542 50.583 L 70.671 50.445 L 78.412 50.445 L 78.542 50.583 Z' } className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '74.542px 53.945px' } transform={ 'matrix(0.68199890852, 0.731352984905, -0.731352984905, 0.68199890852, -0.000002996587, 3.86425e-7)' } />
        <path d={ 'M 74.718 34.019 L 73.191 40.881 L 68.245 40.881 L 66.718 34.019 L 66.847 33.881 L 74.588 33.881 L 74.718 34.019 Z' } className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '70.718px 37.381px' } transform={ 'matrix(-0.292371064425, 0.956305027008, -0.956305027008, -0.292371064425, -0.000011479023, 0.000007287442)' } />
        <path d={ 'M 102.059 27.967 L 100.532 34.829 L 95.586 34.829 L 94.059 27.967 L 94.188 27.829 L 101.929 27.829 L 102.059 27.967 Z' } className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '98.059px 31.329px' } transform={ 'matrix(-0.68199890852, -0.731352984905, 0.731352984905, -0.68199890852, 0.000001118534, 0.000008584099)' } />
        <path d={ 'M 105.881 44.531 L 104.354 51.393 L 99.408 51.393 L 97.881 44.531 L 98.01 44.393 L 105.751 44.393 L 105.881 44.531 Z' } className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '3px' } transform-origin={ '101.881px 47.893px' } transform={ 'matrix(0.292371064425, -0.956305027008, 0.956305027008, 0.292371064425, 0.000004560826, 0.00000804376)' } />
      </g>
      <g>
        { animated && <animateTransform attributeName={ 'transform' } attributeType={ 'XML' } type={ 'rotate' } from={ '-360 61.3 18.2' } to={ '0 61.3 18.2' } dur={ '5s' } begin={ '0s' } repeatCount={ 'indefinite' } /> }
        <ellipse className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '2px' } transform-origin={ '84.169px 55.171px' } cx={ '84.169' } cy={ '55.171' } rx={ '12' } ry={ '12' } transform={ 'matrix(0.984808027744, 0.173649027944, -0.173649027944, 0.984808027744, -22.074705978433, -36.93025048974)' } />
        <ellipse className={ css[`${color}-stroke-no-fill`] } strokeWidth={ '2px' } transform-origin={ '84.169px 55.171px' } cx={ '84.169' } cy={ '55.171' } rx={ '2' } ry={ '2' } transform={ 'matrix(0.984808027744, 0.173649027944, -0.173649027944, 0.984808027744, -22.074705978433, -36.93025048974)' } />
        <path className={ classNames(css[`${color}-stroke-no-fill`], css[`${color}-fill`]) } d={ 'M 67.401 3.595 L 66.447 6.535 L 63.356 6.535 L 62.401 3.595 L 62.482 3.535 L 67.321 3.535 L 67.401 3.595 Z' } strokeWidth={ '2px' } transform-origin={ '64.901px 5.035px' } transform={ 'matrix(-0.97814810276, -0.207911998034, 0.207911998034, -0.97814810276, 0.000014326635, 2.75309e-7)' } />
        <path className={ classNames(css[`${color}-stroke-no-fill`], css[`${color}-fill`]) } d={ 'M 53.89 7.28 L 52.936 10.22 L 49.845 10.22 L 48.89 7.28 L 48.971 7.22 L 53.81 7.22 L 53.89 7.28 Z' } strokeWidth={ '2px' } transform-origin={ '51.39px 8.72px' } transform={ 'matrix(-0.669130027294, 0.743145108223, -0.743145108223, -0.669130027294, 0.00000471869, 1.21448e-7)' } />
        <path className={ classNames(css[`${color}-stroke-no-fill`], css[`${color}-fill`]) } d={ 'M 50.979 20.975 L 50.025 23.915 L 46.934 23.915 L 45.979 20.975 L 46.06 20.915 L 50.899 20.915 L 50.979 20.975 Z' } strokeWidth={ '2px' } transform-origin={ '48.479px 22.415px' } transform={ 'matrix(0.309018015862, 0.95105612278, -0.95105612278, 0.309018015862, 0.000003134742, -0.000002393705)' } />
        <path className={ classNames(css[`${color}-stroke-no-fill`], css[`${color}-fill`]) } d={ 'M 75.432 26.172 L 74.478 29.112 L 71.387 29.112 L 70.432 26.172 L 70.513 26.112 L 75.352 26.112 L 75.432 26.172 Z' } strokeWidth={ '2px' } transform-origin={ '72.932px 27.612px' } transform={ 'matrix(0.669130027294, -0.743145108223, 0.743145108223, 0.669130027294, 0.00000260521, -0.000006238631)' } />
        <path className={ classNames(css[`${color}-stroke-no-fill`], css[`${color}-fill`]) } d={ 'M 78.343 12.478 L 77.389 15.418 L 74.298 15.418 L 73.343 12.478 L 73.424 12.418 L 78.263 12.418 L 78.343 12.478 Z' } strokeWidth={ '2px' } transform-origin={ '75.843px 13.918px' } transform={ 'matrix(-0.309018015862, -0.95105612278, 0.95105612278, -0.309018015861, -0.00000403728, -0.000001020481)' } />
        <path className={ classNames(css[`${color}-stroke-no-fill`], css[`${color}-fill`]) } d={ 'M 61.789 30.004 L 60.835 32.944 L 57.744 32.944 L 56.789 30.004 L 56.87 29.944 L 61.709 29.944 L 61.789 30.004 Z' } strokeWidth={ '2px' } transform-origin={ '59.289px 31.444px' } transform={ 'matrix(0.97814810276, 0.207911998034, -0.207911998034, 0.97814810276, 0.000004718059, 9.77589e-7)' } />
      </g>
    </svg>
  );
};

export default Technology;
