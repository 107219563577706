import classNames from 'classnames';

import { ImageProps } from '../../../types';

import css from '../blog-sections.module.scss';


type Subsection = {
  body: string;
  header: string;
  closing?: string;
  listItems?: Array<string>;
};

export type TitledSubsectionBodyProps = {
  body: string;
  header: string;
  subsections: Array<Subsection>;
  endImage?: ImageProps;
  subheader?: string;
};

const TitledSubsectionBody = ({
  body,
  endImage,
  header,
  subsections,
  subheader,
 }: TitledSubsectionBodyProps) => {
  return (
    <div className={ css['titled-subsection-body'] }>
      <h1 className={ css.header }>
        { header }
      </h1>
      { subheader && <h3 className={ css['header-3'] }>{ subheader }</h3> }
      { body &&
        <p className={ css.paragraph }>
          { body }
        </p>
      }
      { subsections.map((sub, idx) => {
          return (
            <div key={ `subsection-${idx}` } className={ css.subsection }>
              <h3 className={ css['header-3'] }>
                { sub.header }
              </h3>
              <p className={ css.paragraph }>
                { sub.body }
              </p>
              { sub.listItems && sub.listItems.length > 0 &&
                <div className={ css['titled-list'] }>
                  <ul className={ classNames(css.list, css.unordered) }>
                    { sub.listItems.map((item, idx) => {
                        return (
                          <div key={ `list-item-${idx}` } className={ css['list-item'] }>
                            <li>{ item }</li>
                          </div>
                        );
                      })
                    }
                  </ul>
                </div>
              }
              { sub.closing &&
                <p className={ css.paragraph }>
                  { sub.closing }
                </p>
              }
            </div>
          );
        })
      }
      { endImage &&
        <div className={ css['image-wrapper'] }>
          <img src={ endImage.image } alt={ endImage.imageAlt } />
        </div>
      }
    </div>
  );
};

export default TitledSubsectionBody;
