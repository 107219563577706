type FloodProps = {
  houseColor?: string;
  waterColor?: string;
};

const Flood = ({ houseColor = '#B87341', waterColor = '#5699D2' }: FloodProps) => {
  return (
    <svg version={ '1.1' } style={ { shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'crisp-edges' } } viewBox={ '0 0 512 512' } x={ '0px' } y={ '0px' } fillRule={ 'evenodd' } clipRule={ 'evenodd' } xmlns={ 'http://www.w3.org/2000/svg' }>
      <g style={ { fill: houseColor } } transform={ 'matrix(1.43176, 0, 0, 1.43176, 181.428558, -554.22345)' }>
        <path d={ 'M 83.17 540.56 L 76.963 575.859 C 69.205 577.41 61.059 579.738 53.689 583.617 L 54.853 577.022 L 30.415 572.756 L 25.76 597.194 L 33.13 598.745 C 18.002 609.219 -2.557 609.219 -17.685 599.133 L -6.436 535.517 L -56.088 526.595 L -64.621 575.471 C -71.604 574.695 -79.361 574.695 -86.344 575.859 L -75.095 512.631 L 16.063 458.324 L 83.17 540.56 Z M -3.721 592.151 L 21.106 596.418 L 25.372 571.592 L 0.546 567.325 L -3.721 592.151 Z M 1.322 562.283 L 26.148 566.937 L 30.415 542.111 L 5.977 537.845 L 1.322 562.283 Z M 31.19 567.713 L 56.016 572.368 L 60.283 547.542 L 35.846 543.275 L 31.19 567.713 Z' }></path>
        <path d={ 'M -115.049 494.4 L 2.098 424.964 L 25.372 411 L 42.44 431.947 L 64.939 459.488 L 68.817 437.378 L 100.626 442.808 L 91.704 492.072 L 128.942 537.457 L 105.281 551.421 L 19.166 445.911 L -97.981 515.346 L -115.049 494.4 Z' }></path>
      </g>
      <path style={ { fill: waterColor } } d={ 'M -111.17 616.201 C -117.376 622.407 -126.686 613.098 -120.48 606.891 C -95.265 581.678 -54.148 582.065 -29.322 606.891 C -7.987 623.959 22.657 623.959 43.992 606.891 C 68.817 582.065 109.547 581.678 135.149 606.891 C 158.035 625.511 191.395 624.735 213.505 604.563 C 216.221 602.236 220.1 602.625 222.815 605.34 C 225.143 608.055 224.755 611.934 222.04 614.649 C 194.886 638.699 154.157 639.475 126.227 616.589 C 104.504 594.866 74.636 597.194 52.525 616.589 C 26.536 638.312 -12.255 638.312 -38.244 616.589 C -58.415 596.418 -90.611 595.642 -111.17 616.201 Z' } transform={ 'matrix(1.4317599534988403, 0, 0, 1.4317599534988403, 181.42855834960938, -554.2234497070314)' } />
      <g style={ { fill: waterColor } } transform={ 'matrix(1.43176, 0, 0, 1.43176, 181.428558, -554.22345)' }>
        <path d={ 'M -57.199 697.857 C -58.8 699.458 -61.201 697.057 -59.601 695.455 C -53.097 688.952 -42.491 689.052 -36.088 695.455 C -32.885 698.018 -28.868 699.089 -24.985 698.668 L -24.985 698.672 C -21.902 698.286 -18.909 696.974 -16.455 694.734 C -15.896 694.255 -15.146 694.222 -14.51 694.584 C -14.32 694.681 -14.139 694.813 -13.975 694.977 C -13.374 695.678 -13.474 696.678 -14.174 697.379 C -17.284 700.133 -21.084 701.704 -24.979 702.082 L -24.979 702.031 C -24.98 702.032 -24.983 702.032 -24.985 702.032 L -24.985 702.082 C -29.712 702.513 -34.582 701.139 -38.389 697.957 C -43.591 692.754 -51.896 692.554 -57.199 697.857 Z' }></path>
        <path d={ 'M 113.252 685.507 C 111.652 687.108 109.25 684.706 110.851 683.106 C 117.354 676.603 127.96 676.702 134.364 683.106 C 137.567 685.669 141.584 686.739 145.467 686.319 L 145.467 686.322 C 148.55 685.937 151.542 684.624 153.997 682.384 C 154.556 681.906 155.305 681.872 155.942 682.235 C 156.132 682.332 156.313 682.463 156.477 682.627 C 157.077 683.328 156.978 684.329 156.276 685.029 C 153.167 687.783 149.367 689.354 145.473 689.733 L 145.473 689.682 C 145.471 689.682 145.469 689.682 145.467 689.682 L 145.467 689.732 C 140.74 690.164 135.868 688.789 132.063 685.607 C 126.859 680.404 118.555 680.204 113.252 685.507 Z' }></path>
        <path d={ 'M 150.578 664.868 C 149.777 665.668 148.577 664.467 149.378 663.668 C 152.629 660.415 157.932 660.466 161.134 663.668 C 162.735 664.949 164.745 665.485 166.686 665.274 L 166.686 665.276 C 168.228 665.083 169.724 664.427 170.95 663.307 C 171.23 663.067 171.605 663.051 171.924 663.231 C 172.018 663.281 172.108 663.345 172.191 663.428 C 172.491 663.778 172.441 664.279 172.091 664.629 C 170.535 666.006 168.636 666.791 166.688 666.981 L 166.688 666.955 C 166.687 666.955 166.686 666.955 166.686 666.955 L 166.686 666.981 C 164.322 667.196 161.886 666.51 159.983 664.918 C 157.382 662.317 153.23 662.216 150.578 664.868 Z' }></path>
        <path d={ 'M 49.723 666.837 C 48.922 667.637 47.722 666.437 48.523 665.636 C 51.774 662.385 57.077 662.434 60.279 665.636 C 61.881 666.918 63.889 667.453 65.831 667.243 L 65.831 667.245 C 67.373 667.053 68.868 666.395 70.096 665.276 C 70.375 665.036 70.749 665.02 71.069 665.201 C 71.163 665.25 71.254 665.315 71.336 665.397 C 71.635 665.747 71.586 666.248 71.236 666.598 C 69.68 667.975 67.781 668.761 65.834 668.95 L 65.834 668.925 C 65.833 668.925 65.831 668.925 65.831 668.925 L 65.831 668.949 C 63.467 669.165 61.031 668.478 59.129 666.886 C 56.528 664.286 52.375 664.185 49.723 666.837 Z' }></path>
        <path d={ 'M 204.885 718.636 C 204.084 719.436 202.884 718.236 203.684 717.435 C 206.935 714.183 212.239 714.233 215.441 717.435 C 217.042 718.716 219.051 719.252 220.992 719.042 L 220.992 719.044 C 222.534 718.851 224.03 718.194 225.257 717.075 C 225.536 716.835 225.911 716.819 226.23 716.999 C 226.325 717.048 226.415 717.113 226.497 717.196 C 226.797 717.546 226.747 718.046 226.397 718.397 C 224.842 719.774 222.943 720.56 220.995 720.749 L 220.995 720.724 C 220.994 720.724 220.993 720.724 220.992 720.724 L 220.992 720.748 C 218.628 720.964 216.193 720.277 214.29 718.685 C 211.689 716.084 207.537 715.984 204.885 718.636 Z' }></path>
        <animateMotion path={ 'M 0 0 L 410.7 0' } calcMode={ 'linear' } begin={ '0s' } dur={ '2s' } fill={ 'freeze' } repeatCount={ 'indefinite' }></animateMotion>
      </g>
      <g style={ { fill: waterColor } } transform={ 'matrix(1.43176, 0, 0, 1.43176, -229.31041, -554.247437)' }>
        <path d={ 'M -57.199 697.857 C -58.8 699.458 -61.201 697.057 -59.601 695.455 C -53.097 688.952 -42.491 689.052 -36.088 695.455 C -32.885 698.018 -28.868 699.089 -24.985 698.668 L -24.985 698.672 C -21.902 698.286 -18.909 696.974 -16.455 694.734 C -15.896 694.255 -15.146 694.222 -14.51 694.584 C -14.32 694.681 -14.139 694.813 -13.975 694.977 C -13.374 695.678 -13.474 696.678 -14.174 697.379 C -17.284 700.133 -21.084 701.704 -24.979 702.082 L -24.979 702.031 C -24.98 702.032 -24.983 702.032 -24.985 702.032 L -24.985 702.082 C -29.712 702.513 -34.582 701.139 -38.389 697.957 C -43.591 692.754 -51.896 692.554 -57.199 697.857 Z' }></path>
        <path d={ 'M 113.252 685.507 C 111.652 687.108 109.25 684.706 110.851 683.106 C 117.354 676.603 127.96 676.702 134.364 683.106 C 137.567 685.669 141.584 686.739 145.467 686.319 L 145.467 686.322 C 148.55 685.937 151.542 684.624 153.997 682.384 C 154.556 681.906 155.305 681.872 155.942 682.235 C 156.132 682.332 156.313 682.463 156.477 682.627 C 157.077 683.328 156.978 684.329 156.276 685.029 C 153.167 687.783 149.367 689.354 145.473 689.733 L 145.473 689.682 C 145.471 689.682 145.469 689.682 145.467 689.682 L 145.467 689.732 C 140.74 690.164 135.868 688.789 132.063 685.607 C 126.859 680.404 118.555 680.204 113.252 685.507 Z' }></path>
        <path d={ 'M 150.578 664.868 C 149.777 665.668 148.577 664.467 149.378 663.668 C 152.629 660.415 157.932 660.466 161.134 663.668 C 162.735 664.949 164.745 665.485 166.686 665.274 L 166.686 665.276 C 168.228 665.083 169.724 664.427 170.95 663.307 C 171.23 663.067 171.605 663.051 171.924 663.231 C 172.018 663.281 172.108 663.345 172.191 663.428 C 172.491 663.778 172.441 664.279 172.091 664.629 C 170.535 666.006 168.636 666.791 166.688 666.981 L 166.688 666.955 C 166.687 666.955 166.686 666.955 166.686 666.955 L 166.686 666.981 C 164.322 667.196 161.886 666.51 159.983 664.918 C 157.382 662.317 153.23 662.216 150.578 664.868 Z' }></path>
        <path d={ 'M 49.723 666.837 C 48.922 667.637 47.722 666.437 48.523 665.636 C 51.774 662.385 57.077 662.434 60.279 665.636 C 61.881 666.918 63.889 667.453 65.831 667.243 L 65.831 667.245 C 67.373 667.053 68.868 666.395 70.096 665.276 C 70.375 665.036 70.749 665.02 71.069 665.201 C 71.163 665.25 71.254 665.315 71.336 665.397 C 71.635 665.747 71.586 666.248 71.236 666.598 C 69.68 667.975 67.781 668.761 65.834 668.95 L 65.834 668.925 C 65.833 668.925 65.831 668.925 65.831 668.925 L 65.831 668.949 C 63.467 669.165 61.031 668.478 59.129 666.886 C 56.528 664.286 52.375 664.185 49.723 666.837 Z' }></path>
        <path d={ 'M 204.885 718.636 C 204.084 719.436 202.884 718.236 203.684 717.435 C 206.935 714.183 212.239 714.233 215.441 717.435 C 217.042 718.716 219.051 719.252 220.992 719.042 L 220.992 719.044 C 222.534 718.851 224.03 718.194 225.257 717.075 C 225.536 716.835 225.911 716.819 226.23 716.999 C 226.325 717.048 226.415 717.113 226.497 717.196 C 226.797 717.546 226.747 718.046 226.397 718.397 C 224.842 719.774 222.943 720.56 220.995 720.749 L 220.995 720.724 C 220.994 720.724 220.993 720.724 220.992 720.724 L 220.992 720.748 C 218.628 720.964 216.193 720.277 214.29 718.685 C 211.689 716.084 207.537 715.984 204.885 718.636 Z' }></path>
        <animateMotion path={ 'M 0 0 L 410.7 0' } calcMode={ 'linear' } begin={ '0s' } dur={ '2s' } fill={ 'freeze' } repeatCount={ 'indefinite' }></animateMotion>
      </g>
      <g style={ { fill: waterColor } } transform={ 'matrix(1.43176, 0, 0, 1.43176, -640.010376, -554.247437)' }>
        <path d={ 'M -57.199 697.857 C -58.8 699.458 -61.201 697.057 -59.601 695.455 C -53.097 688.952 -42.491 689.052 -36.088 695.455 C -32.885 698.018 -28.868 699.089 -24.985 698.668 L -24.985 698.672 C -21.902 698.286 -18.909 696.974 -16.455 694.734 C -15.896 694.255 -15.146 694.222 -14.51 694.584 C -14.32 694.681 -14.139 694.813 -13.975 694.977 C -13.374 695.678 -13.474 696.678 -14.174 697.379 C -17.284 700.133 -21.084 701.704 -24.979 702.082 L -24.979 702.031 C -24.98 702.032 -24.983 702.032 -24.985 702.032 L -24.985 702.082 C -29.712 702.513 -34.582 701.139 -38.389 697.957 C -43.591 692.754 -51.896 692.554 -57.199 697.857 Z' }></path>
        <path d={ 'M 113.252 685.507 C 111.652 687.108 109.25 684.706 110.851 683.106 C 117.354 676.603 127.96 676.702 134.364 683.106 C 137.567 685.669 141.584 686.739 145.467 686.319 L 145.467 686.322 C 148.55 685.937 151.542 684.624 153.997 682.384 C 154.556 681.906 155.305 681.872 155.942 682.235 C 156.132 682.332 156.313 682.463 156.477 682.627 C 157.077 683.328 156.978 684.329 156.276 685.029 C 153.167 687.783 149.367 689.354 145.473 689.733 L 145.473 689.682 C 145.471 689.682 145.469 689.682 145.467 689.682 L 145.467 689.732 C 140.74 690.164 135.868 688.789 132.063 685.607 C 126.859 680.404 118.555 680.204 113.252 685.507 Z' }></path>
        <path d={ 'M 150.578 664.868 C 149.777 665.668 148.577 664.467 149.378 663.668 C 152.629 660.415 157.932 660.466 161.134 663.668 C 162.735 664.949 164.745 665.485 166.686 665.274 L 166.686 665.276 C 168.228 665.083 169.724 664.427 170.95 663.307 C 171.23 663.067 171.605 663.051 171.924 663.231 C 172.018 663.281 172.108 663.345 172.191 663.428 C 172.491 663.778 172.441 664.279 172.091 664.629 C 170.535 666.006 168.636 666.791 166.688 666.981 L 166.688 666.955 C 166.687 666.955 166.686 666.955 166.686 666.955 L 166.686 666.981 C 164.322 667.196 161.886 666.51 159.983 664.918 C 157.382 662.317 153.23 662.216 150.578 664.868 Z' }></path>
        <path d={ 'M 49.723 666.837 C 48.922 667.637 47.722 666.437 48.523 665.636 C 51.774 662.385 57.077 662.434 60.279 665.636 C 61.881 666.918 63.889 667.453 65.831 667.243 L 65.831 667.245 C 67.373 667.053 68.868 666.395 70.096 665.276 C 70.375 665.036 70.749 665.02 71.069 665.201 C 71.163 665.25 71.254 665.315 71.336 665.397 C 71.635 665.747 71.586 666.248 71.236 666.598 C 69.68 667.975 67.781 668.761 65.834 668.95 L 65.834 668.925 C 65.833 668.925 65.831 668.925 65.831 668.925 L 65.831 668.949 C 63.467 669.165 61.031 668.478 59.129 666.886 C 56.528 664.286 52.375 664.185 49.723 666.837 Z' }></path>
        <path d={ 'M 204.885 718.636 C 204.084 719.436 202.884 718.236 203.684 717.435 C 206.935 714.183 212.239 714.233 215.441 717.435 C 217.042 718.716 219.051 719.252 220.992 719.042 L 220.992 719.044 C 222.534 718.851 224.03 718.194 225.257 717.075 C 225.536 716.835 225.911 716.819 226.23 716.999 C 226.325 717.048 226.415 717.113 226.497 717.196 C 226.797 717.546 226.747 718.046 226.397 718.397 C 224.842 719.774 222.943 720.56 220.995 720.749 L 220.995 720.724 C 220.994 720.724 220.993 720.724 220.992 720.724 L 220.992 720.748 C 218.628 720.964 216.193 720.277 214.29 718.685 C 211.689 716.084 207.537 715.984 204.885 718.636 Z' }></path>
        <animateMotion path={ 'M 0 0 L 410.7 0' } calcMode={ 'linear' } begin={ '0s' } dur={ '2s' } fill={ 'freeze' } repeatCount={ 'indefinite' }></animateMotion>
      </g>
    </svg>
  );
};

export default Flood;
