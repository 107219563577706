import { Section } from '../../../types';
import { COMPONENT_MAP } from '../../../util/constants';

import css from '../blog-sections.module.scss';


export type FlexedRowProps = {
  sections: Array<Section>;
};

const FlexedRow = ({ sections }: FlexedRowProps) => {
  return (
    <div className={ css['flexed-row'] }>
      { sections.map((sect, idx) => {
          const El = COMPONENT_MAP[sect.component];
          return (
            <div key={ `section-${idx}` } className={ css.even }>
              <div className={ css.section }>
                <El { ...sect.props } />
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default FlexedRow;
