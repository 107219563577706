import css from './diamond-center.module.scss';


type DiamondCenterProps = {
  color?: string;
  paddingBottom?: number;
  paddingTop?: number;
}

const DiamondCenter = ({
  color = 'white',
  paddingBottom = 0,
  paddingTop = 0,
}: DiamondCenterProps) => {
  return (
    <div className={ css['divider-container'] } style={ { padding: `${paddingTop}rem 0 ${paddingBottom}rem 0` } }>
      <div className={ css['triangle-up'] } style={ { borderBottom: `1.25rem solid ${color}` } } />
      <div className={ css.divider } style={ { backgroundColor: color } } />
      <div className={ css['triangle-down'] } style={ { borderTop: `1.25rem solid ${color}` } } />
    </div>
  );
};

export default DiamondCenter;
