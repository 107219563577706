import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


const Redirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/emergency-management${location.pathname === '/enigma-advisory-blog'
      ? '/insight'
      : location.pathname}`
    );
  });

  return (
    <></>
  );
};

export const BlogRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/emergency-management/insight${location.pathname}`
    );
  });

  return (
    <></>
  );
};

export default Redirect;
