import classNames from 'classnames';

import css from './header.module.scss';


type HeaderProps = {
  backgroundImage: string;
  header: string;
  subheader?: string
};

const Header = ({
  backgroundImage,
  header,
  subheader,
}: HeaderProps) => {
  return (
    <div
      className={ classNames(css['center-wrapper'], css.background) }
      style={ { backgroundImage: `url(${backgroundImage})` } }
    >
      <div className={ classNames(css['image-overlay'], css['margin-10p']) }>
        <h1 className={ css.header }>
          { header }
        </h1>
        <h2 className={ classNames(css['header-2'], css['text-bold-blue']) }>
          { subheader }
        </h2>
      </div>
    </div>
  );
};

export default Header;
