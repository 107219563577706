import {
  ChevronLeft,
  ChevronRight,
  CircleFill,
  CircleStroke,
} from '@carbon/icons-react';
import { ReactElement } from 'react';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import css from './flat-carousel.module.scss';


type CarouselProps = {
  items: Array<ReactElement>;
  swipable?: boolean;
};

const FlatCarousel = ({ items, swipable = true }: CarouselProps) => {
  return (
    <Carousel
      autoPlay={ true }
      centerMode={ true }
      centerSlidePercentage={ window.innerWidth < 600 ? undefined : getCenterPercentage() }
      infiniteLoop={ true }
      showStatus={ false }
      showThumbs={ false }
      stopOnHover={ true }
      swipeable={ swipable }
      renderArrowPrev={ (onClickHandler, hasPrev, label) =>
        hasPrev && (
          <div
            className={ css.arrow }
            onClick={ onClickHandler }
            title={ label }
            style={ { left: 15 } }>
              <ChevronLeft size={ '3rem' } />
          </div>
        )
      }
      renderArrowNext={ (onClickHandler, hasNext, label) =>
        hasNext && (
          <div
            className={ css.arrow }
            onClick={ onClickHandler }
            title={ label }
            style={ { right: 15 } }>
              <ChevronRight size={ '3rem' } />
          </div>
        )
      }
      renderIndicator={ (onClickHandler, isSelected, index, label) =>
        <span
          className={ css.indicator }
          onClick={ onClickHandler }
          onKeyDown={ onClickHandler }
          key={ index }
          role={ 'button' }
          tabIndex={ 0 }
          aria-label={ `${label} ${index + 1}` }
        >
          { isSelected ? <CircleFill /> : <CircleStroke /> }
        </span>
      }
    >
      { items.map((item, idx) => {
          return (
            <div key={ `carousel-item-${idx}` }>
              { item }
            </div>
          );
        })
      }
    </Carousel>
  );
};

/**
 * Calculates carousel item width percentage based on window width
 * 90% when window <= 600px
 * 55% when window == 1200px
 * Calculates the rate between those 2 points to use for all other widths
 * Max percentage = 90, Min percentage = 10
 * @returns number
 */
const getCenterPercentage = () => {
  const w = window.innerWidth;
  const rate = 600/45;
  const percent = 90 - ((w - 600) / rate);
  if (percent >= 90) {
    return 90;
  }
  if (percent <= 37) {
    return 37;
  }
  return percent;
};

export default FlatCarousel;
