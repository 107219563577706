import { CircleSolid } from '@carbon/icons-react';
import classNames from 'classnames';
import React from 'react';

import { PageProps } from '../../components/Templates/EmergencyManagement';

import PMM from '../../assets/images/preparedness-mitigation-management.jpg';
import Mitigation from '../../assets/images/preparedness-mitigation.jpg';
import RPM from '../../assets/images/response-planning-mitigation.jpg';

import s from '../../i18n/strings.json';

import css from '../../components/Templates/templates.module.scss';


const section1 = () => {
  return (
    <React.Fragment>
      <div className={ css['blue-gradient'] }>
        <h2 className={ css['header-2'] }>
          { s.services.mitigation_planning.MITIGATION_PLANNING }
        </h2>
      </div>
      <div className={ css['section'] }>
        <div className={ css['text-section'] }>
          <p className={ css['paragraph'] }>
            { s.services.mitigation_planning.MITIGATION_PLANNING_INFO1 }
          </p>
          <div className={ css['center-wrapper-2'] }>
            { [
                s.services.mitigation_planning.DEMONSTRATE1,
                s.services.mitigation_planning.DEMONSTRATE2,
                s.services.mitigation_planning.DEMONSTRATE3,
              ].map((phase, idx) => {
                return (
                  <div
                    key={ `phase-${idx}` }
                    className={ classNames(css['icon-label'], css['text-bold-blue']) }
                  >
                    <CircleSolid />
                    { phase }
                  </div>
                );
              })
            }
          </div>
          <p className={ css['paragraph'] }>
            {s.services.mitigation_planning.MITIGATION_PLANNING_INFO2}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

/* Props for Mitigation Planning page */
const props: PageProps = {
  seo: {
    description: s.services.mitigation_planning.META_DESCRIPTION,
    title: s.services.mitigation_planning.TITLE,
  },
  header: {
    backgroundImage: PMM,
    header: s.common.MITIGATION_EMERGENCY_MANAGEMENT,
    subheader: s.common.ENIGMA_ADVISORY,
  },
  section1: {
    header: '',
    text: '',
    custom: section1(),
  },
  section2: {
    backgroundImage: Mitigation,
    header: s.services.mitigation_planning.DEVELOP_PLAN,
    text: s.services.mitigation_planning.DEVELOP_PLAN_INFO,
  },
  section4: {
    list: [
      s.services.mitigation_planning.SERVICE1,
      s.services.mitigation_planning.SERVICE2,
      s.services.mitigation_planning.SERVICE3,
      s.services.mitigation_planning.SERVICE4,
      s.services.mitigation_planning.SERVICE5,
      s.services.mitigation_planning.SERVICE6,
      s.services.mitigation_planning.SERVICE7,
      s.services.mitigation_planning.SERVICE8,
      s.services.mitigation_planning.SERVICE9,
      s.services.mitigation_planning.SERVICE10,
      s.services.mitigation_planning.SERVICE11,
      s.services.mitigation_planning.SERVICE12,
      s.services.mitigation_planning.SERVICE13,
    ],
    header: s.services.mitigation_planning.TERRITORIAL_SERVICES,
    text: s.services.mitigation_planning.TERRITORIAL_SERVICES_INFO,
  },
  section5: {
    backgroundImage: RPM,
    header: s.services.mitigation_planning.HIRE_EXPERTS,
    actionText: s.services.mitigation_planning.HIRE_EXPERTS_INFO,
  },
};

export default props;
