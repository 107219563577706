export type RequestProps = {
  method: 'GET' | 'POST';
  headers: object;
  body?: string,
};

const baseRequest = ({ method, body, headers }: RequestProps) => {
  if (typeof window !== 'undefined') {
    headers = {
      ...headers,
    };
  }

  if (method === 'GET') {
    return {
      method,
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
        }
      ),
    };
  }

  return {
    method,
    body,
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
      }
    ),
  };
};

export default baseRequest;
