import { Close, Launch } from '@carbon/icons-react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SlidingPanel from 'react-sliding-side-panel';
import { Tooltip } from 'react-tooltip';

import Tablet from '../../assets/images/blog-tablet.jpg';

import BlogCard from '../../components/Cards/BlogCard';
import FlatCarousel from '../../components/FlatCarousel';
import Footer from '../../components/Footer';
import Header from '../../components/PageSections/Header';
import PostTemplate from '../../components/Templates/BlogPost';

import { ROUTES } from '../../Routes';
import BLOGS, { BlogPost, getBlog } from './blogs';

import s from '../../i18n/strings.json';

import { Helmet } from 'react-helmet';
import PageNotFound from '../../components/PageNotFound';
import css from './blog.module.scss';


type InsightProps = {
  openContact: () => void;
  windowWidth: number;
};

const Insight = ({ openContact, windowWidth }: InsightProps) => {
  const initializePost = () => {
    const id = searchParams.get('id');
    if (id) {
      const blog = getBlog(id);
      return blog || undefined;
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [post, setPost] = useState<BlogPost | undefined>(initializePost());
  const blogs = [ ...BLOGS];

  const handleInsightAction = (route: string, content: BlogPost) => {
    setSearchParams(searchParams => {
      searchParams.set('id', route);
      return searchParams;
    });
    setPost({ ...content, route });
  };

  const handleClosePost = () => {
    setSearchParams({});
    setPost(undefined);
  };

  initializePost();

  return (
    <div className={ css.blog }>
      <Helmet>
        <title>{ s.insight.TITLE }</title>
        <meta name={ s.common.DESCRIPTION } content={ s.testimonials.META_DESCRIPTION } />
      </Helmet>
      <Header
        backgroundImage={ Tablet }
        header={ s.common.DISASTER_RECOVERY_INSIGHT }
        subheader={ s.common.ENIGMA_ADVISORY }
      />
      <div className={ css.section }>
        <div className={ css['blog-grid'] }>
          <div className={ css['main-blog'] }>
            <BlogCard
              header={ blogs[0][1].header }
              image={ blogs[0][1].image }
              imageAlt={ blogs[0][1].imageAlt }
              snippet={ blogs[0][1].snippet }
              tags={ blogs[0][1].tags }
              onClick={ () => handleInsightAction(...blogs[0]) }
              anchorLink={ `/disaster/insight/${blogs[0][0]}` }
            />
          </div>
          <div className={ css['blog-quad'] }>
            <div className={ css['blog-duo'] }>
              { blogs.length > 1 && blogs.slice(1, 3).map((blog, idx) => {
                  return (
                    <BlogCard
                      key={ `blog-${idx}` }
                      header={ blog[1].header }
                      image={ blog[1].image }
                      imageAlt={ blog[1].imageAlt }
                      snippet={ blog[1].snippet }
                      tags={ blog[1].tags }
                      onClick={ () => handleInsightAction(...blog) }
                      anchorLink={ `/disaster/insight/${blog[0]}` }
                    />
                  );
                })
              }
            </div>
            <div className={ css['blog-duo'] }>
              { blogs.length > 3 && blogs.slice(3, 5).map((blog, idx) => {
                  return (
                    <BlogCard
                      key={ `blog-${idx}` }
                      header={ blog[1].header }
                      image={ blog[1].image }
                      imageAlt={ blog[1].imageAlt }
                      snippet={ blog[1].snippet }
                      tags={ blog[1].tags }
                      onClick={ () => handleInsightAction(...blog) }
                      anchorLink={ `/disaster/insight/${blog[0]}` }
                    />
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div className={ css.carousel }>
        <FlatCarousel
          items={ blogs.slice(5).map((blog, idx) =>
              <div key={ `blog-${idx}` } className={ css['carousel-item'] }>
                <BlogCard
                  header={ blog[1].header }
                  image={ blog[1].image }
                  imageAlt={ blog[1].imageAlt }
                  minimized={ true }
                  snippet={ blog[1].snippet }
                  tags={ blog[1].tags }
                  onClick={ () => handleInsightAction(...blog) }
                  anchorLink={ `/disaster/insight/${blog[0]}` }
                />
              </div>
          ) }
          swipable={ windowWidth > 800 }
        />
      </div>
      <SlidingPanel
        type={ 'left' }
        isOpen={ post ? true : false }
        size={ windowWidth > 800 ? 85 : 100 }
      >
        { post
          ? <div className={ css.post }>
              <PostTemplate content={ post } />
              <div className={ css.actions }>
                <div className={ css.action } onClick={ () => handleClosePost() }>
                  <Close id={ 'close-icon' } size={ 'md' } />
                  <Tooltip
                    anchorSelect={ '#close-icon' }
                    place={ 'bottom' }
                    content={ s.common.CLOSE }
                  />
                </div>
                <a
                  href={ `${ROUTES.insight}/${post.route || searchParams.get('id')}` }
                  target={ '_blank' }
                  rel={ 'noreferrer' }
                >
                  <div className={ css.action }>
                    <Launch id={ 'launch-icon' } size={ 'md' } />
                    <Tooltip
                      anchorSelect={ '#launch-icon' }
                      place={ 'bottom' }
                      content={ s.common.OPEN_IN_TAB }
                    />
                  </div>
                </a>
              </div>
            </div>
          : <div className={ css.post }>
              <PageNotFound openContact={ openContact } />
              <div className={ css.actions }>
                <div className={ css.action } onClick={ () => handleClosePost() }>
                  <Close id={ 'close-icon' } size={ 'md' } />
                  <Tooltip
                    anchorSelect={ '#close-icon' }
                    place={ 'bottom' }
                    content={ s.common.CLOSE }
                  />
                </div>
              </div>
            </div>
        }
      </SlidingPanel>
      <Footer openContact={ openContact } />
    </div>
  );
};

export default Insight;
