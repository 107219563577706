import { PageProps } from '../../components/Templates/EmergencyManagement';

import Mitigation from '../../assets/images/preparedness-mitigation.jpg';
import RDemo from '../../assets/images/response-demo.jpg';
import RFS from '../../assets/images/response-filesystem.jpg';
import RID from '../../assets/images/response-identification.jpg';
import RInspect from '../../assets/images/response-inspection.jpg';
import RPM from '../../assets/images/response-planning-mitigation.jpg';
import RResp from '../../assets/images/response-responders.jpg';
import RSW from '../../assets/images/response-supply-warehouse.jpg';

import s from '../../i18n/strings.json';


/* Props for Emergency Response Consulting page */
const props: PageProps = {
  seo: {
    description: s.services.response.META_DESCRIPTION,
    title: s.services.response.TITLE,
  },
  header: {
    backgroundImage: RResp,
    header: s.common.EMERGENCY_RESPONSE_MANAGEMENT,
    subheader: s.common.ENIGMA_ADVISORY,
  },
  section1: {
    header: s.services.response.OPS_AND_LOGISTICS,
    text: s.services.response.OPS_AND_LOGISTICS_INFO,
  },
  section2: {
    backgroundImage: Mitigation,
    header: s.services.response.EOC_LEADERSHIP_SUPPORT,
    text: s.services.response.EOC_LEADERSHIP_SUPPORT_INFO,
  },
  imageBlurbs: [
    {
      blurb: s.services.response.IMAGE_BLURB1,
      header: s.services.response.IMAGE_BLURB1_HEADER,
      image: RSW,
      imageAlt: 'workers wheeling crates in warehouse',
      color: '#3bafbf',
    },
    {
      blurb: s.services.response.IMAGE_BLURB2,
      header: s.services.response.IMAGE_BLURB2_HEADER,
      image: RFS,
      imageAlt: 'filesystem overlaying typing hands',
    },
    {
      blurb: s.services.response.IMAGE_BLURB3,
      header: s.services.response.IMAGE_BLURB3_HEADER,
      image: RID,
      imageAlt: 'disaster staffing & augmentation',
    },
    {
      blurb: s.services.response.IMAGE_BLURB4,
      header: s.services.response.IMAGE_BLURB4_HEADER,
      image: RDemo,
      imageAlt: 'debris cleanup management',
    },
    {
      blurb: s.services.response.IMAGE_BLURB5,
      header: s.services.response.IMAGE_BLURB5_HEADER,
      image: RInspect,
      imageAlt: 'property inspection',
    },
  ],
  section5: {
    backgroundImage: RPM,
    header: s.services.response.HIRE_THE_BEST,
    actionText: s.services.response.HIRE_THE_BEST_INFO,
  },
};

export default props;
