import { TitledSubsectionBodyProps } from '../components/BlogSections/TitledSubsectionBody';

import Fire from '../pages/Insight/images/fire-disaster.jpg';


const content = [
  {
    component: 'titled-subsection-body',
    props: {
      header: '5 Things Your Emergency Management Specialist Wants You To Know',
      endImage: {
        image: Fire,
        imageAlt: 'woman looking at burned down residence',
      },
      subsections: [
        {
          body: 'If you leave with only one piece of advice from our Emergency Management Specialist and Disaster Management Team, let it be this: Be prepared, not scared. That phrase alone demonstrates the most important factor we can present for the sake of you and your family’s safety. Be Prepared, Not Scared means that you take the time to prepare for a major disaster before catastrophe strikes. That way when the moment to act finally arrives, you respond promptly and logically to the situation; thus, increasing your odds at protecting the lives of those who you care about the most.\n\nThe goal here is to put more control of the situation back into your hands. This way when you are faced with a catastrophic event, that you can neither control or predict, you will be able to act with confidence. Using the knowledge from our Disaster Management Consultants to help you protect the health and well being of your family and yourself.',
          header: 'Be Prepared, Not Scared',
        },
        {
          body: 'When a disaster takes place, local and state authorities are often the first source of safety information. This can help you navigate the aftereffects of a disaster and help you plan accordingly using the most current and relevant information for your area. They will be the ones to issue important details, including evacuation notices, especially if your area becomes unsafe to remain in or occupy.',
          header: 'Connect with Local Authorities',
        },
        {
          body: 'Something that often goes overlooked during a disaster is coordinating with your loved ones regarding safety precautions. An example of this is communicating where to meet in case you get separated. This is particularly important in situations where you have children or family members who require physical or medical assistance. Creating an “Action Plan” is an excellent way to create a contingency plan if this takes place. That way everyone is on the same page and knows what to do if and when this happens.',
          header: 'Stay on the Same Page',
        },
        {
          body: 'A common issue during emergencies is people finding themselves with “dead” devices such as phones or laptops; leaving them with no way to communicate or get information. It’s important to have transportable charging methods, as well as backup charging chords for each device. If you have more than a few people with you, it may be valuable to invest in power strips for your emergency preparedness kit, so you can charge all of your devices even if there’s only one functional outlet.\n\nIf you live in an area susceptible to flooding, you will want to make sure you have bags/cases that can carry your devices and keep them dry.',
          header: 'Keep a Charge',
        },
        {
          body: 'Just like you should protect your electronics, preserving your important documentation can save you future headaches. Birth certificates, licenses, social security cards, and any financial documents that are important or difficult to replace. Make sure you can easily locate and transport these documents, and have a way to keep them dry in case of flooding or rain.',
          header: 'Protect Important Documents',
        },
        {
          body: 'Your animals need an action plan, too. During Hurricane Harvey, thousands of animals were displaced from their families. These animals often ended up in shelters, leaving the animal rehoming system overrun and overwhelmed from the influx. It is impossible to predict getting separated from your animals, but making sure that their contact information (chip, collar, etc.) is completely updated and accurate can make the entire process infinitely easier.',
          header: 'Bonus: Animal Action Plan',
        },
        {
          body: 'These tips are here to help you stay informed and stay safe in case of an emergency situation. Your family and your situation are unique to you, so take these ideas and adapt them to make sense for your particular situation. Disaster Preparedness is an integral precaution necessary to increasing your odds of protecting the well-being of your family and having a disaster plan ready and able to execute in the face of a catastrophe. Our Emergency Management Consultants are here to help Federal, State, and Local Agencies help you in the event of a disaster. But we are also here to help you be prepared and not scared if and when a disaster takes place.',
          header: 'Conclusion',
        },
      ],
    } as TitledSubsectionBodyProps,
  },
];

export default content;
