import { PageProps } from '../../components/Templates/EmergencyManagement';

import Nxt from '../../assets/images/emergency-preparedness-next.jpg';
import Presentation from '../../assets/images/emergency-preparedness-presentatin.jpg';
import Strategy from '../../assets/images/emergency-preparedness-strategy.jpg';
import Training from '../../assets/images/emergency-preparedness-training.jpg';
import DP from '../../assets/images/emergency-preparedness.jpg';
import Phase1 from '../../assets/images/recovery-phase-one.jpg';
import RPM from '../../assets/images/response-planning-mitigation.jpg';

import s from '../../i18n/strings.json';


const props: PageProps = {
  seo: {
    description: s.process.preparedness.META_DESCRIPTION,
    title: s.process.preparedness.TITLE,
  },
  header: {
    backgroundImage: DP,
    header: s.common.DISASTER_PREPAREDNESS,
    subheader: s.common.ENIGMA_ADVISORY,
  },
  section1: {
    header: s.process.preparedness['5_STEPS'],
    text: s.process.preparedness['5_STEPS_INFO'],
  },
  imageBlurbs: [
    {
      blurb: s.process.preparedness.IMAGE_BLURB1,
      header: s.process.preparedness.IMAGE_BLURB1_HEADER,
      image: Phase1,
      imageAlt: 'executive delegating to employees',
      color: '#327ec7',
      subheader: s.process.preparedness.IMAGE_BLURB1_SUBHEADER,
      subheaderColor: '#3bafbf',
    },
    {
      blurb: s.process.preparedness.IMAGE_BLURB2,
      header: s.process.preparedness.IMAGE_BLURB2_HEADER,
      image: Presentation,
      imageAlt: 'planning process',
      color: '#327ec7',
      subheader: s.process.preparedness.IMAGE_BLURB2_SUBHEADER,
      subheaderColor: '#3bafbf',
    },
    {
      blurb: s.process.preparedness.IMAGE_BLURB3,
      header: s.process.preparedness.IMAGE_BLURB3_HEADER,
      image: Strategy,
      imageAlt: 'executive strategy selection',
      color: '#327ec7',
      subheader: s.process.preparedness.IMAGE_BLURB3_SUBHEADER,
      subheaderColor: '#3bafbf',
    },
    {
      blurb: s.process.preparedness.IMAGE_BLURB4,
      header: s.process.preparedness.IMAGE_BLURB4_HEADER,
      image: Training,
      imageAlt: 'training initiatives',
      color: '#327ec7',
      subheader: s.process.preparedness.IMAGE_BLURB4_SUBHEADER,
      subheaderColor: '#3bafbf',
    },
    {
      blurb: s.process.preparedness.IMAGE_BLURB5,
      header: s.process.preparedness.IMAGE_BLURB5_HEADER,
      image: Nxt,
      imageAlt: 'planning whats next',
      color: '#327ec7',
      subheader: s.process.preparedness.IMAGE_BLURB5_SUBHEADER,
      subheaderColor: '#3bafbf',
    },
  ],
  section5: {
    backgroundImage: RPM,
    header: s.process.preparedness.EXPERTS,
    actionText: s.process.preparedness.EXPERTS_INFO,
  },
};

export default props;
