import { ReactComponent as BrandLogo } from '../../../assets/images/brand-logo-emergency.svg';

import css from '../blog-sections.module.scss';


export type EnigmaDocumentProps = {
  body: string;
  header: string;
};

const EnigmaDocument = ({ body, header }: EnigmaDocumentProps) => {
  return (
    <div className={ css['enigma-document'] }>
      <h1 className={ css.header }>
        { header }
      </h1>
      <p className={ css.paragraph }>
        { body }
      </p>
      <BrandLogo />
    </div>
  );
};

export default EnigmaDocument;
