import { FlexedRowProps } from '../components/BlogSections/FlexedRow';
import { TitledBodyProps } from '../components/BlogSections/TitledBody';
import { TitledDividedImageBodyProps } from '../components/BlogSections/TitledDividedImageBody';
import { ImageBlurbProps } from '../components/ImageBlurb';

import BCP from './images/business-continuity-plan.png';
import Man from './images/man-disaster-background.jpg';

import Preparedness from '../assets/images/preparedness-rpa.jpg';
import Recovery from '../assets/images/recovery-phase-one.jpg';


const content = [
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: Man,
          imageAlt: 'man reading tablet with disaster background',
        },
      ],
      imageSide: 'right',
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'No one likes to think about disasters, but they can and do happen. It’s important to have a comprehensive emergency management plan. A personalized disaster plan can help ensure that your business is able to respond effectively and quickly. This protects your staff, customers, and products, and can make a difference in how your business recovers.\n\nYour emergency management plan should cover all aspects of your business. This starts with understanding the risks known to your business and your industry. Then you can develop strategies to mitigate those risks. For example, if your business involves storing inventory, think of some ways you can protect the products from damage or theft? Take the time to think over every aspect of your business. How you can communicate with employees and customers, and keep them updated? Can you or your team continue business operations remotely? If not, can you find an alternative operating space? It’s a lot to consider, and it’s easy to get overwhelmed going down the ‘what if’ rabbit holes. Remember, the goal here is to be prepared for any disaster, not to be scared of something going wrong.\n\nA comprehensive emergency management plan should lay of steps to follow after a disaster. Make sure to review these documents regularly. Check that it is up-to-date and relevant to your current business operations. Also, ensure that everyone in your business can access the reference materials, even remotely. It’s imperative that everyone stay in the loop following a disaster. The last thing you want to do is cause more distress or panic in your staff or customer base. You never know when disaster will strike, so the most you can do is prepare yourself and your team as best you can.',
            header: 'Have a Plan in Place for Emergencies',
            subheader: 'Be Prepared Whenever Disaster Strikes',
          } as TitledBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
  {
    component: 'image-blurb',
    props: {
      blurb: 'Data management is a complex and critical part of any business operation. In the event of an emergency or disaster, this data can be lost or destroyed. This can have a devastating effect on your business. To protect your data, keep copies of important documents and records in a safe place. Back up your computer data regularly and scrutinize the level of data security your team implements within your database.',
      header: 'Keep Copies of Important Documents and Records',
      image: BCP,
      imageAlt: 'business continuity plan',
      imageSide: 'left',
    } as ImageBlurbProps,
  },
  {
    component: 'image-blurb',
    props: {
      blurb: 'If a disaster strikes your property or storefront, try to take pictures of the damages. This will be helpful for both your documentation and any relevant insurance claims. If needed, contact your relevant insurance providers. Be aware that your claims agent may or may not come out to physically inspect the damage. Plan accordingly and follow any directives they give you.',
      header: 'Check-In With Relevant Insurance Providers',
      image: Preparedness,
      imageAlt: 'workers collaborating in room',
      rightImage: true,
    } as ImageBlurbProps,
  },
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: Recovery,
          imageAlt: 'executive delegating to employees',
        },
      ],
      imageSide: 'left',
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'In the event of a disaster, it is important to have a plan in place for how you will manage your business. Consider having a designated person or team responsible for managing the disaster. Also, work on setting up a temporary center of operations. Finally, make sure that all your employees know what to do and what they should expect in terms of communication and responsibilities. Having a clear chains of command protects both your bottom line and your employees’ physical and mental health.\n\nMost of us have had to deal with the dilemma of working from home at some point during the COVID pandemic. Whether it was by choice or out of necessity, many of us have had to adapt to a new way of working. Some businesses have chosen to continue with this structure, while others have quickly gone back into a public office space. No matter where your business falls on this spectrum, as a business owner, you should have a plan in place in case another disaster renders your working space uninhabitable.\n\nHaving an emergency management plan in place for your business operations is key to continuing your work after a disaster. This should include an alternate work plan so that everyone in your office knows what to do in case of an emergency. This reduces panic and stress and allows your team to get back to work with little delay. Even if you need to have your team together in order to function, by having a plan in place, you can ensure that everyone knows what to do and where to go in case of an emergency.',
            header: 'Have an Alternate Work Plan Ready to Go',
          } as TitledBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
  {
    component: 'flexed-row',
    props: {
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'In an emergency, it is vital that you can communicate with your staff, customers, and partners. Make sure that you have a plan in place for communicating with everyone during an emergency. This may include a phone tree, setting up a website, blog post updates, or social media. In the event of a power outage, you may want to consider text messages over emails, as they are more likely to be seen promptly. Whatever method you choose, make sure that it will reach as many people as possible.\n\nYou should also have a backup plan for communication if your primary method fails. The key is to expect complications and have plans to address them whenever they (inevitably) arise. The truth is that things rarely go to plan when running a business, and that goes double in the case of a disaster. With a comprehensive emergency management plan, however, you can help ensure that your business bounces back after the trouble has subsided.',
            header: 'Communicate with Customers and Vendors',
          } as TitledBodyProps,
        },
        {
          component: 'titled-body',
          props: {
            body: 'Okay, let’s take a deep breath. If you’re reading this, you are probably preparing for a disaster situation or are actively in the middle of one. That is a lot to deal with, and it is easy to feel like you have to soldier on and do everything completely by yourself. But that is not the case, and it is important to delegate tasks where possible to lighten your load. There is no shame in sharing the burden with those around you, and you may even be surprised by their enthusiasm as they jump to assist you.\n\nWe here at Enigma Advisory are committed to helping you through the tough times and getting your business back up and running as soon as possible. We’ll work with you to develop a comprehensive emergency management plan to help you get through whatever comes your way.\n\nIf you’re interested in learning more about disaster management or would like assistance developing a plan, please contact us today. We’re here to help you and your community recover and thrive after a disaster.',
            header: 'Don’t Try to Do it All Yourself',
          } as TitledBodyProps,
        },
      ],
    } as FlexedRowProps,
  },
];

export default content;
