import { gray } from '@carbon/colors';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import ActionParagraph from '../../components/ActionParagraph';
import Footer from '../../components/Footer';
import ImageBlurb from '../../components/ImageBlurb';
import WaveSection from '../../components/Sections/Wave';

import { ReactComponent as Logo } from '../../assets/images/brand-logo-grants-white.svg';

import css from './grants-management.module.scss';

import s from '../../i18n/strings.json';


type GrantsManagementProps = {
  openContact: () => void;
};

const GrantsManagement = ({ openContact }: GrantsManagementProps) => {
  return (
    <div className={ css.grants }>
      <Helmet >
        <title>{ s.emergency_management.TITLE }</title>
        <meta name={ s.common.DESCRIPTION } content={ s.emergency_management.META_DESCRIPTION } />
      </Helmet>
      <div className={ css['logo-header'] }>
        <Logo />
      </div>
      <h1 className={ css.header }>
        { s.common.GRANTS_MANAGEMENT_SOLUTIONS }
      </h1>
      <div className={ classNames(css.section) }>
        <h2 className={ css['header-2'] }>
          { s.grants.GRANT }
          <span className={ css['text-bold-blue'] }>
            { s.grants.writing.WRITING_AND_APP }
          </span>
          { s.grants.DEVELOPMENT }
        </h2>
        <h4 className={ css['header-4'] }>
          { s.grants.writing.WRITING_AND_APP_SUBHEADER }
        </h4>
        <div className={ css['text-section'] }>
          <p className={ css.paragraph }>
            { s.grants.writing.WRITING_AND_APP_INFO }
          </p>
        </div>
      </div>
      <WaveSection
        backgroundColor={ '#327ec7' }
        bottom bottomColor={ '#327ec7' }
        bottomTransitionColor={ gray[100] }
        top topColor={ '#327ec7' }
        topTransitionColor={ gray[100] }
      >
        <div className={ css.padded }>
          <div>
            <h2 className={ css['header-2'] }>
              { s.grants.compliance.COMPLIANCE_AND_REPORTING }
            </h2>
            <h4 className={ classNames(css['header-4'], css['text-gray']) }>
              { s.grants.compliance.COMPLIANCE_AND_REPORTING_SUBHEADER }
            </h4>
          </div>
          <ImageBlurb
            blurb={ s.grants.compliance.COMPLIANCE_AND_REPORTING_INFO }
            header={ '' }
            image = { '/images/emergency-preparedness-consultation.jpg' }
            imageAlt={ 'preparedness consultation' }
            action={ false }
          />
        </div>
      </WaveSection>
      <div className={ classNames(css.section) }>
        <div className={ css['header-section'] }>
          <h2 className={ css['header-2'] }>
            { s.grants.administration.ADMINISTRATION_MANAGEMENT }
          </h2>
          <h4 className={ classNames(css['header-4'], css['text-bold-blue']) }>
            { s.grants.administration.ADMINISTRATION_MANAGEMENT_SUBHEADER }
          </h4>
        </div>
        <ImageBlurb
          blurb={ s.grants.administration.ADMINISTRATION_MANAGEMENT_INFO }
          header={ '' }
          image = { '/images/emergency-preparedness-consultation.jpg' }
          imageAlt={ 'preparedness consultation' }
          action={ false }
          rightImage={ true }
        />
      </div>
      <div className={ classNames(css.flood) }>
        <div className={ css['header-section'] }>
          <h2 className={ css['header-2'] }>
            { s.grants.monitoring.MONITORING_AND_EVAL }
          </h2>
          <div className={ css.divider } />
          <h4 className={ css['header-4'] }>
            { s.grants.monitoring.MONITORING_AND_EVAL_SUBHEADER }
          </h4>
        </div>
        <div className={ css['image-overlay'] }>
          <p className={ css.paragraph }>
            { s.grants.monitoring.MONITORING_AND_EVAL_INFO }
          </p>
        </div>
      </div>
      <div className={ classNames(css.section, css['background-gray']) }>
        <h2 className={ css['header-2'] }>
          { s.grants.communication.COMM_AND_RELATIONSHIP_MGMT }
        </h2>
      </div>
      <div className={ css.section }>
        <ImageBlurb
          blurb={ s.grants.communication.COMM_AND_RELATIONSHIP_MGMT_INFO }
          header={ '' }
          image = { '/images/emergency-preparedness-consultation.jpg' }
          imageAlt={ 'preparedness consultation' }
          action={ false }
        />
        <div className={ css['text-section'] }>
          <ActionParagraph
            action={ openContact }
            actionText={ s.common.CONTACT_US }
            beginningText={ s.grants.communication.CTA1 }
            endingText={ s.grants.communication.CTA2 }
          />
        </div>
      </div>
      <div className={ css['blue-gradient'] } />
      <div className={ css['footer-container'] }>
        <Footer openContact={ () => openContact() } />
      </div>
    </div>
  );
};

export default GrantsManagement;
