import { Checkmark } from '@carbon/icons-react';
import classNames from 'classnames';
import React from 'react';

import Spear from '../../components/Dividers/Spear';
import HorizontalScroll from '../../components/Parallax/HorizontalScroll';
import { PageProps } from '../../components/Templates/EmergencyManagement';

import NG from '../../assets/images/emergency-response-national-guard.jpg';
import DR from '../../assets/images/emergency-response.jpg';
import RPM from '../../assets/images/response-planning-mitigation.jpg';

import s from '../../i18n/strings.json';

import css from '../../components/Templates/templates.module.scss';


const INCLUDES = [
  s.process.response.INCLUDES_1,
  s.process.response.INCLUDES_2,
  s.process.response.INCLUDES_3,
  s.process.response.INCLUDES_4,
  s.process.response.INCLUDES_5,
  s.process.response.INCLUDES_6,
  s.process.response.INCLUDES_7,
  s.process.response.INCLUDES_8,
  s.process.response.INCLUDES_9,
  s.process.response.INCLUDES_10,
  s.process.response.INCLUDES_11,
  s.process.response.INCLUDES_12,
  s.process.response.INCLUDES_13,
  s.process.response.INCLUDES_14,
  s.process.response.INCLUDES_15,
];

const section2 = () => {
  return (
    <React.Fragment>
      <Spear size={ 'sm' } />
      <div className={ css.section }>
        <h2 className={ css['header-2'] }>
          { s.process.response.PROCESS }
        </h2>
        <h4 className={ css['header-4'] }>
          { s.process.response.PROCESS_SUBHEADER }
        </h4>
        <div className={ css['text-section'] }>
          <p className={ css.paragraph }>
            { s.process.response.PROCESS_INFO1 }
          </p>
        </div>
      </div>
      <div className={ classNames(css['horizontal-gradient'], css['header-4']) }>
        { s.process.response.INCLUDES }
      </div>
      <HorizontalScroll backgroundImage={ NG }>
        <div className={ classNames(css.section, css['padding-10p']) }>
          <div className={ css['image-overlay'] }>
            <div className={ css['overlay-list'] }>
              { INCLUDES.map((itm, idx) => {
                  return (
                    <div key={ `list1-item-${idx}` } className={ css['list-item'] }>
                      <div className={ css['icon-label'] }>
                        <Checkmark />
                        { itm }
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </HorizontalScroll>
      <div className={ css['horizontal-gradient-reverse'] } />
      <div className={ css.section }>
        <div className={ css['text-section'] }>
          <p className={ css.paragraph }>
            { s.process.response.PROCESS_INFO1 }
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

const props: PageProps = {
  seo: {
    description: s.process.response.META_DESCRIPTION,
    title: s.process.response.TITLE,
  },
  header: {
    backgroundImage: DR,
    header: s.common.DISASTER_RESPONSE,
    subheader: s.common.ENIGMA_ADVISORY,
  },
  section1: {
    header: s.process.response.EDR_PROCESS,
    text: s.process.response.EDR_PROCESS_INFO,
  },
  section2: {
    backgroundImage: '',
    header: '',
    text: '',
    custom: section2(),
  },
  section5: {
    backgroundImage: RPM,
    header: s.process.response.EXPERTS,
    actionText: s.process.response.EXPERTS_INFO,
  },
};

export default props;
