import { Tag } from '@carbon/react';
import React from 'react';
import { Tooltip } from 'react-tooltip';

import css from './tag-group.module.scss';


type TagGroupProps = {
  tags: Array<string>
  size?: 'sm' | 'md';
  color?: 'blue' | 'red' | 'magenta' | 'purple' | 'cyan' | 'teal' | 'green' | 'gray' | 'cool-gray' | 'warm-gray' | 'high-contrast' | 'outline' | undefined;
};

const TagGroup = ({
  tags,
  size = 'md',
  color = 'blue',
}: TagGroupProps) => {
  return (
    <div className={ css['tag-group'] }>
      { tags.length > 3
        ? <React.Fragment>
            <Tag
              title={ tags[0] }
              type={ color }
              size={ size }
            >
              { tags[0] }
            </Tag>
            <Tag
              id={ 'tag-overflow' }
              title={ tags[0] }
              type={ color }
              size={ size }
            >
              { `+${tags.length - 1}` }
            </Tag>
            <Tooltip
              anchorSelect={ '#tag-overflow' }
              place={ 'top' }
              content={ tags.slice(1).join(', ') }
            />
          </React.Fragment>
        : tags.map(tag => {
          return (
            <Tag
              key={ `${tag}-tag` }
              title={ tag }
              type={ color }
              size={ size }
            >
              { tag }
            </Tag>
          );
        })
      }
    </div>
  );
};

export default TagGroup;
