import { TitledImageBodyProps } from '../components/BlogSections/TitledImageBody';
import { TitledSubsectionBodyProps } from '../components/BlogSections/TitledSubsectionBody';

import CommunityMeeting from './images/community-meeting.jpg';
import Hands from './images/group-hands.jpg';

import Meeting from '../pages/Insight/images/office-meeting.jpg';


const content = [
  {
    component: 'titled-image-body',
    props: {
      body: 'In the age of rapid information and the coronavirus pandemic, it is essential to consider crisis management preparedness as a “when” and not “if” within your company, group, or organization. A Crisis Management Preparedness Plan grants companies and organizations the ability to handle situational crises effectively. Having a proactive plan, rather than a reactive plan, in place for any crisis faced within your organization will allow you to handle unexpected situations better.\n\nCrisis Management Preparedness Plans are used by emergency management teams and others to avoid or minimize perceived damage. They provide direction, resources, and communications to those affected. The ability to communicate with all parties quickly and efficiently can minimize the effects of a crisis situation. This will allow your team to have a practiced, planned framework for response criteria for an effective response to a crisis.\n\nThere are several factors that go into a successful crisis management preparedness plan. Knowing the importance of each step is essential in creating success within your organization. By defining the response for each type of crisis your organization faces, your team will be better prepared to handle, respond to, and recover from any type of crisis that might occur. However, success depends on your team’s understanding of your plan. The best way to ensure the success of your crisis response is to practice each step of your plan and adjust accordingly. No matter the size of your organization, designing a comprehensive and effective crisis management plan that everyone understands will be important to your team’s response.',
      header: 'What Is Crisis Management Preparedness?',
      image: Hands,
      imageAlt: 'group meeting hand gestures',
      imageSide: 'right',
      subheader: 'The Art Of Planning, Preparing, And Practicing',
    } as TitledImageBodyProps,
  },
  {
    component: 'titled-subsection-body',
    props: {
      body: 'A crisis can last anywhere from a few hours to a few days or even longer. It can require fast decisions to be made quickly to minimize damage. Indeed defines a crisis as an “unforeseen event that causes restlessness among the people of an organization .”Potential crises can include natural disasters, severe weather events, and biological hazards to human-caused events or technological crashes. With so many different forms of crisis, it’s important to have a ready crisis management preparedness plan of action to avoid panic, mismanagement, or chaos. Take a look at the differences in types of crises below:',
      header: 'Crisis Management Preparedness For Every Situation',
      subheader: 'What Defines A Crisis?',
      subsections: [
        {
          body: 'Natural, unavoidable disasters such as hurricanes, earthquakes, tsunamis, and volcanoes are included in this category of crisis. These can strike at any moment and be extremely devastating. While most businesses can recover quickly from natural disasters, some companies may find themselves severely impacted by damages.',
          header: 'Natural Disasters',
        },
        {
          body: 'Severe weather events are defined as flooding, blizzards, droughts, and more. While these can be catastrophic, having a crisis management preparedness plan in place can help keep your entire team safe. Anticipating changing seasons and radar can help to predict these sudden, severe weather events and allow your team to act accordingly.',
          header: 'Severe Weather Events',
        },
        {
          body: 'Biological hazards are especially relevant in recent times, as foodborne illnesses and pandemics are a part of this category. As a result, measures against covid have now become a regular part of crisis prevention in the past couple of years. It will be up to your crisis management plan to ensure that everyone has proper pandemic wear and access to the latest information.',
          header: 'Biological Hazards',
        },
        {
          body: 'Accidental human-caused events include things like fires, explosions, building or structural collapses, and hazardous spills. These sudden events can be shocking and catastrophic, with no warning beforehand. If you find yourself without a plan in place, damages, injuries, or even loss of life could occur.',
          header: 'Accidental Human-Caused Events',
        },
        {
          body: 'Intentional human-caused events are also a crisis. This can include events such as robberies, violence, and arson. These events cannot be predicted. Without a plan in place for escape and management, it can be hard to successfully redirect your team to safety or de-escalate.',
          header: 'Intentional Human-Caused Events',
        },
        {
          body: 'Technology issues in today’s ever-growing technological world are a real threat, and crises such as outages and cyberattacks can affect your organization significantly. In today’s computer-oriented world, a plan of action when your organization’s system is compromised, is important to maintain order.',
          header: 'Technology Issues',
        },
      ],
    } as TitledSubsectionBodyProps,
  },
  {
    component: 'titled-subsection-body',
    props: {
      body: 'While many types of crises may pose a threat to your organization, there are several steps you can take to develop a plan to mitigate damage and organize your efforts. A good crisis management preparedness plan should identify all potential threats to your organization while determining your team’s response and recovery process. This strategic framework will minimize the risk of mismanagement during a crisis and maintain consistency. Here are seven key points you may want to include in your crisis management preparedness plan:',
      header: 'What To Include In Your Crisis Management Preparedness',
      subheader: '7 Key Points To A Good Crisis Plan',
      subsections: [
        {
          body: 'Having a list ready of all potential risks and how to handle them will prepare you for any crisis that may arise within your organization. Assessing as many situations as possible will allow you to plan and practice all potential crisis responses effectively. Knowing how to spot risks early on can encourage a successful crisis management response within your organization’s team.',
          header: 'Identify Potential Risks',
        },
        {
          body: 'Identifying roles and responsibilities in a crisis can allow your team to handle crises in action effectively. Knowing the role each team member plays in a crisis can effectively reduce confusion and chaos while enabling you to manage every unexpected situation. Establishing a response team and allowing everyone to know their roles can allow more crisis management preparedness.',
          header: 'Identify Roles And Responsibilities',
        },
        {
          body: 'A part of your plan should involve establishing signs to look for in order to start action, which can minimize the outcome of a crisis situation. When everyone knows the early monitoring signs to look for, your team can remain confident and level-headed. You’re more likely to act effectively and prudently when you have the confidence that practice, expertise, and a plan give you.',
          header: 'Pinpoint When To Act',
        },
        {
          body: 'Key emergency contacts are an essential part of a crisis management preparedness plan. Having all the necessary contact information readily available helps expedite crisis response, making it easier to manage. Giving your team a list of contacts to call will save time on strategizing and avoid wasting expensive minutes searching for contact information.',
          header: 'Key Emergency Contacts',
        },
        {
          body: 'Documenting who needs to be notified during a crisis reduces the response time needed for mass communication to all affected parties. Effectively explaining the situation to the public or key stakeholders is easier when including details about who will deliver the information and who is in charge of handling feedback.',
          header: 'How To Communicate',
        },
        {
          body: 'Identifying a process to assess the incident, its potential severity, and how it will/has impacted the building and employees will be essential in your crisis management preparedness plan. This allows your team to assess what went well and what didn’t, which can then be used to improve your response procedures and reduce the impact on your organization.',
          header: 'Follow-Up Assessment',
        },
        {
          body: 'Finally, it is important to review your plan periodically to ensure there are no gaps in the process. Revisit your plan at least once a year to update potential risks or reassess each plan you have in place based on changing technology and staff. Providing a way to test the effectiveness of your crisis management response plan regularly can allow you to update it with tried and tested information, so you will always be prepared in a crisis.',
          header: 'Testing And Updating Procedures',
        },
      ],
    } as TitledSubsectionBodyProps,
  },
  {
    component: 'titled-image-body',
    props: {
      body: 'Hearing the horror stories of organizations failing in crisis management can be due to many different reasons. Whether due to a lack of leadership, adjustment, or failure to plan accordingly, countless factors go into the success or failure of crisis management preparedness. The first step to any successful crisis management plan is to make one to begin with. Organizations without a plan in place risk chaos and stress during any situation. By putting a plan in place for when situations occur, you alleviate the stress of planning at the moment.\n\nAnother reason crisis management could prove unsuccessful is due to the inability to act fast enough when a crisis presents itself. Establishing criteria for the appropriate time to react in a crisis is crucial for a successful crisis management plan. Making timely decisions and correct communication planning will ensure a smoother transition into your crisis management planning procedures. Without these steps, you risk disorganization from the first sign of a crisis.\n\nAdditionally, planning ahead for any crisis will allow you to find gaps in your crisis management plan before they become an issue. Ensuring that your team has the tools and resources to complete each step of your crisis management plan is important to ensure your entire team’s confidence during a crisis. When you have a prepared team, you can handle and evolve with any changing situation your organization faces. Finding these gaps ahead of time and practicing your crisis responses will ensure success for your crisis management plan.',
      header: 'Avoiding Crisis Management Preparedness Failures',
      image: Meeting,
      imageAlt: 'man presenting in office meeting',
      imageSide: 'left',
      subheader: 'Is Having A Crisis Plan Enough?',
    } as TitledImageBodyProps,
  },
  {
    component: 'titled-image-body',
    props: {
      body: 'Crises can arise from any area within your organization. Proper planning before any situation will enhance your team’s ability to handle and recover from a crisis. Studying the reasons why crisis management plans have failed in the past can garner an understanding of how to learn from those mistakes. Success in crisis planning comes from many factors. By being prepared and following all of the necessary steps in your crisis management plan, you can prepare yourself, your team, and your company to weather any storm that comes your way.\n\nHaving the right tools and resources can make setting up a crisis management preparedness plan to suit your organization’s needs easy. However, what is most important is ensuring that every team member understands your organization’s crisis plan. Automating aspects of your plan to ensure a smooth transition into crisis mode when needed will help organize your team’s efforts to handle any situation.\n\nAt Enigma Advisory, we offer Expert Emergency Preparedness Consulting to ensure that your organization takes the appropriate steps to ensure safety before, during, and after a crisis event. With our extensive experience and knowledge, we can help you focus on the best practice for developing procedures, securing resources, and building the infrastructure needed to handle any situation. In each crisis, it’s important to remember the passing nature of storms. If you handle each crisis with a plan in place and the confidence to act, your crisis management plan will ensure success for all within your organization. Contact us today to learn more!',
      header: 'Crisis Management Planning With Enigma',
      image: CommunityMeeting,
      imageAlt: 'woman listening during community group session',
      imageSide: 'right',
      subheader: 'Expert Emergency Preparedness Consulting',
    } as TitledImageBodyProps,
  },
];

export default content;
