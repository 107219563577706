import { gray } from '@carbon/colors';
import { CircleDash } from '@carbon/icons-react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import ActionParagraph from '../../components/ActionParagraph';
import Footer from '../../components/Footer';
import ImageBlurb from '../../components/ImageBlurb';
import WaveSection from '../../components/Sections/Wave';

import { ReactComponent as Logo } from '../../assets/images/brand-logo-technology-white.svg';

import css from './technology.module.scss';

import s from '../../i18n/strings.json';


type TechnologyProps = {
  openContact: () => void;
};

const Technology = ({ openContact }: TechnologyProps) => {
  return (
    <div className={ css.technology }>
      <Helmet >
        <title>{ s.technology.TITLE }</title>
        <meta name={ s.common.DESCRIPTION } content={ s.technology.META_DESCRIPTION } />
      </Helmet>
      <div className={ css['logo-header'] }>
        <Logo />
      </div>
      <h1 className={ css.header }>
        { s.common.TECHNOLOGY_SOLUTIONS }
      </h1>
      <div className={ classNames(css.section) }>
        <h2 className={ css['header-2'] }>
          { s.technology.WHERE }
          <span className={ css['text-bold-blue'] }>
            { s.technology.INNOVATION_MEETS_EFFICEINTCY }
          </span>
        </h2>
        <div className={ css['text-section'] }>
          <p className={ css.paragraph }>
            { s.technology.INNOVATION_MEETS_EFFICEINTCY_INFO }
          </p>
        </div>
      </div>
      <WaveSection
        backgroundColor={ '#327ec7' }
        bottom bottomColor={ '#327ec7' }
        bottomTransitionColor={ gray[100] }
        top topColor={ '#327ec7' }
        topTransitionColor={ gray[100] }
      >
        <div className={ css.padded }>
          <div className={ css['header-section'] }>
            <h2 className={ css['header-2'] }>
              { s.solutions.cloud.UNLOCKING_POWER }
            </h2>
            <h4 className={ classNames(css['header-4'], css['text-gray']) }>
              { s.solutions.cloud.REVOLUTIONIZE }
            </h4>
          </div>
          <ImageBlurb
            blurb={ s.solutions.cloud.REVOLUTIONIZE_INFO }
            header={ '' }
            image = { '/images/emergency-preparedness-consultation.jpg' }
            imageAlt={ 'preparedness consultation' }
            action={ false }
          />
          <div className={ css['list-section'] }>
            <ul>
              <li>
                <span className={ css.label }>{ `${s.solutions.cloud.SCALE_AND_FLEX}: ` }</span>
                { s.solutions.cloud.SCALE_AND_FLEX_DESCRIPTION }
              </li>
              <li>
                <span className={ css.label }>{ `${s.solutions.cloud.CONTAINERIZE_MICROSERVICES}: ` }</span>
                { s.solutions.cloud.CONTAINERIZE_MICROSERVICES_DESCRIPTION }
              </li>
              <li>
                <span className={ css.label }>{ `${s.solutions.cloud.RESILIANCE_RELIABILITY}: ` }</span>
                { s.solutions.cloud.RESILIANCE_RELIABILITY_DESCRIPTION }
              </li>
              <li>
                <span className={ css.label }>{ `${s.solutions.cloud.COST_OPTIMIZATION}: ` }</span>
                { s.solutions.cloud.COST_OPTIMIZATION_DESCRIPTION }
              </li>
              <li>
                <span className={ css.label }>{ `${s.solutions.cloud.INNOVATION_AGILITY}: ` }</span>
                { s.solutions.cloud.INNOVATION_AGILITY_DESCRIPTION }
              </li>
            </ul>
          </div>
          <div className={ css['text-section'] }>
            <ActionParagraph
              action={ openContact }
              actionText={ s.common.CONTACT_US }
              beginningText={ s.solutions.cloud.CTA1 }
              endingText={ s.solutions.cloud.CTA2 }
              color={ 'gray' }
            />
          </div>
        </div>
      </WaveSection>
      <div className={ classNames(css.section) }>
        <div className={ css['header-section'] }>
          <h2 className={ css['header-2'] }>
            { s.solutions.web.CRAFTING_WEB_EXP }
          </h2>
          <h4 className={ classNames(css['header-4'], css['text-bold-blue']) }>
            { s.solutions.web.ELEVATE_PRESENCE }
          </h4>
        </div>
        <ImageBlurb
          blurb={ s.solutions.web.ELEVATE_PRESENCE_DESCRIPTION }
          header={ '' }
          image = { '/images/emergency-preparedness-consultation.jpg' }
          imageAlt={ 'preparedness consultation' }
          action={ false }
          rightImage={ true }
        />
        <div className={ css['list-section'] }>
          <ul>
            <li>
              <span className={ css.label }>{ `${s.solutions.web.CUSTOMIZED_SOLUTIONS}: ` }</span>
              { s.solutions.web.CUSTOMIZED_SOLUTIONS_DESCRIPTION }
            </li>
            <li>
              <span className={ css.label }>{ `${s.solutions.web.PERFORMANCE_OPTIMIZATION}: ` }</span>
              { s.solutions.web.PERFORMANCE_OPTIMIZATION_DESCRIPTION }
            </li>
            <li>
              <span className={ css.label }>{ `${s.solutions.web.SEO}: ` }</span>
              { s.solutions.web.SEO_DESCRIPTION }
            </li>
            <li>
              <span className={ css.label }>{ `${s.solutions.web.SEC_AND_COMPLIANCE}: ` }</span>
              { s.solutions.web.SEC_AND_COMPLIANCE_DESCRIPTION }
            </li>
            <li>
              <span className={ css.label }>{ `${s.solutions.web.SCALABILITY_AND_FUTURE}: ` }</span>
              { s.solutions.web.SCALABILITY_AND_FUTURE_DESCRIPTION }
            </li>
          </ul>
        </div>
        <div className={ css['text-section'] }>
          <ActionParagraph
            action={ openContact }
            actionText={ s.common.CONTACT_US }
            beginningText={ s.solutions.web.CTA1 }
            endingText={ s.solutions.web.CTA2 }
          />
        </div>
      </div>
      <div className={ classNames(css.flood) }>
        <div className={ css['header-section'] }>
          <h2 className={ css['header-2'] }>
            { s.solutions.advisory.NAVIGATE }
          </h2>
          <div className={ css.divider } />
          <h4 className={ css['header-4'] }>
            { s.solutions.advisory.STRATEGIC_ADVISORY }
          </h4>
        </div>
        <div className={ css['text-section'] }>
          <p className={ css.paragraph }>
            { s.solutions.advisory.STRATEGIC_ADVISORY_DESCRIPTION }
          </p>
        </div>
      </div>
      <div className={ css.blocks }>
        <div className={ classNames(css.block, css['blue-1']) }>
          <div className={ css['block-header'] }>
            { s.solutions.advisory.TECH_ROADMAPPING }
          </div>
          <div className={ css['block-fill'] }>
            { s.solutions.advisory.TECH_ROADMAPPING_DESCRIPTION }
          </div>
        </div>
        <div className={ classNames(css.block, css['blue-2']) }>
          <div className={ css['block-header'] }>
            { s.solutions.advisory.EMERGING_TECH }
          </div>
          <div className={ css['block-fill'] }>
            { s.solutions.advisory.EMERGING_TECH_DESCRIPTION }
          </div>
        </div>
        <div className={ classNames(css.block, css['blue-3']) }>
          <div className={ css['block-header'] }>
            { s.solutions.advisory.DIGITAL_STRAT_INNOVATION }
          </div>
          <div className={ css['block-fill'] }>
            { s.solutions.advisory.DIGITAL_STRAT_INNOVATION_DESCRIPTION }
          </div>
        </div>
        <div className={ classNames(css.block, css['blue-4']) }>
          <div className={ css['block-header'] }>
            { s.solutions.advisory.ORG_CHANGE_MGMNT }
          </div>
          <div className={ css['block-fill'] }>
            { s.solutions.advisory.ORG_CHANGE_MGMNT_DESCRIPTION }
          </div>
        </div>
        <div className={ classNames(css.block, css['blue-5']) }>
          <div className={ css['block-header'] }>
            { s.solutions.advisory.PERFORMANCE_OPTIMIZATION }
          </div>
          <div className={ css['block-fill'] }>
            { s.solutions.advisory.PERFORMANCE_OPTIMIZATION_DESCRIPTION }
          </div>
        </div>
      </div>
      <div className={ css.section }>
        <div className={ css['text-section'] }>
          <ActionParagraph
            action={ openContact }
            actionText={ s.common.CONTACT_US }
            beginningText={ s.solutions.advisory.CTA1 }
            endingText={ s.solutions.advisory.CTA2 }
          />
        </div>
      </div>
      <div className={ classNames(css.section, css['background-gray']) }>
        <h2 className={ css['header-2'] }>
          { s.solutions.devops.STREAMLINE }
        </h2>
        <h4 className={ classNames(css['header-4'], css['text-bold-blue']) }>
          { s.solutions.devops.EMPOWER }
        </h4>
      </div>
      <div className={ css.section }>
        <ImageBlurb
          blurb={ s.solutions.devops.EMPOWER_DESCRIPTION }
          header={ '' }
          image = { '/images/emergency-preparedness-consultation.jpg' }
          imageAlt={ 'preparedness consultation' }
          action={ false }
        />
        <div className={ css.list }>
          <div className={ css['list-column'] }>
            <div className={ css['list-item'] }>
              <CircleDash />
              <div>
                <span className={ css.label }>{ `${s.solutions.devops.CICD}: ` }</span>
                { s.solutions.devops.CICD_DESCRIPTION }
              </div>
            </div>
            <div className={ css['list-item'] }>
              <CircleDash />
              <div>
                <span className={ css.label }>{ `${s.solutions.devops.IAC}: ` }</span>
                { s.solutions.devops.IAC_DESCRIPTION }
              </div>
            </div>
            <div className={ css['list-item'] }>
              <CircleDash />
              <div>
                <span className={ css.label }>{ `${s.solutions.devops.MONITORING_ANALYTICS}: ` }</span>
                { s.solutions.devops.MONITORING_ANALYTICS_DESCRIPTION }
              </div>
            </div>
            <div className={ css['list-item'] }>
              <CircleDash />
              <div>
                <span className={ css.label }>{ `${s.solutions.devops.SEC_AND_COMPLIANCE}: ` }</span>
                { s.solutions.devops.SEC_AND_COMPLIANCE_DESCRIPTION }
              </div>
            </div>
            <div className={ css['list-item'] }>
              <CircleDash />
              <div>
                <span className={ css.label }>{ `${s.solutions.devops.COLLABOTATION_CULTURE}: ` }</span>
                { s.solutions.devops.COLLABOTATION_CULTURE_DESCRIPTION }
              </div>
            </div>
          </div>
        </div>
        <div className={ css['text-section'] }>
          <ActionParagraph
            action={ openContact }
            actionText={ s.common.CONTACT_US }
            beginningText={ s.solutions.devops.CTA1 }
            endingText={ s.solutions.devops.CTA2 }
          />
        </div>
      </div>
      <div className={ css['blue-gradient'] } />
      <div className={ css['footer-container'] }>
        <Footer openContact={ () => openContact() } />
      </div>
    </div>
  );
};

export default Technology;
