import { Helmet } from 'react-helmet';

import Hurricane from '../../assets/images/hurricane.jpg';
import Testimonial from '../../assets/images/testimonials.jpg';

import DisasterTemplate, { PageProps } from '../../components/Templates/EmergencyManagement';

import s from '../../i18n/strings.json';


type TestimonialsProps = {
  openContact?: () => void;
};

const PROPS: PageProps = {
  seo: {
    description: s.testimonials.META_DESCRIPTION,
    title: s.testimonials.TITLE,
  },
  header: {
    backgroundImage: Testimonial,
    header: s.common.TESTIMONIALS,
    subheader: s.common.ENIGMA_ADVISORY,
  },
  section1: {
    header: s.testimonials.ENIGMA_ADVISORY_TESTIMONIALS,
    text: s.testimonials.ENIGMA_ADVISORY_TESTIMONIALS_INFO,
  },
  section2: {
    backgroundImage: Hurricane,
    header: s.testimonials.HIRE_EXPERTS,
    text: s.testimonials.HIRE_EXPERTS_INFO,
  },
  testimonials: {
    header: s.testimonials.EXPERIENCE_AND_RESULTS,
    testimonials: [
      {
        author: s.testimonials.TESTIMONIAL1_CLIENT,
        text: s.testimonials.TESTIMONIAL1,
      },
    ],
  },
};

const Testimonials = ({ openContact = () => {} }: TestimonialsProps) => {
  return (
    <>
      <Helmet >
        <title>{ PROPS.seo.title }</title>
        <meta name={ s.common.DESCRIPTION } content={ PROPS.seo.description } />
      </Helmet>
      <DisasterTemplate openContact={ openContact } props={ PROPS } />
    </>
  );
};

export default Testimonials;
