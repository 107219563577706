/* eslint-disable @typescript-eslint/no-explicit-any */
import EnigmaDocument from '../components/BlogSections/EnigmaDocument';
import FlexedRow from '../components/BlogSections/FlexedRow';
import TitledBody from '../components/BlogSections/TitledBody';
import TitledDividedImageBody from '../components/BlogSections/TitledDividedImageBody';
import TitledImageBody from '../components/BlogSections/TitledImageBody';
import TitledList from '../components/BlogSections/TitledList';
import TitledSubsectionBody from '../components/BlogSections/TitledSubsectionBody';
import ImageBlurb from '../components/ImageBlurb';


export const EMAIL_REGEX: RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const PHONE_NUMBER_REGEX = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;


/* External Links */
export const FACEBOOK = 'https://www.facebook.com/people/Enigma-Advisory/100066744037466/';
export const LINKED_IN = 'https://www.linkedin.com/company/enigma-advisory/';
export const X = 'https://twitter.com/EnigmaAdvisory';


/* Mapping of Component to a name for dynamic instantiation */
type ComponentMap = {
  [component: string]: any;
};

export const COMPONENT_MAP: ComponentMap = {
  'enigma-document': EnigmaDocument,
  'flexed-row': FlexedRow,
  'image-blurb': ImageBlurb,
  'titled-body': TitledBody,
  'titled-divided-image-body': TitledDividedImageBody,
  'titled-image-body': TitledImageBody,
  'titled-list': TitledList,
  'titled-subsection-body': TitledSubsectionBody,
};

export const BLOG_ROUTES = [
  'the-psychology-of-emergency-preparedness',
  'contingency-management-distinctions-in-crisis',
  'business-resilience-framework',
  'disaster-prevention-mitigation-planning-during-the-holidays',
  'the-foundations-of-emergency-management',
  'disaster-risk-management-reaction-vs-proaction',
  'a-complete-guide-to-environmental-risk-management',
  'cbrn-emergency-management-preparedness-and-response-planning',
  'examining-emergency-preparedness-in-hospitals',
  'how-technology-in-emergency-management-is-changing-industry',
  'crisis-management-preparedness',
  'learn-more-about-an-emergency-response-plan',
  'understanding-natural-disasters-risk-reduction-and-management',
  'responsibilities-of-emergency-operation-centers-managers',
  'basic-emergency-preparedness-and-response-in-the-workplace',
  'the-applications-of-a-disaster-mitigation-plan-for-the-community',
  'understanding-state-emergency-response-teams',
  'activities-of-disaster-management-procedure-and-purpose',
  'benefits-of-hazard-mitigation-planning',
  'continued-developments-in-the-supply-chain',
  'managing-your-business-after-a-disaster',
  'how-to-handle-an-evacuation-notice',
  'setup-office-disaster-plan',
  'emergency-management-alpha-to-omicron',
  'fema-2022-2026-new-emergency-management-plan',
  '5-things-your-emergency-management-specialist-wants-you-to-know',
  'emergency-management-professionals',
  'disaster-experts-blog',
];
