import { TitledBodyProps } from '../components/BlogSections/TitledBody';
import { TitledDividedImageBodyProps } from '../components/BlogSections/TitledDividedImageBody';

import Consultation from './images/consultation.jpg';
import ResponseTeam from './images/emergency-response-team.jpg';

import Mitigation from '../assets/images/preparedness-mitigation.jpg';
import Responders from '../assets/images/response-responders.jpg';


const content = [
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: ResponseTeam,
          imageAlt: 'emergency response team notebook',
        },
      ],
      imageSide: 'right',
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'So who do we mean when we are talking about a State Emergency Response Team? Here we are referring to the organization of officials and teams at the State-level Government to address a disaster situation. What exactly this looks like can vary widely, depending on the Laws and Regulations of the State. These teams are typically made up of elected officials who represent the area, their affiliated offices, local officials, and applicable State disaster response specialist groups. The title of this last element is different for every State and may be managed by different offices. For example, in Texas, the TDEM (Texas Department of Emergency Management) is overseen by the Texas A&M University System, while in Florida the Division of Emergency Management falls under the Executive Office of the Governor. Despite the differences in names, they all serve the purpose of responding to emergencies that occur within the State and coordinating with Local, Federal, and external organizations to ensure proper response and recovery efforts are made.\n\nWhen an emergency occurs, the State emergency response team often has standard procedurals that go into effect. Broadly speaking, however, they will often first coordinate with Local Authorities who are on the ground and can provide a status report on the current situation. If a base of operations has not been established, they will work to help set one up. Based on the reports on the ground, they will also connect with the Governor and advise them on future actions. On that note, it is important to know that the ultimate authority on the State level is the State Governor, and they make the final call on declaring a disaster and recalling that status. The lion’s share of the work, however, often falls to the State Disaster Management Department. They are the ones who prepare reports evaluating the situation and coordinating directly with Local officials and external Disaster Management Organizations.',
            header: 'How a Disaster Mitigation Plan Helps Protect Communities',
            subheader: 'Who’s Typically Involved In A State Emergency Response Team?',
          } as TitledBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: Mitigation,
          imageAlt: 'hand pointing at notebook in meeting',
        },
      ],
      imageSide: 'left',
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'The public may not always see the work of the State Government, but the effects of their work directly affect how the situation is handled on the ground. Because the State Departments and officials have the ear of the Governor when it comes to the situation, their work directly affects the allocation of funds, attention, and personnel that can be deployed to assist the Local response.\n\nThe State will also have a hand in determining evacuation routes when necessary, and determining when an area is safe for residents to return. Often they will coordinate with Local Organizations and Government Agencies to determine the best course of action in these situations.\n\nIf the disaster event is a large one that overwhelms Local and State resources, the State Departments are also the ones who will beseech other States and Federal Agencies for assistance and support. The Governor may request that the National Guard be mobilized to assist in relief efforts and work with larger organizations like FEMA to secure funding and supplies. In this way, the SERT functions as a liaison between different levels of Government to ensure that areas that need assistance are able to get it promptly and efficiently.',
            header: 'What Does A Response Team Do For The Affected Community?',
            subheader: 'Defining The Connection Between The Public and The State',
          } as TitledBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: Responders,
          imageAlt: 'emergency responders in the field',
        },
      ],
      imageSide: 'right',
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'Following response efforts, the State may also be involved with the recovery and rebuilding process. Often they will take on a less hands-on approach in favor of allowing communities to decide on rebuilding initiatives. The biggest form of power that they take on here falls to the Governor, who makes the ultimate decision on when to rescind the disaster scenario status regarding a disaster situation.\n\nState Emergency Response Teams can also help with managing and distributing grants and aid money to communities. These grants are often imperative parts of the recovery process, providing critical resources for communities to help them rebuild structures, pave roads, and deal with any other damages that may have occurred. Through these efforts, the State can be involved in contributing to recovery efforts.',
            header: 'How A State Emergency Response Team Handles Long-Term Crisis',
            subheader: 'The Recovery and Rebuilding Process',
          } as TitledBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
  {
    component: 'titled-divided-image-body',
    props: {
      images: [
        {
          image: Consultation,
          imageAlt: 'consultation presentation around table',
        },
      ],
      imageSide: 'left',
      sections: [
        {
          component: 'titled-body',
          props: {
            body: 'In the grand scheme of things, disaster management is all about working together as a whole. That means collaboration between the State and Local Government, collaboration between Government entities and external agencies, and ultimately between the Government and the public itself. Everyone plays a part in bringing a community back together. In all this, the role of the State emergency response team can not be underestimated. They act as the liaison between so many branches of power while working directly with community outreach to ensure that areas in need get the resources they require. This allows the Local Government to focus on helping the people themselves, and the Federal Government to refocus on other areas that may be experiencing disaster.',
            header: 'Understanding The Art of Collaboration',
            subheader: 'Why The SERT Is An Important Entity',
          } as TitledBodyProps,
        },
      ],
    } as TitledDividedImageBodyProps,
  },
];

export default content;
