import { PageProps } from '../../components/Templates/EmergencyManagement';

import { CircleSolid } from '@carbon/icons-react';
import classNames from 'classnames';
import React from 'react';

import Mitigation from '../../assets/images/preparedness-mitigation.jpg';
import Phase1 from '../../assets/images/recovery-phase-one.jpg';
import Phase3 from '../../assets/images/recovery-phase-three.jpg';
import Phase2 from '../../assets/images/recovery-phase-two.jpg';
import Rebulding from '../../assets/images/recovery-rebuilding.jpg';
import RPM from '../../assets/images/response-planning-mitigation.jpg';

import s from '../../i18n/strings.json';

import css from '../../components/Templates/templates.module.scss';


const section1 = () => {
  return (
    <React.Fragment>
      <div className={ css['blue-gradient'] }>
        <h2 className={ css['header-2'] }>
          { s.process.recovery.THREE_PHASE }
        </h2>
      </div>
      <div className={ css['section'] }>
        <div className={ css['text-section'] }>
          <p className={ css['paragraph'] }>
            { s.process.recovery.THREE_PHASE_INFO1 }
          </p>
          <div className={ css['center-wrapper-2'] }>
            { [s.process.recovery.PHASE_1, s.process.recovery.PHASE_2, s.process.recovery.PHASE_3]
                .map((phase, idx) => {
                return (
                  <div
                    key={ `phase-${idx}` }
                    className={ classNames(css['icon-label'], css['text-bold-blue']) }
                  >
                    <CircleSolid />
                    { phase }
                  </div>
                );
              })
            }
          </div>
          <p className={ css['paragraph'] }>
            { s.process.recovery.THREE_PHASE_INFO2 }
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

const props: PageProps = {
  seo: {
    description: s.process.recovery.META_DESCRIPTION,
    title: s.process.recovery.TITLE,
  },
  header: {
    backgroundImage: Rebulding,
    header: s.common.DISASTER_RECOVERY,
    subheader: s.common.ENIGMA_ADVISORY,
  },
  section1: {
    header: '',
    text: '',
    custom: section1(),
  },
  imageBlurbs: [
    {
      blurb: s.process.recovery.IMAGE_BLURB1,
      header: s.process.recovery.IMAGE_BLURB1_HEADER,
      image: Phase1,
      imageAlt: 'executive delegating to employees',
      color: '#327ec7',
      subheader: s.process.recovery.IMAGE_BLURB1_SUBHEADER,
      subheaderColor: '#3bafbf',
    },
    {
      blurb: s.process.recovery.IMAGE_BLURB2,
      header: s.process.recovery.IMAGE_BLURB2_HEADER,
      image: Phase2,
      imageAlt: 'data and financial management',
      color: '#327ec7',
      subheader: s.process.recovery.IMAGE_BLURB2_SUBHEADER,
      subheaderColor: '#3bafbf',
    },
    {
      blurb: s.process.recovery.IMAGE_BLURB3,
      header: s.process.recovery.IMAGE_BLURB3_HEADER,
      image: Phase3,
      imageAlt: 'policy guideline process',
      color: '#327ec7',
      subheader: s.process.recovery.IMAGE_BLURB3_SUBHEADER,
      subheaderColor: '#3bafbf',
    },
  ],
  section3: {
    backgroundImage: Mitigation,
    header: s.process.recovery.PREPAREDNESS_PLANNING,
    subheader: s.process.recovery.BEFORE_EMERGENCY,
    text: s.process.recovery.PREPAREDNESS_PLANNING_INFO,
  },
  section5: {
    backgroundImage: RPM,
    header: s.process.recovery.EMERGENCY_RECOVERY_EXPERTS,
    actionText: s.process.recovery.EMERGENCY_RECOVERY_EXPERTS_INFO,
  },
};

export default props;
